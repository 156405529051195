import React, { useEffect, useState } from "react";
import { getAllUserAsync, deleteUserAsync, disableUserAsync } from "./redux";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { UserManagementActions } from "./redux/action";
import { ButtonLoading } from "../../../components/button";
import { Form, Modal } from "react-bootstrap";
import BasicPagination from "../../../components/pagination";
import Pen from "../../../assets/images/pen.svg";
import Trash from "../../../assets/images/trash.svg";
import Replay from "../../../assets/images/replay.png";
import { ImageCard } from "../../../components/card/image-card";
import { CommonLayout } from "../../../components/layout/common-layout";

const DefaultFallbackImage = () => {
  return (
    <i
      className="mdi mdi-account-circle-outline"
      style={{ fontSize: "32px" }}
    ></i>
  );
};

const UserManagement = () => {
  const [showDisable, setDisableUser] = useState(false);
  const [showDelete, setDeleteUser] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    userList,
    refreshUserList,
    selectedUser,
    limit,
    offset,
    totalRecords,
    isLoading,
  } = useSelector((state) => state.userManagement, shallowEqual);

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (refreshUserList) {
      dispatch(getAllUserAsync());
    }
  }, [refreshUserList]);

  const setSelectedUser = (user) => {
    dispatch(UserManagementActions.setSelectedUser(user));
  };

  const onUserClick = (user) => {
    if (user) {
      setSelectedUser(user);
      history.push(`/user-management/${user.userId}`);
    } else {
      setSelectedUser({});
      history.push("/user-management/create-user");
    }
  };

  const onDisableToggle = (user) => {
    setSelectedUser(user);
    setDisableUser(true);
  };

  const onDisableUser = async () => {
    await dispatch(disableUserAsync(selectedUser.userId, true));
    setDisableUser(false);
  };

  const onDeleteToggle = (user) => {
    setSelectedUser(user);
    setDeleteUser(true);
  };

  const onDeleteUser = async () => {
    await dispatch(deleteUserAsync(selectedUser.userId, true));
    setDeleteUser(false);
  };

  const onPageChange = (page) => {
    dispatch(UserManagementActions.setUserPagination({ offset: page, limit }));
  };

  const onLimitChange = (e) => {
    dispatch(
      UserManagementActions.setUserPagination({
        offset: 1,
        limit: parseInt(e.target.value),
      })
    );
  };

  if (user.role !== "system_admin") return <Redirect to="/" />;

  return (
    <CommonLayout>
      <div className="card-box shadow p-3 mb-5 bg-white rounded">
        <div className="d-flex justify-content-end align-items-center mr-3">
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light w-10"
            onClick={() => onUserClick()}
          >
            Add User
          </button>
        </div>
        <div className="card-body">
          <table id="datatable" className="table dt-responsive nowrap">
            <thead>
              <tr>
                <th className="text-center align-middle">Name</th>
                {/* <th className="text-center align-middle">User Name</th> */}
                <th className="text-center align-middle">Email</th>
                <th className="text-center align-middle">Status</th>
                <th className="text-center align-middle">Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={8} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : userList && userList?.users?.length > 0 ? (
                userList?.users?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="d-flex justify-content-start align-items-center">
                        <span className="mr-2">
                          <ImageCard
                            src={item.profileImageUrl}
                            height="32"
                            width="32"
                            className="rounded-circle"
                            loading="lazy"
                            Fallback={<DefaultFallbackImage />}
                          />
                        </span>{" "}
                        {item.name}
                      </td>
                      {/* <td className="text-center align-middle">
                        {item.userName}
                      </td> */}
                      <td className="text-center align-middle">
                        {item.emailId}
                      </td>
                      <td
                        className={`text-center align-middle text-${
                          !item.isActive ? "danger" : "success"
                        }`}
                      >
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          color={!item.isActive ? "danger" : "success"}
                          label={
                            <span
                              className={`text-${
                                !item.isActive ? "danger" : "success"
                              }`}
                            >
                              {!item.isActive ? "Inactive" : "Active"}
                            </span>
                          }
                          checked={item.isActive}
                          onChange={() => onDisableToggle(item)}
                        />

                        {/* {!item.isActive ? "Inactive" : "Active"} */}
                      </td>
                      <td className="text-center align-middle">
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => onUserClick(item)}
                        >
                          <img src={Pen} alt="Pen" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-link p-0"
                          onClick={() => onDeleteToggle(item)}
                        >
                          {item.isDeleted ? (
                            <img src={Replay} alt="Replay" width="21px" />
                          ) : (
                            <img src={Trash} alt="Trash" />
                          )}
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Showing {offset * limit - (limit - 1)} to{" "}
              {limit * offset < totalRecords ? limit * offset : totalRecords} of{" "}
              {totalRecords} records
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mr-3 mb-2">
                Show{" "}
                <select value={limit} onChange={onLimitChange}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </select>{" "}
                entries
              </div>
              <BasicPagination
                totalRecords={totalRecords}
                limit={limit}
                batch={offset}
                onBatchChange={onPageChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/** Disable user confirmation modal */}
      <Modal show={showDisable} onHide={() => setDisableUser(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser && !selectedUser.isActive
              ? "Activate User"
              : "Inactivate User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {selectedUser && !selectedUser.isActive
              ? "Are you sure to activate user?"
              : "Are you sure to inactivate user?"}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light waves-effect"
            data-dismiss="modal"
          >
            Keep
          </button>
          <ButtonLoading
            className={`${
              selectedUser && !selectedUser.isActive
                ? "btn-primary"
                : "btn-danger"
            } waves-effect waves-light w-25`}
            onClick={onDisableUser}
            title={
              selectedUser && !selectedUser.isActive
                ? "Yes, Activate"
                : "Yes, Inactivate"
            }
            isLoading={loadingButton}
          />
        </Modal.Footer>
      </Modal>

      {/** Delete user confirmation modal */}
      <Modal show={showDelete} onHide={() => setDeleteUser(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser && selectedUser.isDeleted
              ? "Activate User"
              : "Delete User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {selectedUser && selectedUser.isDeleted
              ? "Are you sure to activate user?"
              : "Are you sure to delete user?"}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light waves-effect"
            data-dismiss="modal"
          >
            Keep
          </button>
          <ButtonLoading
            className={`${
              selectedUser && selectedUser.isDeleted
                ? "btn-primary"
                : "btn-danger"
            } waves-effect waves-light w-25`}
            onClick={onDeleteUser}
            title={
              selectedUser && selectedUser.isDeleted
                ? "Yes, Activate"
                : "Yes, Delete"
            }
            isLoading={loadingButton}
          />
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
};

export default UserManagement;
