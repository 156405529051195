export const BannerManagementMap = {
    GET_ALL_BANNER: "GET_ALL_BANNER",
    CREATE_BANNER: "CREATE_BANNER",
    UPDATE_BANNER: "UPDATE_BANNER",
    DELETE_BANNER: "DELETE_BANNER",
    SET_SELECTED_BANNER: "SET_SELECTED_BANNER",
    BANNER_PAGE_CHANGE: "BANNER_PAGE_CHANGE",
    BANNER_LIST_LOADING: "BANNER_LIST_LOADING",
    BANNER_SEARCH_CHANGE: "BANNER_SEARCH_CHANGE",
    BANNER_FILTER_CHANGE: "BANNER_FILTER_CHANGE",
  };
  
  export const BannerManagementActions = {
    getAllBanner: (data) => ({
      type: BannerManagementMap.GET_ALL_BANNER,
      payload: data,
    }),
  
    createBanner: (data) => ({
      type: BannerManagementMap.CREATE_BANNER,
      payload: data,
    }),
  
    updateBanner: (data) => ({
      type: BannerManagementMap.UPDATE_BANNER,
      payload: data,
    }),
  
    deleteBanner: (data) => ({
      type: BannerManagementMap.DELETE_BANNER,
      payload: data,
    }),
  
    setSelectedBanner: (data) => ({
      type: BannerManagementMap.SET_SELECTED_BANNER,
      payload: data,
    }),
  
    setBannerPagination: (data) => ({
      type: BannerManagementMap.BANNER_PAGE_CHANGE,
      payload: data,
    }),
  
    setBannerListLoading: (data) => ({
      type: BannerManagementMap.BANNER_LIST_LOADING,
      payload: data,
    }),
  
    setBannerSearch: (data) => ({
      type: BannerManagementMap.BANNER_SEARCH_CHANGE,
      payload: data,
    }),
    setBannerFilter: (data) => ({
      type: BannerManagementMap.BANNER_FILTER_CHANGE,
      payload: data,
    }),
  };
  