import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { BannerManagementActions } from "../app/modules/banner/redux/action";
import {
  deleteBannerAsync,
  getAllBannerAsync,
} from "../app/modules/banner/redux/api";
import { getAllCourseAsync } from "../app/modules/course/redux/api";

export const useBannerHook = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    bannerList,
    refreshBannerList,
    selectedBanner,
    limit,
    offset,
    totalCount,
  } = useSelector((state) => state.bannerManagement, shallowEqual);

  const { courseList, refreshCourseList } = useSelector(
    (state) => state.courseManagement,
    shallowEqual
  );

  const currentStartCount = offset * limit - (limit - 1);
  const currentEndCount =
    limit * offset < totalCount ? limit * offset : totalCount;

  useEffect(() => {
    if (refreshBannerList) {
      dispatch(getAllBannerAsync());
    }
  }, [refreshBannerList]);

  useEffect(() => {
    if (refreshCourseList) {
      dispatch(getAllCourseAsync());
    }
  }, [refreshCourseList]);

  useEffect(() => {
    setSelectedBanner(null);
  }, []);

  const setSelectedBanner = (banner) => {
    dispatch(
      BannerManagementActions.setSelectedBanner({ selectedBanner: banner })
    );
  };

  const deleteBanner = () => {
    return dispatch(deleteBannerAsync(selectedBanner.id));
  };

  const onLimitChange = (value) => {
    dispatch(
      BannerManagementActions.setBannerPagination({
        offset: 1,
        limit: parseInt(value),
      })
    );
  };

  const onPageChange = (page) => {
    dispatch(
      BannerManagementActions.setBannerPagination({ offset: page, limit })
    );
  };

  const onFilter = async (name, value) => {
    dispatch(BannerManagementActions.setBannerFilter({ name, value }));
  };
  return {
    isLoading,
    bannerList,
    refreshBannerList,
    selectedBanner,
    limit,
    offset,
    totalCount,
    currentEndCount,
    currentStartCount,
    courseList,
    setSelectedBanner,
    onLimitChange,
    onPageChange,
    deleteBanner,
    onFilter,
  };
};
