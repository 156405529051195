export const AsanaManagementMap = {
  GET_ALL_ASANA: "GET_ALL_ASANA",
  CREATE_ASANA: "CREATE_ASANA",
  UPDATE_ASANA: "UPDATE_ASANA",
  DELETE_ASANA: "DELETE_ASANA",
  SET_SELECTED_ASANA: "SET_SELECTED_ASANA",
  ASANA_PAGE_CHANGE: "ASANA_PAGE_CHANGE",
  ASANA_LIST_LOADING: "ASANA_LIST_LOADING",
  ASANA_SEARCH_CHANGE: "ASANA_SEARCH_CHANGE",
  ASANA_FILTER_CHANGE: "ASANA_FILTER_CHANGE",
};

export const AsanaManagementActions = {
  getAllAsana: (data) => ({
    type: AsanaManagementMap.GET_ALL_ASANA,
    payload: data,
  }),

  createAsana: (data) => ({
    type: AsanaManagementMap.CREATE_ASANA,
    payload: data,
  }),

  updateAsana: (data) => ({
    type: AsanaManagementMap.UPDATE_ASANA,
    payload: data,
  }),

  deleteAsana: (data) => ({
    type: AsanaManagementMap.DELETE_ASANA,
    payload: data,
  }),

  setSelectedAsana: (data) => ({
    type: AsanaManagementMap.SET_SELECTED_ASANA,
    payload: data,
  }),

  setAsanaPagination: (data) => ({
    type: AsanaManagementMap.ASANA_PAGE_CHANGE,
    payload: data,
  }),

  setAsanaListLoading: (data) => ({
    type: AsanaManagementMap.ASANA_LIST_LOADING,
    payload: data,
  }),

  setAsanaSearch: (data) => ({
    type: AsanaManagementMap.ASANA_SEARCH_CHANGE,
    payload: data,
  }),
  setAsanaFilter: (data) => ({
    type: AsanaManagementMap.ASANA_FILTER_CHANGE,
    payload: data,
  }),
};
