import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, Redirect } from "react-router-dom";
import { ButtonLoading } from "../../../../components/button";
import { updateProfileAsync } from "../redux/api";
import { get_diff_object } from "../../../../utils/functions";

const Profile = () => {

    const [file, setFile] = useState({ file: null, url: "" });

    const user = useSelector((state) => state.auth.user, shallowEqual);

    const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

    const initialValues = {
        name: user.name || "",
        userName: user.userName || "",
        email: user.email || "",
        profileImageUrl: user.profilePicture || "/assets/images/account-circle-outline.png",
    };

    const ProfileSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .min(2, "Please enter valid name")
            .max(36, "Please enter valid name")
            .required("Name is required"),
        userName: Yup.string()
            .trim()
            .min(2, "Please enter valid name")
            .max(36, "Please enter valid name")
            .required("User name is required"),
        email: Yup.string()
            .trim()
            .email("Enter valid email")
            .required("Email Id is required"),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }
        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues,
        validationSchema: ProfileSchema,
        onSubmit: (values) => {
            let formdata = null;
            if (file && file.file) {
                formdata = new FormData();
                formdata.append("file", file.file);
            }
            let diffObj = get_diff_object(user, values);
            if(Object.keys(diffObj).length || formdata) {
                diffObj.userId = user.userId;
                // dispatch(updateProfileAsync(diffObj, formdata));
            }
        }
    });

    const uploadImage = (e) => {
        if (e.target.files.length) {
            setFile({
                url: URL.createObjectURL(e.target.files[0]),
                file: e.target.files[0],
            });
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col-md-12 text-center mb-4">
                    <div
                        className="pro_img"
                        style={{
                            backgroundImage: `url(${file.url || formik.values.profileImageUrl})`
                        }}
                    >
                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={uploadImage}
                        />
                        <div className="edt_img_btn">
                            <span className="mdi mdi-pencil"></span>EDIT
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="userName">Name</label>
                        <input
                            type="text"
                            name="name"
                            parsley-trigger="change"
                            placeholder="Name"
                            id="name"
                            // defaultValue={user.name}
                            className={`form-control ${getInputClasses("name")}`}
                            {...formik.getFieldProps("name")}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="userName">User Name</label>
                        <input
                            type="text"
                            name="userName"
                            parsley-trigger="change"
                            placeholder="User Name"
                            id="userName"
                            // defaultValue={user.userName}
                            className={`form-control ${getInputClasses("userName")}`}
                            {...formik.getFieldProps("userName")}
                        />
                        {formik.touched.userName && formik.errors.userName ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    {formik.errors.userName}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className='form-group'>
                        <label htmlFor='emailaddress'>Email address</label>
                        <input
                            className={`form-control ${getInputClasses('email')}`}
                            type='email'
                            id='emailaddress'
                            placeholder='Enter your email'
                            name='email'
                            {...formik.getFieldProps('email')}
                        // defaultValue={user.emailId}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="form-group text-right">
                <Link
                    to="/dashboard"
                    className="btn btn-light waves-effect waves-light mr-3"
                >
                    Cancel
                </Link>
                <ButtonLoading
                    className="btn-primary waves-effect waves-light w-15"
                    type="submit"
                    title="Update"
                    isLoading={loadingButton}
                />
            </div>
        </form>
    );
};

export default Profile;
