import React, { useMemo } from "react";
import { Form, Modal } from "react-bootstrap";
import get from "lodash/get";
import { ButtonLoading } from "../../../../components/button";
// import { asanaOptions } from "../config";
import { useFormik } from "formik";
import { CustomAsyncSelect } from "../../../../components/inputs/async-select";
import {
  convertHHMMSSToMoment,
  getHHMMSSMoment,
  hhmmssToSeconds,
  secTohhmmss,
} from "../../../../utils/functions";
import { AsanaValidationSchema } from "../../course/config";
import { TimeInput } from "../../../../components/inputs/time-picker";

export const PoseModal = ({
  show,
  setShow,
  formik,
  name,
  poseList,
  poseSearchPromiseOptions,
  selectedAsanaPose,
  setSelectedAsanaPose,
}) => {
  const options = useMemo(
    () =>
      poseList?.length > 0 &&
      poseList.map((pose) => ({
        label: pose.poseName,
        value: pose.id,
        config: { poseName: pose.poseName },
      })),
    [poseList]
  );
  const defaultData = useMemo(() => {
    if (selectedAsanaPose) {
      return {
        id: {value: selectedAsanaPose.id, ...selectedAsanaPose},
        startTime: convertHHMMSSToMoment(
          secTohhmmss(selectedAsanaPose.startTime)
        ),
        endTime: convertHHMMSSToMoment(secTohhmmss(selectedAsanaPose.endTime)),
      };
    } else {
      return {
        id: "",
        startTime: convertHHMMSSToMoment("00:00:00"),
        endTime: convertHHMMSSToMoment("00:00:00"),
      };
    }
  }, [selectedAsanaPose]);
  const poseFormik = useFormik({
    initialValues: {
      ...defaultData,
    },
    validationSchema: AsanaValidationSchema,
    enableReinitialize: true,
  });

  const onSave = async () => {
    if (poseFormik.values.id) {
      let poses = [...get(formik.values, name)];
      console.log(poseFormik);
      let data = {
        id: poseFormik.values.id.value,
        startTime: hhmmssToSeconds(
          getHHMMSSMoment(poseFormik.values.startTime)
        ),
        endTime: hhmmssToSeconds(getHHMMSSMoment(poseFormik.values.endTime)),
      };
      if (selectedAsanaPose) {
        poses = poses.map((pose, i) => {
          if (i === selectedAsanaPose.index) {
            return {
              ...data,
              config: poseFormik.values.id.config || {},
            };
          }
          return pose;
        });
      } else {
        poses.push({
          ...data,
          config: poseFormik.values.id.config || {},
        });
      }
      formik.setFieldValue(name, [...poses]);
    }
    poseFormik.resetForm();
    setSelectedAsanaPose(null);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setSelectedAsanaPose(null);
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Pose</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomAsyncSelect
          label="Pose"
          name={`id`}
          formik={poseFormik}
          options={options}
          isSearchable
          propmiseOptions={poseSearchPromiseOptions}
          defaultValue={
            selectedAsanaPose
              ? [
                  {
                    label: selectedAsanaPose?.config?.poseName,
                    value: selectedAsanaPose.id,
                  },
                ]
              : []
          }
        />
        {/* <CustomSelect
          label="Asana"
          name={`asana`}
          formik={poseFormik}
          options={asanaOptions}
          isSearchable
        /> */}
        <TimeInput
          name="startTime"
          label="Start Time"
          placeholder="hh:mm:ss"
          id="startTime"
          formik={poseFormik}
          value={poseFormik.values.startTime}
          onChange={(val) => poseFormik.setFieldValue("startTime", val)}
        />
        <TimeInput
          name="endTime"
          label="End Time"
          placeholder="hh:mm:ss"
          id="endTime"
          // formik={poseFormik}
          value={poseFormik.values.endTime}
          onChange={(val) => poseFormik.setFieldValue("endTime", val)}
        />
        {/* <TextInput
          name="endTime"
          label="End Time"
          placeholder="hh:mm:ss"
          id="endTime"
          formik={poseFormik}
          type="time"
          step="2"
          pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
        /> */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light waves-effect"
          onClick={() => setShow(false)}
        >
          Cancel
        </button>
        <ButtonLoading
          className={`btn-primary waves-effect waves-light w-25`}
          onClick={onSave}
          title="Save"
          isLoading={false}
        />
      </Modal.Footer>
    </Modal>
  );
};
