import React from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from './app/routes.js'
import store, { persistor } from './redux/store';
import * as _redux from './redux';
import SplashScreen from './components/splashscreen';
import { initFirebase } from './utils/firebase';
import { ToastContainer } from 'react-toastify';
// import "./assets/index.scss";

import './app.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-time-picker/assets/index.css';

initFirebase();

_redux.setupAxios(axios, store);

function App() {

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <BrowserRouter>
                    <React.Suspense fallback={<SplashScreen />}>
                        <Routes />
                        <ToastContainer autoClose={3000} />
                    </React.Suspense>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
}

export default App;
