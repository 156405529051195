import axios from "axios";
import { apiService } from "./config";

export const get = async (url, options) => {
  const { serverUrl, baseUrl } = apiService;
  let headers = options?.headers || {};
  return axios({
    method: "GET",
    url: `${serverUrl}/${baseUrl}/${url}`,
    headers: {
      "Accept-Language": "en",
      ...headers,
    },
  });
};

export const post = (url, data, options = {}) => {
  const { serverUrl, baseUrl } = apiService;
  let headers = {
    "Content-Type": "application/json",
    "Accept-Language": "en",
  };
  if (options.headers) {
    headers = {
      ...headers,
      ...options.headers,
    };
  }
  return axios({
    method: "POST",
    url: `${serverUrl}/${baseUrl}/${url}`,
    data: data,
    headers,
  });
};

export const update = async (url, data) => {
  const { serverUrl, baseUrl } = apiService;
  return axios({
    method: "PUT",
    url: `${serverUrl}/${baseUrl}/${url}`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const remove = async (url, data) => {
  const { serverUrl, baseUrl } = apiService;
  return axios({
    method: "DELETE",
    url: `${serverUrl}/${baseUrl}/${url}`,
    data: data,
  });
};

export const upload = async (url, formdata) => {
  const { serverUrl, baseUrl } = apiService;
  let { data } = await axios({
    method: "POST",
    url: `${serverUrl}/${baseUrl}/${url}`,
    data: formdata,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (!data.isError) {
    return data.data.file.fileUrl;
  }
};

export const uploadSigned = (url, file) => {
  return axios({
    method: "PUT",
    url: url,
    data: file,
    headers: {
      "Content-Type": file.type,
    },
    // onUploadProgress: event => {
    //     let current = store.getState().general.fileUploadProgress.current;
    //     store.dispatch(toggleFileUploadProgress({ visible: true, current: current + 1 }))
    // }
  });
};

export const getUploadSignedUrl = (files) => {
  return post("file/upload", files);
};

export const getDownloadSignedUrl = (data) => {
  return post("file/download", data);
};
