export const TABS_TYPES = {
  PRIVACY_POLICY: "privacy_policy",
  TERMS_AND_CONDITIONS: "terms_and_conditions",
};

export const TABS = [
  {
    key: TABS_TYPES.PRIVACY_POLICY,
    title: "Privacy Policy",
  },
  {
    key: TABS_TYPES.TERMS_AND_CONDITIONS,
    title: "Terms & Conditions",
  },
];

export const initState = {
  [TABS_TYPES.PRIVACY_POLICY]: "",
  [TABS_TYPES.TERMS_AND_CONDITIONS]: "",
};
