import * as Yup from "yup";
import { LANGUAGES } from "../../../../utils/constants";
import { generateOriginalUrl, getFileName } from "../../../../utils/functions";

export const options = [
  { value: "course", label: "Course" },
  { value: "link", label: "Link" },
];

export const CreateBannerSchema = Yup.object().shape({
  section: Yup.string().required("Section is required"),
  type: Yup.string().required("Type is required"),
  order: Yup.string().required("Order is required"),
});
export const initialValues = {
  section: "",
  type: "",
  order: "",
  link: "",
  course: "",
  [LANGUAGES.ENGLISH]: {
    thumbnail: [],
  },
  [LANGUAGES.HINDI]: {
    thumbnail: [],
  },
};

export const createBannerPayload = (data, selectedBanner) => ({
  section: data.section,
  type: data.type,
  order: data.order,
  link: data.type === "link" ? data.link : "",
  course: data.type === "course" ? data.course.value : "",
  details: {
    en: {
      thumbnailUrl:
        typeof data.english.thumbnail[0] === "string"
          ? generateOriginalUrl(selectedBanner?.details?.en?.thumbnailUrl)
          : "",
    },
    hi: {
      thumbnailUrl:
        typeof data.hindi.thumbnail[0] === "string"
          ? generateOriginalUrl(selectedBanner?.details?.hi?.thumbnailUrl)
          : "",
    },
  },
});

export const getBannerFiles = ({ english, hindi }) => {
  let files = {
    english: { fileDetails: [] },
    hindi: { fileDetails: [] },
  };
  let filesObj = { english: [], hindi: [] };
  if (english.thumbnail.length && typeof english.thumbnail[0] !== "string") {
    let file = english.thumbnail[0];
    filesObj.english.push(file);
    files.english.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "banner",
      refId: "thumbnailUrl",
      language: "en",
    });
  }
  if (hindi.thumbnail.length && typeof hindi.thumbnail[0] !== "string") {
    let file = hindi.thumbnail[0];
    filesObj.hindi.push(file);
    files.hindi.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "banner",
      refId: "thumbnailUrl",
      language: "hi",
    });
  }
  let filesData = {};
  if (files.english.fileDetails.length) {
    filesData.english = files.english;
  }
  if (files.hindi.fileDetails.length) {
    filesData.hindi = files.hindi;
  }
  return { filesData, files: filesObj };
};

export const formatSelectedBannerData = (data) => ({
  section: data.section,
  type: data.type,
  order: data.order,
  link: data.link,
  course: data.course,
  [LANGUAGES.ENGLISH]: {
    thumbnail: data.details.en.thumbnailUrl
      ? [data.details.en.thumbnailUrl]
      : [],
  },
  [LANGUAGES.HINDI]: {
    thumbnail: data.details.hi.thumbnailUrl
      ? [data.details.hi.thumbnailUrl]
      : [],
  },
});
