export const PAGE_TITLE = {
  DASHBOARD: "Dashboard",
  USER_MANAGEMENT: "User Management",
  CATEGORY_MANAGEMENT: "Category Management",
  PROFILE_SETINGS: "Profile Settings",
  COURSE_MANAGEMENT: "Course Management",
  ASANA_MANAGEMENT: "Asana Management",
  BANNER_MANAGEMENT: "Banner Management",
  POSE_MANAGEMENT: "Pose Management",
  CONTENT_MANAGEMENT: "Content Management",
};

export const PATHS = {
  DASHBOARD: "/dashboard",
  PROFILE_SETINGS: "/profile-settings",

  USER_MANAGEMENT: "/user-management",
  CREATE_USER: "/create-user",
  EDIT_USER: "/:userId",

  CATEGORY_MANAGEMENT: "/category-management",
  CREATE_CATEGORY: "/create-category",

  COURSE_MANAGEMENT: "/course-management",
  CREATE_COURSE: "/course/create",
  CREATE_MULTI_COURSE: "/multi-course/create",
  COURSE: "course",
  EDIT_COURSE: "/course/:courseId",
  MULTI_COURSE: "multi-course",
  EDIT_MULTI_COURSE: "/multi-course/:courseId",

  ASANA_MANAGEMENT: "/asana-management",
  CREATE_ASANA: "/create-asana",
  EDIT_ASANA: "/:asanaId",

  BANNER_MANAGEMENT: "/banner-management",
  CREATE_BANNER: "/create-banner",
  EDIT_BANNER: "/:bannerId",

  POSE_MANAGEMENT: "/pose-management",
  CREATE_POSE: "/create-pose",
  EDIT_POSE: "/:poseId",

  CONTENT_MANAGEMENT: "/content-management",
};

export const links = [
  {
    name: PAGE_TITLE.DASHBOARD,
    route: PATHS.DASHBOARD,
    icon: "mdi-view-dashboard",
  },
  {
    name: PAGE_TITLE.USER_MANAGEMENT,
    route: PATHS.USER_MANAGEMENT,
    icon: "mdi-account-multiple",
  },
  {
    name: PAGE_TITLE.COURSE_MANAGEMENT,
    route: PATHS.COURSE_MANAGEMENT,
    icon: "mdi-cube-outline",
  },
  {
    name: PAGE_TITLE.ASANA_MANAGEMENT,
    route: PATHS.ASANA_MANAGEMENT,
    icon: "mdi-image-filter-vintage",
  },
  {
    name: PAGE_TITLE.POSE_MANAGEMENT,
    route: PATHS.POSE_MANAGEMENT,
    icon: "mdi-image-multiple",
  },
  {
    name: PAGE_TITLE.BANNER_MANAGEMENT,
    route: PATHS.BANNER_MANAGEMENT,
    icon: "mdi-desktop-mac",
  },
  {
    name: PAGE_TITLE.CONTENT_MANAGEMENT,
    route: PATHS.CONTENT_MANAGEMENT,
    icon: "mdi-file-document",
  },
  {
    name: PAGE_TITLE.PROFILE_SETINGS,
    route: PATHS.PROFILE_SETINGS,
    hideAsideMenu: true,
  },
];
