import { AsanaManagementMap } from "./action";

const initialState = {
  isLoading: false,
  asanaList: {},
  refreshAsanaList: true,
  selectedAsana: null,
  limit: 10,
  offset: 1,
  totalCount: 0,
  redirect: false,
  searchText: "",
  apiData: null,
  filters: {
    difficulty: "",
    type: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AsanaManagementMap.ASANA_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case AsanaManagementMap.GET_ALL_ASANA: {
      return {
        ...state,
        asanaList: action.payload.asana,
        totalCount: action.payload.totalCount,
        refreshAsanaList: false,
        redirect: false,
      };
    }
    case AsanaManagementMap.CREATE_ASANA: {
      return {
        ...state,
        refreshAsanaList: true,
        redirect: true,
      };
    }
    case AsanaManagementMap.DELETE_ASANA: {
      return {
        ...state,
        refreshAsanaList: true,
        selectedAsana: null,
      };
    }
    case AsanaManagementMap.SET_SELECTED_ASANA: {
      return {
        ...state,
        selectedAsana: action.payload.selectedAsana,
        apiData: action.payload.apiData,
      };
    }
    case AsanaManagementMap.ASANA_PAGE_CHANGE: {
      return {
        ...state,
        offset: action.payload.offset || state.offset,
        limit: action.payload.limit || state.limit,
        refreshAsanaList: true,
      };
    }
    case AsanaManagementMap.ASANA_SEARCH_CHANGE: {
      return {
        ...state,
        searchText: action.payload.searchText,
        offset: 1,
        refreshAsanaList: true,
      };
    }
    case AsanaManagementMap.ASANA_FILTER_CHANGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value,
        },
        offset: 1,
        refreshAsanaList: true,
      };
    }
    default:
      return { ...state };
  }
};
