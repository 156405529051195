import React from "react";
import { IconButton } from "../../../../components/button/common-button";
import { CommonTable } from "../../../../components/table/common-table";

const columns = [
  {
    id: "fileName",
    label: "Video Name",
    className: "text-center align-middle",
  },
  {
    id: "quality",
    label: "Video Quality",
    className: "text-center align-middle",
  },
];

const DeleteAction = ({ onDelete }) => (
  <IconButton
    color="danger"
    icon={<i className="fe-trash-2"></i>}
    className="btn-icon py-0 px-1 ml-2"
    onClick={onDelete}
  />
);

const getActions = (onDelete) => {
  return {
    active: true,
    className: "text-center align-middle",
    lists: [<DeleteAction onDelete={onDelete} />],
  };
};

export const VideoTable = ({
  onBtnClick,
  rows = [],
  onDeleteVideo,
  onEditVideo,
}) => {
  const onDelete = (index) => {
    onDeleteVideo(index);
  };
  const onEdit = (item, index) => {
    onEditVideo(item, index);
  };
  return (
    <div className="card-group card border border-light p-2">
      <div className="form-group w-100">
        <label>Videos</label>
        <IconButton
          color={"primary"}
          type="button"
          icon={<i className="fas fa-plus "></i>}
          className="btn-icon py-0 px-1 ml-2"
          onClick={onBtnClick}
          disabled={rows.length >= 3}
        />
        <table id="datatable" className="table dt-responsive nowrap">
          <thead>
            <tr>
              {/* <th className="text-center align-middle">Video name</th> */}
              <th className="text-center align-middle">Quality</th>
              <th className="text-center align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows?.length > 0 ? (
              rows.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td className="text-center align-middle">{item.fileName}</td> */}
                    <td className="text-center align-middle">
                      {item?.quality}
                    </td>
                    <td className="text-center align-middle">
                      <IconButton
                        color="warning"
                        icon={<i className="fe-edit-1"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onEdit(item)}
                      />
                      <IconButton
                        color="danger"
                        icon={<i className="fe-trash-2"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onDelete(index)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
