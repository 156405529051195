import React from "react";
import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";

export const CustomCreatableSelect = ({
  label,
  name,
  formik,
  isLoading = false,
  isSearchable = false,
  isMulti = false,
  isClearable = false,
  defaultValue = [],
  options = [],
  value = [],
}) => {
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const onChange = (data) => {
    if (isMulti) {
      let values = data.map((item) => item.value);
      return formik.setFieldValue(name, values);
    }
    formik.setFieldValue(name, data.value);
  };
  return (
    <div className="form-group">
      <label style={{ marginTop: "2px" }}>{label}</label>
      <CreatableSelect
        className={getInputClasses(name)}
        classNamePrefix="react-select"
        defaultValue={defaultValue}
        value={value}
        // isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        isLoading={isLoading}
        isSearchable={isSearchable}
        name={name}
        options={options}
        onChange={onChange}
      />
      {formik.touched[name] && formik.errors[name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      ) : null}
    </div>
  );
};
