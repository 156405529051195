import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import get from "lodash/get";
import { ButtonLoading } from "../../../../components/button";
import { CustomAsyncSelect } from "../../../../components/inputs/async-select";
import { asanaOptions, AsanaValidationSchema } from "../config";
import { TextInput } from "../../../../components/inputs/text-input";
import { useFormik } from "formik";
import { convertHHMMSSToMoment, getHHMMSSMoment, hhmmssToSeconds, secTohhmmss } from "../../../../utils/functions";
import { TimeInput } from "../../../../components/inputs/time-picker";

export const AsanaModal = ({
  show,
  setShow,
  formik,
  name,
  asanaList,
  asanaSearchPromiseOptions,
  selectedAsanaPose,
  setSelectedAsanaPose,
}) => {
  const options = useMemo(
    () =>
      asanaList &&
      asanaList.length > 0 &&
      asanaList.map((asana) => ({
        label: asana?.details?.en?.name + " - " + asana.details?.hi?.name,
        value: asana.id,
        details: asana?.details,
      })),
    [asanaList]
  );

  const defaultData = useMemo(() => {
    if (selectedAsanaPose) {
      return {
        id: {value: selectedAsanaPose.id, ...selectedAsanaPose},
        startTime: convertHHMMSSToMoment(
          secTohhmmss(selectedAsanaPose.startTime)
        ),
        endTime: convertHHMMSSToMoment(secTohhmmss(selectedAsanaPose.endTime)),
      };
    } else {
      return {
        id: "",
        startTime: convertHHMMSSToMoment("00:00:00"),
        endTime: convertHHMMSSToMoment("00:00:00"),
      };
    }
  }, [selectedAsanaPose]);

  const asanaFormik = useFormik({
    initialValues: {
      ...defaultData,
    },
    validationSchema: AsanaValidationSchema,
    enableReinitialize: true,
  });

  const onSave = async () => {
    if (asanaFormik.values.id) {
      let asanas = [...get(formik.values, name)];
      let data = {
        id: asanaFormik.values.id.value,
        startTime: hhmmssToSeconds(getHHMMSSMoment(asanaFormik.values.startTime)),
        endTime: hhmmssToSeconds(getHHMMSSMoment(asanaFormik.values.endTime)),
      };
      if (selectedAsanaPose) {
        asanas = asanas.map((asana, i) => {
          if (i === selectedAsanaPose.index) {
            return {
              ...data,
              details: asanaFormik.values.id.details || {},
            };
          }
          return asana;
        });
      } else {
        asanas.push({
          ...data,
          details: asanaFormik.values.id.details || {},
        });
      }
      formik.setFieldValue(name, [...asanas]);
    }
    asanaFormik.resetForm();
    setShow(false);
    setSelectedAsanaPose(null);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setSelectedAsanaPose(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Asana</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomAsyncSelect
          label="Asana"
          name={`id`}
          formik={asanaFormik}
          options={options}
          isSearchable
          propmiseOptions={asanaSearchPromiseOptions}
          defaultValue={
            selectedAsanaPose
              ? [
                  {
                    label:
                      selectedAsanaPose?.details?.en?.name +
                      " - " +
                      selectedAsanaPose?.details?.hi?.name,
                    value: selectedAsanaPose.id,
                  },
                ]
              : []
          }
        />
        <TimeInput
          name="startTime"
          label="Start Time"
          placeholder="hh:mm:ss"
          id="startTime"
          value={asanaFormik.values.startTime}
          onChange={(val) => asanaFormik.setFieldValue("startTime", val)}
        />
        <TimeInput
          name="endTime"
          label="End Time"
          placeholder="hh:mm:ss"
          id="endTime"
          value={asanaFormik.values.endTime}
          onChange={(val) => asanaFormik.setFieldValue("endTime", val)}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light waves-effect"
          onClick={() => setShow(false)}
        >
          Cancel
        </button>
        <ButtonLoading
          className={`btn-primary waves-effect waves-light w-25`}
          onClick={onSave}
          title="Save"
          isLoading={false}
        />
      </Modal.Footer>
    </Modal>
  );
};
