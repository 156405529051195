import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useParams, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import isEqual from "lodash/isEqual";
import {
  initialMultiCourseValues,
  createMultiCoursePayload,
  getCourseFiles,
  formatSelectedMultiCourseData,
  practiceOptions,
  studioOptions,
  awareOptions,
  tagsOptions,
  dayFormikInitData,
  createDaysPayload,
  formatSelectedDayData,
  CreateMultiCourseSchema,
} from "../config";
import { CommonCard } from "../../../../components/card/common-card";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { TextInput } from "../../../../components/inputs/text-input";
import { PATHS } from "../../../../components/aside-menu/links";
import { ButtonLoading } from "../../../../components/button";
import { CustomSelect } from "../../../../components/inputs/select";
import {
  COURSE_TYPES,
  MULTI_COURSE_TYPE_OPTIONS,
  LANGUAGES,
} from "../../../../utils/constants";
import { useContentLanguage } from "../../../../hooks/content-language-hook";
import { LanguageButton } from "../../../../components/button/language-buttons";
import { Textarea } from "../../../../components/inputs/textarea";
import { Dropzone } from "../../../../components/dropzone/dropzone";
import { VideoTable } from "../components/video-table";
import { VideoModal } from "../components/video-modal";
import { AsanaModal } from "../components/asana-modal";
import { AsanaTable } from "../components/asana-table";
import { PoseModal } from "../components/pose-modal";
import { PoseTable } from "../components/pose-table";
import { CustomCreatableSelect } from "../../../../components/inputs/creatable-select";
import { useCourseHook } from "../../../../hooks/course-data-hook";
import {
  createDaysAsync,
  createMultiCourseAsync,
  deleteDaysAsync,
  getCourseDetailsAsync,
  updateDaysAsync,
  updateMultiCourseAsync,
} from "../redux/api";
import "./course.css";
import { useAsanaHook } from "../../../../hooks/asana-data-hook";
import { usePoseHook } from "../../../../hooks/pose-data-hook";
import { searchAsanaAsync } from "../../asana/redux/api";
import { searchPoseAsync } from "../../pose/redux/api";
import { CourseManagementActions } from "../redux/actions";
import { Button, Modal } from "react-bootstrap";

export const CreateMultiCourse = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const isEditMode = params.courseId;

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  const { selectedCourse, isLoading, redirect, apiData } = useCourseHook();
  const asanaData = useAsanaHook();
  const poseData = usePoseHook();

  const { language, otherLanguage, toggleLanguage } = useContentLanguage();

  const [videoModal, setVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [asanaModal, setAsanaModal] = useState(false);
  const [poseModal, setPoseModal] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const [selectedAsanaPose, setSelectedAsanaPose] = useState(null);

  let defaultData = useMemo(
    () =>
      isEditMode && selectedCourse
        ? formatSelectedMultiCourseData(selectedCourse)
        : JSON.parse(JSON.stringify(initialMultiCourseValues)),
    [selectedCourse]
  );
  let defaultDaysData = useMemo(
    () =>
      isEditMode && selectedCourse
        ? formatSelectedDayData(selectedCourse)
        : JSON.parse(JSON.stringify(dayFormikInitData)),
    [selectedCourse]
  );

  useEffect(() => {
    if (isEditMode) {
      dispatch(getCourseDetailsAsync(isEditMode, 1, true));
    }
  }, [isEditMode]);

  const formik = useFormik({
    initialValues: defaultData,
    validationSchema: CreateMultiCourseSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const payload = createMultiCoursePayload(values, apiData, isEditMode);
      const filesDetails = getCourseFiles(values);
      const daysPayload = createDaysPayload(
        { ...daysFormik.values, day: isEditMode ? daysFormik.values.day : "1" },
        apiData
      );
      const daysFilesDetails = getCourseFiles(daysFormik.values);
      if (selectedCourse) {
        let isDataEqual = isEqual(values, defaultData);
        if (!isDataEqual) {
          let data = {
            ...payload,
            id: isEditMode,
          };
          await dispatch(updateMultiCourseAsync(data, filesDetails));
        }
        daysFormik.handleSubmit();
        return;
      }
      dispatch(
        createMultiCourseAsync(
          payload,
          filesDetails,
          daysPayload,
          daysFilesDetails,
          () => {
            resetForm({ values: { ...initialMultiCourseValues } });
            daysFormik.resetForm({ values: { ...dayFormikInitData } });
          }
        )
      );
    },
  });

  const onLanguageChange = (lang) => toggleLanguage(lang);

  const onDeleteVideo = (index) => {
    let allVideos = daysFormik.values[language].videos;
    daysFormik.setFieldValue(
      `${language}.videos`,
      allVideos.filter((_, i) => i !== index)
    );
  };

  const onEditVideo = (item, index) => {
    setSelectedVideo(item);
    setVideoModal(true);
  };

  const onDeleteAsana = (index) => {
    let allAsanas = daysFormik.values[language].asanas;
    daysFormik.setFieldValue(
      `${language}.asanas`,
      allAsanas.filter((_, i) => i !== index)
    );
  };

  const onDeletePose = (index) => {
    let allPoses = daysFormik.values[language].poses;
    daysFormik.setFieldValue(
      `${language}.poses`,
      allPoses.filter((_, i) => i !== index)
    );
  };

  const asanaSearchPromiseOptions = useCallback(async (value) => {
    let data = await searchAsanaAsync(value);
    if (data?.length) {
      return data.map((asana) => ({
        label: asana.details.en.name + " - " + asana.details.hi.name,
        value: asana.id,
        details: asana.details,
      }));
    }
    return [];
  }, []);

  const poseSearchPromiseOptions = useCallback(async (value) => {
    let data = await searchPoseAsync(value);
    if (data?.length) {
      return data.map((pose) => ({
        label: pose.poseName,
        value: pose.id,
        config: { poseName: pose.poseName },
      }));
    }
    return [];
  }, []);

  /**
   * Days methods
   */
  const daysOptions = useMemo(() => {
    const duration = parseInt(formik.values.courseDuration);
    if (isFinite(duration)) {
      return new Array(duration)
        .fill()
        .map((_, index) => ({ value: index + 1, label: `Day ${index + 1}` }));
    }
    return [];
  }, [formik.values.courseDuration]);

  const daysFormik = useFormik({
    initialValues: defaultDaysData,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const daysPayload = createDaysPayload(values, apiData);
      const daysFilesDetails = getCourseFiles(values);
      if (selectedCourse) {
        if (apiData.isNewDay) {
          await dispatch(
            createDaysAsync(daysPayload, daysFilesDetails, isEditMode, () => {
              dispatch(
                CourseManagementActions.updateApiData({ isNewDay: false })
              );
            })
          );
        } else {
          let isDayEqual = isEqual(values, defaultDaysData);
          if (!isDayEqual) {
            await dispatch(
              updateDaysAsync(daysPayload, daysFilesDetails, isEditMode)
            );
          }
        }
        return;
      }
    },
  });

  const onDayChangeCallback = (value) => {
    if (isEditMode) {
      const initData = {
        ...apiData,
        details: {
          en: { ...apiData.details.en, videoUrls: [], pose: [], asanas: [] },
          hi: { ...apiData.details.hi, videoUrls: [], pose: [], asanas: [] },
        },
        day: value,
      };

      dispatch(getCourseDetailsAsync(isEditMode, value, true, initData));
    }
  };

  const onDeleteToggle = () => {
    setShowDelete((prev) => !prev);
  };
  const onDeleteDay = async () => {
    await dispatch(
      deleteDaysAsync({
        courseId: isEditMode,
        day: daysFormik.values.day,
        language: "en",
      })
    );
    await dispatch(
      deleteDaysAsync({
        courseId: isEditMode,
        day: daysFormik.values.day,
        language: "hi",
      })
    );
    let initData = {
      ...apiData,
      details: {
        en: { ...apiData.details.en, videoUrls: [], pose: [], asanas: [] },
        hi: { ...apiData.details.hi, videoUrls: [], pose: [], asanas: [] },
      },
      day: daysFormik.values.day,
      isNewDay: true,
    };
    // await getCourseDownloadUrl(data.data);
    dispatch(
      CourseManagementActions.setSelectedCourse({
        selectedCourse: initData || null,
        apiData,
      })
    );
    setShowDelete(false);
  };

  const onEditClickAsana = (item) => {
    setSelectedAsanaPose(item);
    setAsanaModal(true);
  };
  const onEditClickPose = (item) => {
    setSelectedAsanaPose(item);
    setPoseModal(true);
  };

  if (redirect && !isEditMode) {
    return <Redirect to={PATHS.COURSE_MANAGEMENT} />;
  }

  return (
    <CommonLayout>
      <CommonCard
        className="card w-75 mx-auto"
        header={
          <>
            <Link to={PATHS.COURSE_MANAGEMENT}>
              <span
                className="mdi mdi-arrow-left mr-2"
                style={{ fontSize: "x-large" }}
              ></span>
            </Link>{" "}
            <h4 className="header-title m-0">
              {isEditMode ? "Update" : "Create"} Multi Course
            </h4>
          </>
        }
        headerClassName="card-header card-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <CustomSelect
                label="Course Type"
                name="courseType"
                formik={formik}
                options={MULTI_COURSE_TYPE_OPTIONS}
                value={[
                  MULTI_COURSE_TYPE_OPTIONS.find(
                    (option) => option.value === formik.values.courseType
                  ),
                ]}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                type="number"
                name="courseDuration"
                label="Course Duration"
                placeholder="Enter course duration (days)"
                id="courseDuration"
                formik={formik}
                min={1}
                max={31}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomCreatableSelect
                label="Tags"
                name="tags"
                formik={formik}
                options={tagsOptions}
                isSearchable
                isMulti
                value={formik.values.tags.map((tag) => ({
                  value: tag,
                  label: tag,
                }))}
              />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Categories"
                name="categories"
                formik={formik}
                options={
                  formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value
                    ? practiceOptions
                    : formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value
                    ? studioOptions
                    : formik.values.courseType === COURSE_TYPES.AWARE.value
                    ? awareOptions
                    : []
                }
                isSearchable
                isMulti
                value={formik.values.categories.map((category) => ({
                  label: category,
                  value: category,
                }))}
              />
            </div>
          </div>
          <hr className="my-3" />
          <div className="row">
            <div className="col-md-11">
              <LanguageButton
                language={language}
                onLanguageChange={onLanguageChange}
                errorBorder={
                  formik.touched[otherLanguage] && formik.errors[otherLanguage]
                }
              />
            </div>
          </div>
          <div className="chat-day-title my-2">
            <span className="title text-capitalize">{language}</span>
          </div>
          {language === LANGUAGES.ENGLISH && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.ENGLISH}.title`}
                    label="Title"
                    placeholder="Enter title"
                    id={`${LANGUAGES.ENGLISH}.title`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.ENGLISH}.duration`}
                    label="Duration"
                    placeholder="Enter duration"
                    id={`${LANGUAGES.ENGLISH}.duration`}
                    formik={formik}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Textarea
                    name={`${LANGUAGES.ENGLISH}.aboutCourse`}
                    label="About course"
                    placeholder="Enter about course"
                    id={`${LANGUAGES.ENGLISH}.aboutCourse`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <Dropzone
                    label="Thumbnail"
                    text="Drag 'n' drop image here, or click to select image"
                    info="(Only 1 image file will be accepted)"
                    allow="image/png, image/jpeg, image/jpg"
                    type="image"
                    hideDropZoneOnPreview
                    preview
                    name={`${LANGUAGES.ENGLISH}.thumbnail`}
                    formik={formik}
                    language={language}
                  />
                </div>
              </div>
              {formik.values.courseType === COURSE_TYPES.AWARE.value && (
                <div className="row">
                  <div className="col-md-12">
                    <Dropzone
                      label="Audio"
                      text="Drag 'n' drop audio here, or click to select audio"
                      info="(Only 1 audio file will be accepted)"
                      allow="audio/*"
                      type="audio"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.ENGLISH}.audio`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {language === LANGUAGES.HINDI && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.HINDI}.title`}
                    label="Title"
                    placeholder="Enter title"
                    id={`${LANGUAGES.HINDI}.title`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.HINDI}.duration`}
                    label="Duration"
                    placeholder="Enter duration"
                    id={`${LANGUAGES.HINDI}.duration`}
                    formik={formik}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Textarea
                    name={`${LANGUAGES.HINDI}.aboutCourse`}
                    label="About course"
                    placeholder="Enter about course"
                    id={`${LANGUAGES.HINDI}.aboutCourse`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <Dropzone
                    label="Thumbnail"
                    text="Drag 'n' drop image here, or click to select image"
                    info="(Only 1 image file will be accepted)"
                    allow="image/png, image/jpeg, image/jpg"
                    type="image"
                    hideDropZoneOnPreview
                    preview
                    name={`${LANGUAGES.HINDI}.thumbnail`}
                    formik={formik}
                    language={language}
                  />
                </div>
              </div>
              {formik.values.courseType === COURSE_TYPES.AWARE.value && (
                <div className="row">
                  <div className="col-md-12">
                    <Dropzone
                      label="Audio"
                      text="Drag 'n' drop audio here, or click to select audio"
                      info="(Only 1 audio file will be accepted)"
                      allow="audio/*"
                      type="audio"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.HINDI}.audio`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <hr className="my-3" />
          <div className="chat-day-title my-2">
            <span className="title text-capitalize">Manage Days</span>
            {isEditMode && (
              <Button
                className={`btn-danger waves-effect waves-light float-right`}
                onClick={onDeleteToggle}
              >
                Delete Day
              </Button>
            )}
          </div>
          {isLoading && (
            <div className="text-center">
              <div className="spinner-border spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {daysOptions.length > 0 && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <CustomSelect
                    label="Day"
                    name="day"
                    formik={daysFormik}
                    options={
                      isEditMode
                        ? daysOptions || []
                        : [
                            {
                              label: `Day 1`,
                              value: "1",
                            },
                          ]
                    }
                    isSearchable
                    value={
                      isEditMode
                        ? [
                            {
                              label: `Day ${daysFormik.values.day}`,
                              value: daysFormik.values.day,
                            },
                          ]
                        : [
                            {
                              label: `Day 1`,
                              value: "1",
                            },
                          ]
                    }
                    onChangeCallback={onDayChangeCallback}
                  />
                </div>
              </div>
              {language === LANGUAGES.ENGLISH && (
                <>
                  {(formik.values.courseType ===
                    COURSE_TYPES.YOGA_PRACTICE.value ||
                    formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value) && (
                    <div className="row">
                      <div className="col-md-12">
                        <VideoTable
                          onBtnClick={() => {
                            daysFormik.values[LANGUAGES.ENGLISH]?.videos
                              ?.length <= 3 && setVideoModal(true);
                            setSelectedVideo(null);
                          }}
                          rows={[
                            ...daysFormik.values[LANGUAGES.ENGLISH].videos,
                          ]}
                          onDeleteVideo={onDeleteVideo}
                          onEditVideo={onEditVideo}
                        />
                      </div>
                    </div>
                  )}
                  {(formik.values.courseType ===
                    COURSE_TYPES.YOGA_PRACTICE.value ||
                    formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value) && (
                    <div className="row">
                      <div className="col-md-12">
                        <AsanaTable
                          onBtnClick={() => {
                            setSelectedAsanaPose(null);
                            setAsanaModal(true);
                          }}
                          rows={[
                            ...daysFormik.values[LANGUAGES.ENGLISH].asanas,
                          ]}
                          onDeleteAsana={onDeleteAsana}
                          onEditClickAsana={onEditClickAsana}
                        />
                      </div>
                    </div>
                  )}
                  {(formik.values.courseType ===
                    COURSE_TYPES.YOGA_PRACTICE.value ||
                    formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value) && (
                    <div className="row">
                      <div className="col-md-12">
                        <PoseTable
                          onBtnClick={() => {
                            setSelectedAsanaPose(null);
                            setPoseModal(true);
                          }}
                          rows={[...daysFormik.values[LANGUAGES.ENGLISH].poses]}
                          onDeletePose={onDeletePose}
                          onEditClickPose={onEditClickPose}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {language === LANGUAGES.HINDI && (
                <>
                  {(formik.values.courseType ===
                    COURSE_TYPES.YOGA_PRACTICE.value ||
                    formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value) && (
                    <div className="row">
                      <div className="col-md-12">
                        <VideoTable
                          onBtnClick={() => {
                            daysFormik.values[LANGUAGES.HINDI]?.videos
                              ?.length <= 3 && setVideoModal(true);
                            setSelectedVideo(null);
                          }}
                          rows={daysFormik.values[LANGUAGES.HINDI].videos}
                          onDeleteVideo={onDeleteVideo}
                          onEditVideo={onEditVideo}
                        />
                      </div>
                    </div>
                  )}
                  {(formik.values.courseType ===
                    COURSE_TYPES.YOGA_PRACTICE.value ||
                    formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value) && (
                    <div className="row">
                      <div className="col-md-12">
                        <AsanaTable
                          onBtnClick={() => {
                            setSelectedAsanaPose(null);
                            setAsanaModal(true);
                          }}
                          rows={[...daysFormik.values[LANGUAGES.HINDI].asanas]}
                          onDeleteAsana={onDeleteAsana}
                          onEditClickAsana={onEditClickAsana}
                        />
                      </div>
                    </div>
                  )}
                  {(formik.values.courseType ===
                    COURSE_TYPES.YOGA_PRACTICE.value ||
                    formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value) && (
                    <div className="row">
                      <div className="col-md-12">
                        <PoseTable
                          onBtnClick={() => {
                            setSelectedAsanaPose(null);
                            setPoseModal(true);
                          }}
                          rows={[...daysFormik.values[LANGUAGES.HINDI].poses]}
                          onDeletePose={onDeletePose}
                          onEditClickPose={onEditClickPose}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {daysOptions.length === 0 && (
            <div className="row ">
              <div className="text-center text-danger fw-bold">
                Course duration is required. Please add course duration
              </div>
              {/* <div className="col t"></div> */}
            </div>
          )}
          <div className="form-group text-right">
            <Link
              to={PATHS.COURSE_MANAGEMENT}
              className="btn btn-light waves-effect waves-light mr-3"
            >
              Cancel
            </Link>
            <ButtonLoading
              className="btn-primary waves-effect waves-light w-15"
              type="submit"
              title={"Submit"}
              isLoading={loadingButton}
            />
          </div>
          {/* </div> */}
        </form>
      </CommonCard>
      <VideoModal
        show={videoModal}
        setShow={setVideoModal}
        formik={daysFormik}
        language={language}
        name={`${language}.videos`}
        selectedVideo={selectedVideo}
        setSelectedVideo={setSelectedVideo}
      />
      <AsanaModal
        show={asanaModal}
        setShow={setAsanaModal}
        formik={daysFormik}
        name={`${language}.asanas`}
        asanaList={asanaData.asanaList}
        asanaSearchPromiseOptions={asanaSearchPromiseOptions}
        selectedAsanaPose={selectedAsanaPose}
        setSelectedAsanaPose={setSelectedAsanaPose}
      />
      <PoseModal
        show={poseModal}
        setShow={setPoseModal}
        formik={daysFormik}
        name={`${language}.poses`}
        poseList={poseData.poseList}
        poseSearchPromiseOptions={poseSearchPromiseOptions}
        selectedAsanaPose={selectedAsanaPose}
        setSelectedAsanaPose={setSelectedAsanaPose}
      />

      {/** Delete course confirmation modal */}
      <Modal show={showDelete} onHide={onDeleteToggle}>
        <Modal.Header closeButton>
          <Modal.Title>Delete day</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure to delete day?</h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light waves-effect"
            onClick={onDeleteToggle}
          >
            Keep
          </button>
          <ButtonLoading
            className={`btn-danger waves-effect waves-light w-25`}
            onClick={onDeleteDay}
            title="Yes, Delete"
            isLoading={loadingButton}
          />
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
};
