import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllAsanaAsync } from "../app/modules/asana/redux/api";
import { CourseManagementActions } from "../app/modules/course/redux/actions";
import {
  deleteCourseAsync,
  getAllCourseAsync,
} from "../app/modules/course/redux/api";
import { getAllPoseAsync } from "../app/modules/pose/redux/api";
import { PATHS } from "../components/aside-menu/links";

export const useCourseHook = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    isLoading,
    courseList,
    refreshCourseList,
    selectedCourse,
    limit,
    offset,
    totalCount,
    redirect,
    apiData,
  } = useSelector((state) => state.courseManagement, shallowEqual);

  const { asanaList } = useSelector(
    (state) => state.asanaManagement,
    shallowEqual
  );

  const { poseList } = useSelector(
    (state) => state.poseManagement,
    shallowEqual
  );

  const currentStartCount = offset * limit - (limit - 1);
  const currentEndCount =
    limit * offset < totalCount ? limit * offset : totalCount;

  useEffect(() => {
    if (refreshCourseList && location.pathname === PATHS.COURSE_MANAGEMENT) {
      dispatch(getAllCourseAsync());
    }
  }, [refreshCourseList]);

  // useEffect(() => {
  //   if (!asanaList?.length) dispatch(getAllAsanaAsync());
  // }, [asanaList]);

  // useEffect(() => {
  //   if (!poseList?.length) {
  //     dispatch(getAllPoseAsync());
  //   }
  // }, [poseList]);

  useEffect(() => {
    setSelectedCourse(null);
  }, []);

  const setSelectedCourse = (course) => {
    dispatch(
      CourseManagementActions.setSelectedCourse({ selectedCourse: course })
    );
  };

  const deleteCourse = () => {
    return dispatch(deleteCourseAsync(selectedCourse.id));
  };

  const onLimitChange = (value) => {
    dispatch(
      CourseManagementActions.setCoursePagination({
        offset: 1,
        limit: parseInt(value),
      })
    );
  };

  const onPageChange = (page) => {
    dispatch(
      CourseManagementActions.setCoursePagination({ offset: page, limit })
    );
  };

  const onSearch = async (text) => {
    if (text.length > 1) {
      dispatch(CourseManagementActions.setCourseSearch({ searchText: text }));
    }
    if (!text) {
      dispatch(CourseManagementActions.setCourseSearch({ searchText: "" }));
    }
  };
  const onFilter = async (name, value) => {
    dispatch(CourseManagementActions.setCourseFilter({ name, value }));
  };



  return {
    isLoading,
    courseList,
    // asanaList,
    // poseList,
    refreshCourseList,
    selectedCourse,
    limit,
    offset,
    totalCount,
    currentEndCount,
    currentStartCount,
    redirect,
    apiData,
    setSelectedCourse,
    onLimitChange,
    onPageChange,
    deleteCourse,
    onSearch,
    onFilter
  };
};
