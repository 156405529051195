import React, { useEffect, useMemo, useCallback } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  createBannerPayload,
  CreateBannerSchema,
  formatSelectedBannerData,
  getBannerFiles,
  initialValues,
  options,
} from "../config";
import { CommonCard } from "../../../../components/card/common-card";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { TextInput } from "../../../../components/inputs/text-input";
import { PATHS } from "../../../../components/aside-menu/links";
import { ButtonLoading } from "../../../../components/button";
import { Dropzone } from "../../../../components/dropzone/dropzone";
import { CustomSelect } from "../../../../components/inputs/select";
import { BANNER_TYPE_OPTIONS, LANGUAGES } from "../../../../utils/constants";
import { useContentLanguage } from "../../../../hooks/content-language-hook";
import { LanguageButton } from "../../../../components/button/language-buttons";
import {
  createBannerAsync,
  getBannerDetailsAsync,
  updateBannerAsync,
} from "../redux/api";
import { useBannerHook } from "../../../../hooks/banner-data-hook";
import { searchCourseAsync } from "../../course/redux/api";
import { CustomAsyncSelect } from "../../../../components/inputs/async-select";

export const CreateBanner = () => {
  const params = useParams();
  const isEditMode = params.bannerId;

  const { isLoading, selectedBanner, redirect, apiData } = useSelector(
    (state) => state.bannerManagement,
    shallowEqual
  );
  const { courseList } = useBannerHook();

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  const { language, otherLanguage, toggleLanguage } = useContentLanguage();

  useEffect(() => {
    if (isEditMode) {
      dispatch(getBannerDetailsAsync(isEditMode));
    }
  }, [isEditMode]);

  const dispatch = useDispatch();

  let defaultData =
    isEditMode && selectedBanner
      ? formatSelectedBannerData(JSON.parse(JSON.stringify(selectedBanner)))
      : initialValues;

  const formik = useFormik({
    initialValues: { ...defaultData },
    validationSchema: CreateBannerSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let payload = createBannerPayload(values, apiData);
      let filesData = getBannerFiles(values);
      if (selectedBanner) {
        let data = {
          ...payload,
          id: isEditMode,
        };
        return dispatch(updateBannerAsync(data, filesData));
      }
      dispatch(createBannerAsync(payload, filesData));
    },
  });

  const onLanguageChange = (lang) => {
    toggleLanguage(lang);
  };

  const selectedCourseValue = useMemo(() => {
    if (formik.values?.course?.title) {
      return {
        label: formik.values?.course?.title,
        value: formik.values?.course?.id,
      };
    }
    if (courseList?.length) {
      let course = courseList?.find(
        (course) => course.id === formik.values.course
      );
      if (course) {
        return {
          label: course?.details?.en?.title,
          value: course?.id,
        };
      }
    }
    return {};
  }, [courseList, formik.values.course]);

  const courseOptions = useMemo(
    () =>
      courseList?.length
        ? courseList?.map((course) => ({
            label: course.details?.en?.title,
            value: course.id,
            details: course.details,
          }))
        : [],
    [courseList]
  );

  const courseSearchPromiseOptions = useCallback(async (value) => {
    let data = await searchCourseAsync(value);
    if (data?.length) {
      return data.map((course) => ({
        label: course.details.en.title,
        value: course.id,
        details: course.details,
      }));
    }
    return [];
  }, []);

  if (redirect) {
    return <Redirect to={PATHS.BANNER_MANAGEMENT} />;
  }

  return (
    <CommonLayout>
      <CommonCard
        className="card w-75 mx-auto"
        header={
          <>
            <Link to={PATHS.BANNER_MANAGEMENT}>
              <span
                className="mdi mdi-arrow-left mr-2"
                style={{ fontSize: "x-large" }}
              ></span>
            </Link>{" "}
            <h4 className="header-title m-0">Create Banner</h4>
          </>
        }
        headerClassName="card-header card-title"
      >
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <CustomSelect
                  label="Section"
                  name="section"
                  formik={formik}
                  options={BANNER_TYPE_OPTIONS}
                  value={[
                    BANNER_TYPE_OPTIONS.find(
                      (option) => option.value === formik.values.section
                    ),
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <CustomSelect
                  label="Type"
                  name="type"
                  formik={formik}
                  options={options}
                  value={[
                    options.find(
                      (option) => option.value === formik.values.type
                    ),
                  ]}
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  name={`order`}
                  label="Order"
                  placeholder="Enter order"
                  id={`order`}
                  formik={formik}
                />
              </div>
            </div>
            <div className="row">
              {formik.values.type === "link" && (
                <div className="col-md-6">
                  <TextInput
                    name={`link`}
                    label="Link"
                    placeholder="Enter link"
                    id={`link`}
                    formik={formik}
                  />
                </div>
              )}
              {formik.values.type === "course" && (
                <div className="col-md-6">
                  {/* <CustomSelect
                    label="Select Course"
                    name="course"
                    formik={formik}
                    options={courseOptions}
                    value={[selectedCourseValue]}
                  /> */}
                  <CustomAsyncSelect
                    label="Select Course"
                    name={`course`}
                    formik={formik}
                    options={courseOptions}
                    isSearchable
                    propmiseOptions={courseSearchPromiseOptions}
                    defaultValue={
                      selectedCourseValue
                        ? [
                            {
                              label: selectedCourseValue?.label,
                              value: selectedCourseValue.value,
                            },
                          ]
                        : []
                    }
                  />
                </div>
              )}
            </div>
            <hr className="my-3" />
            <div className="row">
              <div className="col-md-11">
                <LanguageButton
                  language={language}
                  onLanguageChange={onLanguageChange}
                  errorBorder={
                    formik.touched[otherLanguage] &&
                    formik.errors[otherLanguage]
                  }
                />
              </div>
            </div>
            <div className="chat-day-title my-2">
              <span className="title text-capitalize">{language}</span>
            </div>
            {language === LANGUAGES.ENGLISH && (
              <div className="row">
                <div className="col-md-12">
                  <Dropzone
                    label="Thumbnail"
                    text="Drag 'n' drop image here, or click to select image"
                    info="(Only 1 image file will be accepted)"
                    allow="image/png, image/jpeg, image/jpg"
                    type="image"
                    hideDropZoneOnPreview
                    preview
                    name={`${LANGUAGES.ENGLISH}.thumbnail`}
                    formik={formik}
                    language={language}
                  />
                </div>
              </div>
            )}
            {language === LANGUAGES.HINDI && (
              <div className="row">
                <div className="col-md-12">
                  <Dropzone
                    label="Thumbnail"
                    text="Drag 'n' drop image here, or click to select image"
                    info="(Only 1 image file will be accepted)"
                    allow="image/png, image/jpeg, image/jpg"
                    type="image"
                    hideDropZoneOnPreview
                    preview
                    name={`${LANGUAGES.HINDI}.thumbnail`}
                    formik={formik}
                    language={language}
                  />
                </div>
              </div>
            )}
            <div className="form-group text-right">
              <Link
                to={PATHS.BANNER_MANAGEMENT}
                className="btn btn-light waves-effect waves-light mr-3"
              >
                Cancel
              </Link>
              <ButtonLoading
                className="btn-primary waves-effect waves-light w-15"
                type="submit"
                title={"Submit"}
                isLoading={loadingButton}
              />
            </div>
          </form>
        )}
      </CommonCard>
    </CommonLayout>
  );
};
