import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyD9nbkwSsbwOMIs6lIabmrJ4lEv6hKT3qQ",
  authDomain: "asanly-fb729.firebaseapp.com",
  projectId: "asanly-fb729",
  storageBucket: "asanly-fb729.appspot.com",
  messagingSenderId: "306799072876",
  appId: "1:306799072876:web:a3c6292318c41d62deea49",
  measurementId: "G-QLX5XEY5S7",
};

// Initialize Firebase
// Create app first time / Return already intialized app
export const initFirebase = () =>
  !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();
