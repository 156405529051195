import TimePicker from "rc-time-picker";

export const TimeInput = ({
  label,
  name,
  id,
  placeholder,
  formik,
  type = "text",
  disabled = false,
  touched = false,
  errors = false,
  ...rest
}) => {
  //   const touched = get(formik.touched, name);
  //   const errors = get(formik.errors, name);
  const getInputClasses = () => {
    // if (touched && errors) {
    //   return "is-invalid";
    // }
    // if (touched && !errors) {
    //   return "is-valid";
    // }
    return "";
  };
  return (
    <div className="form-group">
      <label htmlFor={id} className="d-block">{label}</label>
      <TimePicker
        name={name}
        id={id}
        disabled={disabled}
        use12Hours={false}
        allowEmpty={false}
        {...rest}
      />
      {touched && errors ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{errors}</div>
        </div>
      ) : null}
    </div>
  );
};
