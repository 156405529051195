import React from "react";
import { IconButton } from "../../../../components/button/common-button";
import { CommonTable } from "../../../../components/table/common-table";

const columns = [
  {
    id: "asana",
    label: "Asana Id",
    className: "text-center align-middle",
  },
  {
    id: "startTime",
    label: "Start Time",
    className: "text-center align-middle",
  },
  {
    id: "endTime",
    label: "End Time",
    className: "text-center align-middle",
  },
];

export const AsanaTable = ({
  onBtnClick,
  rows = [],
  onDeleteAsana,
  onEditClickAsana,
}) => {
  const onDelete = (index) => {
    onDeleteAsana(index);
  };
  return (
    <div className="card-group card border border-light p-2">
      <div className="form-group w-100">
        <label>Asana</label>
        <IconButton
          color={"primary"}
          type="button"
          icon={<i className="fas fa-plus "></i>}
          className="btn-icon py-0 px-1 ml-2"
          onClick={onBtnClick}
        />
        <table id="datatable" className="table dt-responsive nowrap">
          <thead>
            <tr>
              <th className="text-center align-middle">Name(En)</th>
              <th className="text-center align-middle">Name(Hi)</th>
              <th className="text-center align-middle">Start Time</th>
              <th className="text-center align-middle">End Time</th>
              <th className="text-center align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            {rows?.length > 0 ? (
              rows.map((item, index) => {
                return (
                  <tr key={index}>
                    <td
                      className="text-center align-middle text-clip-ellipsis"
                      title={item?.details?.en?.name || ""}
                    >
                      {item?.details?.en?.name || ""}
                    </td>
                    <td
                      className="text-center align-middle text-clip-ellipsis"
                      title={item?.details?.hi?.name || ""}
                    >
                      {item?.details?.hi?.name || ""}
                    </td>
                    <td className="text-center align-middle">
                      {item?.startTime}
                    </td>
                    <td className="text-center align-middle">
                      {item?.endTime}
                    </td>
                    <td className="text-center align-middle">
                      <IconButton
                        color="warning"
                        icon={<i className="fe-edit-1"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onEditClickAsana({...item, index})}
                      />
                      <IconButton
                        color="danger"
                        icon={<i className="fe-trash-2"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onDelete(index)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
