import React from "react";

export const CommonCard = ({ header, children, headerClassName, className = "card-box mt-2" }) => {
  return (
    <div className={"shadow p-3 mb-5 bg-white rounded " + className}>
      {header && (
        <div
          className={`d-flex  align-items-center  ${
            headerClassName ? headerClassName : ""
          }`}
        >
          {header}
        </div>
      )}
      <div className="card-body">{children}</div>
    </div>
  );
};
