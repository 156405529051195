import { ContentManagementMap } from "./action";

const initialState = {
  privacy_policy: "",
  terms_and_conditions: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ContentManagementMap.GET_CONTENT_DATA: {
      return {
        ...state,
        [action.payload.type]: action.payload.data,
      };
    }
    default:
      return { ...state };
  }
};
