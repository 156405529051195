import React from "react";

export const IconButton = ({
  color,
  type,
  title = null,
  icon = null,
  className = "",
  onClick,
  disabled,
}) => {
  return (
    <button
      className={`btn btn-${
        color || "primary"
      } waves-effect waves-light  ${className}`}
      type={type || "button"}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {title && <span>{title}</span>}
    </button>
  );
};
