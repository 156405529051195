import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../redux/authApi";
import { NavLink } from "react-router-dom";
import { ButtonLoading } from "../../../../components/button/loading-button";
import { AuthActions } from "../redux";
import mockUser from "../_mock/user.json";

const Login = () => {
  const initialValues = {
    email: "",
    password: "",
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("Enter valid email")
      .required("Email Id is required"),
    password: Yup.string()
      .trim()
      .matches(/^(?!\s*$).+/, "Please enter valid password")
      .min(5, "Please enter valid password")
      .max(50, "Please enter valid password")
      .required("Password is required"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const dispatch = useDispatch();

  const loadingButton = useSelector((state) => state.ui.loadingButton);

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(loginAsync({ ...values }));
      // dispatch(AuthActions.setAuthToken("TEST_TOKEN"));
      // dispatch(AuthActions.login({ user: mockUser }));
      // window.location.href = "/dashboard";
    },
  });

  return (
    <>
      <div className="card">
        <div className="card-body p-4">
          <div className="text-center mb-4">
            <h4 className="text-uppercase mt-0">Sign In</h4>
          </div>

          <form action="#" onSubmit={formik.handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="emailaddress">Email address</label>
              <input
                className={`form-control ${getInputClasses("email")}`}
                type="email"
                id="emailaddress"
                placeholder="Enter your email"
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password">Password</label>
              <input
                className={`form-control ${getInputClasses("password")}`}
                type="password"
                id="password"
                placeholder="Enter your password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group mb-3">
              {/* <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="checkbox-signin"
                />
                <label
                  className="custom-control-label"
                  htmlFor="checkbox-signin"
                >
                  Remember me
                </label>
              </div> */}
            </div>
            <div className="form-group mb-0 text-center">
              <ButtonLoading
                type="submit"
                title="LOG IN"
                className="btn-primary btn-block"
                isLoading={loadingButton}
              />
            </div>
          </form>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <p>
                {" "}
                <NavLink
                  to="/auth/forgot-password"
                  style={{ color: "#000" }}
                  className="ml-1"
                >
                  <i className="fa fa-lock mr-1"></i>Forgot your password?
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
