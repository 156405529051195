import { UserManagementMap } from './action';

const initialState = {
    isLoading: false,
    userList: {},
    refreshUserList: true,
    selectedUser: {},
    reDirect: false,
    limit: 5,
    offset: 1,
    totalRecords: 0,
    isDeleted: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UserManagementMap.GET_ALL_USER: {
            return {
                ...state,
                isLoading: false,
                userList: action.payload.user,
                totalRecords: action.payload.totalRecords || 0,
                refreshUserList: false,
            };
        }
        case UserManagementMap.CREATE_USER: {
            return {
                ...state,
                isLoading: false,
                refreshUserList: true,
                reDirect:true,
            };
        }
        case UserManagementMap.USER_LIST_LOADING: {
            return {
                ...state,
                isLoading: action.payload,
            };
        }
        case UserManagementMap.UPDATE_USER: {
            return {
                ...state,
                isLoading: false,
                refreshUserList: true,
                selectedUser: {
                    ...state.selectedUser,
                    ...action.payload,
                },
                reDirect: true,
            };
        }
        case UserManagementMap.DELETE_USER: {
            return {
                ...state,
                refreshUserList: true,
                selectedUser: null,
            };
        }
        case UserManagementMap.SET_SELECTED_USER: {
            return {
                ...state,
                selectedUser: action.payload,
                reDirect: false,
            };
        }
        case UserManagementMap.USER_PAGE_CHANGE: {
            return {
                ...state,
                offset: action.payload.offset || state.offset,
                limit: action.payload.limit || state.limit,
                refreshUserList: true,             
            };
        }
        default: return { ...state };
    }
};
