import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CommonCard } from "../../../../components/card/common-card";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { TextInput } from "../../../../components/inputs/text-input";
import { PATHS } from "../../../../components/aside-menu/links";
import { ButtonLoading } from "../../../../components/button";

const options = [
  { label: "Banner", value: "banner" },
  { label: "Yoga", value: "yoga" },
  { label: "Aware", value: "aware" },
];

export const CreateCategory = () => {
  const initialValues = {
    title: "",
    displayOrder: "",
    type: ""
  };

  const CreateCategorySchema = Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    displayOrder: Yup.string().required("Display order is required"),
    type: Yup.string().trim().required("Type is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CreateCategorySchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <CommonLayout>
      <CommonCard
        className="card w-75 mx-auto"
        header={
          <>
            <Link to={PATHS.CATEGORY_MANAGEMENT}>
              <span
                className="mdi mdi-arrow-left mr-2"
                style={{ fontSize: "x-large" }}
              ></span>
            </Link>{" "}
            <h4 className="header-title m-0">{"Create Category"}</h4>
          </>
        }
        headerClassName="card-header card-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
            </div>
            <div className="col-md-6">
              <TextInput
                name="title"
                label="Title"
                placeholder="Enter title"
                id="title"
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                name="displayOrder"
                label="Display Order"
                placeholder="Enter display order: 1"
                id="displayOrder"
                formik={formik}
              />
            </div>
          </div>
          <div className="form-group text-right">
            <Link
              to={PATHS.CATEGORY_MANAGEMENT}
              className="btn btn-light waves-effect waves-light mr-3"
            >
              Cancel
            </Link>
            <ButtonLoading
              className="btn-primary waves-effect waves-light w-15"
              type="submit"
              title={"Submit"}
              isLoading={false}
            />
          </div>
        </form>
      </CommonCard>
    </CommonLayout>
  );
};
