import { useMemo, useState } from "react";
import { CONTENT_LANGUAGES } from "../utils/constants";

export const useContentLanguage = () => {
  const [language, setLanguage] = useState(CONTENT_LANGUAGES[0]);

  const toggleLanguage = (value) => {
    setLanguage(value);
  }
  const otherLanguage = useMemo(() => CONTENT_LANGUAGES.filter(lang => lang !== language), [language])
  return {
    language,
    otherLanguage,
    toggleLanguage,
  };
};
