import moment from "moment";
import { apiService } from "../api/config";

export const get_diff_object = (obj1, obj2) => {
  if (typeof obj1 === "object" && typeof obj2 === "object") {
    const diffObj = {};
    for (let key in obj1) {
      if (obj2.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        diffObj[key] = obj2[key];
      }
    }
    return diffObj;
  }
};

/******************* 
@purpose : Ganarate File Base64 Image
@Parameter : {file}
******************/
export const get_base_64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const setIndex = (obj, is, value) => {
  if (typeof is == "string") return setIndex(obj, is.split("."), value);
  else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length == 0) return obj;
  else return setIndex(obj[is[0]], is.slice(1), value);
};

export const generateOriginalUrl = (resolvedUrl) => {
  return decodeURI(
    resolvedUrl.split("?")[0].replace(apiService.assetBaseUrl + "/", "")
  );
};

export const hhmmssToSeconds = (time, splitBy = ":") => {
  let [hr, min, sec] = time.split(splitBy);

  return parseInt(hr) * 60 * 60 + parseInt(min) * 60 + parseInt(sec);
};

export const secTohhmmss = (seconds = 0) =>
  new Date(seconds * 1000).toISOString().substr(11, 8);

export const convertHHMMSSToMoment = (hhmmss, splitBy = ":") => {
  let [hh, mm,ss] = hhmmss.split(splitBy)
  let m = moment().utcOffset(0);
  m.set({hour: hh, minute: mm, second: ss})
  return m;
}

export const getHHMMSSMoment = (moment) => {
  return `${moment.hour()}:${moment.minute()}:${moment.second()}`;
}

export const getFileName = (name) => encodeURIComponent(name);