import { UserManagementActions } from "./action";
import {
  get,
  post,
  update,
  remove,
  getUploadSignedUrl,
  uploadSigned,
} from "../../../../api";
import { toast } from "react-toastify";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config";
import axios from "axios";

export const getAllUserAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(UserManagementActions.setUserListLoading(true));
      const { limit, offset, isDeleted } = getState().userManagement;
      let url = `${apiService.userUrl}/list?limit=${limit}&offset=${offset}`;
      if (isDeleted) {
        url += `&isDeleted=${isDeleted}`;
      }
      const { data } = await get(url, null);
      if (data && !data.isError) {
        dispatch(
          UserManagementActions.getAllUser({
            user: (data.data && data.data) || [],
            totalRecords: (data.data && data.data.total) || 0,
          })
        );
        return dispatch(UserManagementActions.setUserListLoading(false));
      }
      dispatch(UserManagementActions.setUserListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(UserManagementActions.setUserListLoading(false));
      return toast.error(error.message);
    }
  };
};

const addProfileImage = async (fileDetails, file, user) => {
  let filesUrls;
  if (file) {
    let { data } = await getUploadSignedUrl(fileDetails);
    filesUrls = data?.data;
    await uploadSigned(filesUrls?.[0]?.signedUrl, file);
    // profileImageUrl = profile;
  }
  let finalUserDetails = user;
  if (filesUrls?.length) {
    finalUserDetails = {
      ...finalUserDetails,
      profilePicture: filesUrls[0]?.uploadedFilePath?.path,
    };
  }
  return finalUserDetails;
};

export const createUserAsync = (user, { fileDetails, file }) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let finalUserDetails = await addProfileImage(fileDetails, file, user);
      const { data } = await post(`${apiService.userUrl}`, finalUserDetails);
      if (!data.isError) {
        dispatch(UserManagementActions.createUser({ user: data.data }));
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(data.message);
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(data.message);
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const updateUserAsync = (user, { fileDetails, file }) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let finalUserDetails = await addProfileImage(fileDetails, file, user);
      const { data } = await update(`${apiService.userUrl}`, finalUserDetails);
      if (!data.isError) {
        dispatch(UserManagementActions.updateUser({ user: data.data }));
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(data.message);
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error("Error while updating.Please try again later.");
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const disableUserAsync = (userId, updateList) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      const { data } = await update(`${apiService.userUrl}/toggle-active`, {
        userId,
      });
      if (!data.isError) {
        if (updateList) {
          dispatch(UserManagementActions.deleteUser({ user: data.data }));
        }
        dispatch(UIActions.setUIKey("loadingButton", false));
        toast.success(data.message);
        return data;
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error("Error while deteting user.Please try again later.");
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const deleteUserAsync = (userId, updateList) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      const { data } = await remove(`${apiService.userUrl}`, { userId });
      if (!data.isError) {
        if (updateList) {
          dispatch(UserManagementActions.deleteUser({ user: data.data }));
        }
        dispatch(UIActions.setUIKey("loadingButton", false));
        toast.success(data.message);
        return data;
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error("Error while deteting user.Please try again later.");
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};
