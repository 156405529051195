export const CourseManagementMap = {
  GET_ALL_COURSE: "GET_ALL_COURSE",
  CREATE_COURSE: "CREATE_COURSE",
  UPDATE_COURSE: "UPDATE_COURSE",
  DELETE_COURSE: "DELETE_COURSE",
  SET_SELECTED_COURSE: "SET_SELECTED_COURSE",
  COURSE_PAGE_CHANGE: "COURSE_PAGE_CHANGE",
  COURSE_LIST_LOADING: "COURSE_LIST_LOADING",
  COURSE_SEARCH_CHANGE: "COURSE_SEARCH_CHANGE",
  COURSE_FILTER_CHANGE: "COURSE_FILTER_CHANGE",
  COURSE_UPDATE_API_DATA: "COURSE_UPDATE_API_DATA",
};

export const CourseManagementActions = {
  getAllCourse: (data) => ({
    type: CourseManagementMap.GET_ALL_COURSE,
    payload: data,
  }),

  createCourse: (data) => ({
    type: CourseManagementMap.CREATE_COURSE,
    payload: data,
  }),

  updateCourse: (data) => ({
    type: CourseManagementMap.UPDATE_COURSE,
    payload: data,
  }),

  deleteCourse: (data) => ({
    type: CourseManagementMap.DELETE_COURSE,
    payload: data,
  }),

  setSelectedCourse: (data) => ({
    type: CourseManagementMap.SET_SELECTED_COURSE,
    payload: data,
  }),

  setCoursePagination: (data) => ({
    type: CourseManagementMap.COURSE_PAGE_CHANGE,
    payload: data,
  }),

  setCourseListLoading: (data) => ({
    type: CourseManagementMap.COURSE_LIST_LOADING,
    payload: data,
  }),

  setCourseSearch: (data) => ({
    type: CourseManagementMap.COURSE_SEARCH_CHANGE,
    payload: data,
  }),
  setCourseFilter: (data) => ({
    type: CourseManagementMap.COURSE_FILTER_CHANGE,
    payload: data,
  }),
  updateApiData: (data) => ({
    type: CourseManagementMap.COURSE_UPDATE_API_DATA,
    payload: data,
  }),
};
