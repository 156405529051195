import React from "react";
import get from "lodash/get";

export const Textarea = ({
  label,
  name,
  id,
  placeholder,
  formik,
}) => {
  const touched = get(formik.touched, name);
  const errors = get(formik.errors, name);
  const getInputClasses = () => {
    if (touched && errors) {
      return "is-invalid";
    }
    if (touched && !errors) {
      return "is-valid";
    }
    return "";
  };
  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <textarea
        className={`form-control ${getInputClasses()}`}
        name={name}
        parsley-trigger="change"
        placeholder={placeholder}
        id={id}
        {...formik.getFieldProps(name)}
        rows={5}
      />
      {touched && errors ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{errors}</div>
        </div>
      ) : null}
    </div>
  );
};
