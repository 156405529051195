export const COURSE_TYPES = {
  YOGA_PRACTICE: { name: "Yoga Practice", value: "yoga_practice" },
  YOGA_STUDIO: { name: "Yoga Studio", value: "yoga_studio" },
  AWARE: { name: "Aware", value: "aware" },
};

export const COURSE_TYPE_OPTIONS = [
  {
    value: COURSE_TYPES.YOGA_PRACTICE.value,
    label: COURSE_TYPES.YOGA_PRACTICE.name,
  },
  {
    value: COURSE_TYPES.YOGA_STUDIO.value,
    label: COURSE_TYPES.YOGA_STUDIO.name,
  },
  { value: COURSE_TYPES.AWARE.value, label: COURSE_TYPES.AWARE.name },
];

export const MULTI_COURSE_TYPE_OPTIONS = [
  {
    value: COURSE_TYPES.YOGA_PRACTICE.value,
    label: COURSE_TYPES.YOGA_PRACTICE.name,
  },
  {
    value: COURSE_TYPES.YOGA_STUDIO.value,
    label: COURSE_TYPES.YOGA_STUDIO.name,
  },
  // { value: COURSE_TYPES.AWARE.value, label: COURSE_TYPES.AWARE.name },
];

export const BANNER_TYPES = {
  HOME: { name: "Home", value: "home" },
  YOGA: { name: "Yoga", value: "yoga" },
  AWARE: { name: "Aware", value: "aware" },
};

export const BANNER_TYPE_OPTIONS = [
  {
    value: BANNER_TYPES.HOME.value,
    label: BANNER_TYPES.HOME.name,
  },
  {
    value: BANNER_TYPES.YOGA.value,
    label: BANNER_TYPES.YOGA.name,
  },
  { value: BANNER_TYPES.AWARE.value, label: BANNER_TYPES.AWARE.name },
];

export const LANGUAGES = {
    ENGLISH: "english",
    HINDI: "hindi"
}

export const CONTENT_LANGUAGES = [LANGUAGES.ENGLISH, LANGUAGES.HINDI];
