import React, { useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createUserAsync,
  deleteUserAsync,
  updateUserAsync,
} from "../redux/api";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, Redirect, useParams } from "react-router-dom";
import { ButtonLoading } from "../../../../components/button";
import { get_diff_object } from "../../../../utils/functions";
import { TextInput } from "../../../../components/inputs/text-input";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { Form, Modal } from "react-bootstrap";
import { UserManagementActions } from "../redux";

const CreateUser = () => {
  const [file, setFile] = useState({ file: null, url: "" });
  const [showDelete, setShowDelete] = useState(false);

  const { selectedUser, reDirect } = useSelector(
    (state) => state.userManagement,
    shallowEqual
  );

  const params = useParams();

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const initialValues = useMemo(
    () => ({
      name: selectedUser.name || "",
      // userName: selectedUser.userName || "",
      email: selectedUser.emailId || "",
      password: selectedUser.password || "",
      confirmPassword: selectedUser.confirmPassword || "",
      // role: "user",
      profileImageUrl:
        selectedUser.profileImageUrl ||
        "/assets/images/account-circle-outline.png",
      // isPasswordRequired: selectedUser.emailId === undefined ? true : false,
    }),
    [selectedUser]
  );

  const CreateUserSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(2, "Please enter valid name")
      .max(36, "Please enter valid name")
      .required("Name is required"),
    // userName: Yup.string()
    //   .trim()
    //   .min(2, "Please enter valid name")
    //   .max(36, "Please enter valid name")
    //   .required("User name is required"),
    email: Yup.string()
      .trim()
      .email("Enter valid email")
      .required("Email Id is required"),
    // password: Yup.string()
    //   .trim()
    //   .min(5, "Please enter valid password")
    //   .max(50, "Please enter valid password")
    //   .required("Password is required"),
    // confirmPassword: Yup.string().oneOf(
    //   [Yup.ref("password"), null],
    //   "Passwords must match"
    // ),
  });

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CreateUserSchema,
    onSubmit: (values) => {
      if (!selectedUser.userId) {
        const { isPasswordRequired, profileImageUrl, ...allValues } = values;
        let fileObj = file.file;
        let fileDetails = [
          {
            name: fileObj.name.split(" ").join("-"),
            contentType: fileObj.type,
            type: "profile",
            refId: "profileImageUrl",
            language: "en",
          },
        ];
        return dispatch(
          createUserAsync(allValues, {
            fileDetails: { fileDetails },
            file: fileObj,
          })
        );
      }
      if (selectedUser.userId) {
        let diffData = get_diff_object(selectedUser, values);
        if (values.password) {
          diffData.password = values.password;
        }
        let fileObj = null,
          fileDetails = null;
        if (Object.keys(diffData).length || file?.file) {
          let data = {
            ...diffData,
            userId: selectedUser.userId,
          };
          if (file.file) {
            fileObj = file.file;
            fileDetails = [
              {
                name: fileObj.name.split(" ").join("-"),
                contentType: fileObj.type,
                type: "profile",
                refId: "profileImageUrl",
                language: "en",
              },
            ];
          }
          dispatch(
            updateUserAsync(data, {
              fileDetails: { fileDetails },
              file: fileObj,
            })
          );
        }
      }
    },
  });

  const uploadImage = (e) => {
    if (e.target.files.length) {
      setFile({
        url: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
  };

  const onDeleteUser = async () => {
    let resp = await dispatch(deleteUserAsync(selectedUser.userId));
    if (!resp?.isError) {
      dispatch(
        UserManagementActions.setSelectedUser({
          ...selectedUser,
          isActive: !selectedUser.isActive,
        })
      );
    }
    setShowDelete(false);
  };

  if (reDirect) {
    return <Redirect to="/user-management" />;
  }

  if (user.role !== "system_admin") return <Redirect to="/" />;

  return (
    <CommonLayout>
      <div className="card w-75 mx-auto">
        <div className="card-title card-header d-flex align-items-center">
          <Link to="/user-management">
            <span
              className="mdi mdi-arrow-left mr-2"
              style={{ fontSize: "x-large" }}
            ></span>
          </Link>{" "}
          <h4 className="header-title m-0">
            {selectedUser.name ? "Update User" : "Create User"}
          </h4>
        </div>
        <div className="card-body p-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-12 text-center mb-4">
                <div
                  className="pro_img"
                  style={{
                    backgroundImage: `url(${
                      file.url || formik.values.profileImageUrl
                    })`,
                  }}
                >
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={uploadImage}
                  />
                  <div className="edt_img_btn">
                    <span className="mdi mdi-pencil"></span>EDIT
                  </div>
                </div>
                <div className="col-md-12 text-center my-2">
                  {selectedUser.userId && (
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      color={!selectedUser.isActive ? "danger" : "success"}
                      label={
                        <span
                          className={`text-${
                            !selectedUser.isActive ? "danger" : "success"
                          }`}
                        >
                          {!selectedUser.isActive ? "Inactive" : "Active"}
                        </span>
                      }
                      checked={selectedUser.isActive}
                      onChange={() => setShowDelete(true)}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <TextInput
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  id="name"
                  formik={formik}
                />
              </div>
              {/* <div className="col-md-6">
                <TextInput
                  name="userName"
                  label="User Name"
                  placeholder="Enter user name"
                  id="userName"
                  formik={formik}
                />
              </div> */}
              <div className="col-md-6">
                <TextInput
                  name="email"
                  label="Email"
                  placeholder="Enter email"
                  id="email"
                  formik={formik}
                  type="email"
                  disabled={!!selectedUser.userId}
                />
              </div>
              {(!selectedUser?.userId ||
                selectedUser?.signInProvider === "password") && (
                <>
                  <div className="col-md-6">
                    <TextInput
                      name="password"
                      label="Password"
                      placeholder="Enter password"
                      id="password"
                      formik={formik}
                      type="password"
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="Enter password"
                      id="confirmPassword"
                      formik={formik}
                      type="password"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="form-group text-right">
              <Link
                to="/user-management"
                className="btn btn-light waves-effect waves-light mr-3"
              >
                Cancel
              </Link>
              <ButtonLoading
                className="btn-primary waves-effect waves-light w-15"
                type="submit"
                title={selectedUser.name ? "Update" : "Submit"}
                isLoading={loadingButton}
              />
            </div>
          </form>
        </div>
      </div>

      {/** Delete user confirmation modal */}

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedUser && !selectedUser.isActive
              ? "Activate User"
              : "Inactivate User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {selectedUser && !selectedUser.isActive
              ? "Are you sure to activate user?"
              : "Are you sure to inactivate user?"}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light waves-effect"
            data-dismiss="modal"
          >
            Keep
          </button>
          <ButtonLoading
            className={`${
              selectedUser && !selectedUser.isActive
                ? "btn-primary"
                : "btn-danger"
            } waves-effect waves-light w-25`}
            onClick={onDeleteUser}
            title={
              selectedUser && !selectedUser.isActive
                ? "Yes, Activate"
                : "Yes, Inactivate"
            }
            isLoading={loadingButton}
          />
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
};

export default CreateUser;
