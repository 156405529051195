export const ContentManagementMap = {
  GET_CONTENT_DATA: "GET_CONTENT_DATA",
  SET_CONTENT_DATA: "SET_CONTENT_DATA",
};

export const ContentManagementActions = {
  getContentData: (data) => ({
    type: ContentManagementMap.GET_CONTENT_DATA,
    payload: data,
  }),

  createContent: (data) => ({
    type: ContentManagementMap.SET_CONTENT_DATA,
    payload: data,
  }),
};
