import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthMap } from './authAction';

const initialState = {
    user: null,
    authToken: null,
    isLoading: false,
};

const persistConfig = {
    storage,
    key: 'v706-demo1-auth',
    whitelist: ['user', 'authToken'],
};

export const reducer = persistReducer(persistConfig, (state = initialState, action) => {
    switch (action.type) {
        case AuthMap.AUTH_ISLOADING: {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case AuthMap.SET_AUTH_TOKEN: {
            return {
                ...state,
                authToken: action.payload
            }
        }
        case AuthMap.LOGIN: {
            return {
                ...state,
                user: action.payload.user,
            }
        }
        case AuthMap.LOGOUT: {
            return {
                ...initialState
            }
        }
        case AuthMap.FORGOT_PASSWORD: {
            return {
                ...state,
            }
        }
        case AuthMap.SET_NEW_PASSWORD: {
            return {
                ...state,
            }
        }
        case AuthMap.VERIFY_AUTH_TOKEN: {
            return {
                ...state,
                authToken: action.payload,
                user: {
                    ...state.user,
                    token: action.payload
                }
            }
        }
     case AuthMap.UPDATE_ADMIN_PROFILE: {
        return {
            ...state,
            user: {
                ...state.user,
                ...action.payload,
            }
        }
    }
        default: return { ...state }
    }
});