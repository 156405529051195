import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import AuthPage from "./modules/auth/pages";
import BasePageRoutes from "./base-page-routes";
import ErrorsPage from "./modules/error-pages/error-page";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken && auth.user,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      {!isAuthorized ? (
        <Redirect to="/auth/login" />
      ) : (
        <Route>
          <BasePageRoutes />
        </Route>
      )}

      <Route path="/error" component={ErrorsPage} />
    </Switch>
  );
}
