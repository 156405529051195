import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setNewPasswordAsync } from '../redux/authApi';
import {NavLink, useParams, useHistory } from "react-router-dom";

const SetPassword = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const PasswordSchema = Yup.object().shape({
        password: Yup.string().trim()
            .matches(/^(?!\s*$).+/, 'Please enter valid password')
            .min(6, "Minimum 6 characters")
            .max(20, "Maximum 20 characters")
            .required("Password is required"),
        confirmPassword: Yup.string().trim()
            .test("passwords-match", "Passwords must match", function (value) {
                return this.parent.password === value;
            }),
    });

    const { user } = useSelector((state) => state.auth, shallowEqual);

    const initialValues = {
        password: user && user.password ? user.password : "",
        confirmPassword: "",
    };

    const redirectBack = () => {
        history.push("/auth/login");
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }
        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid';
        }
        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: PasswordSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(setNewPasswordAsync({
                resetToken: params.resetToken,
                password: values.password
            }, resetForm(), redirectBack));
        },
    });

    const params = useParams();

    // if (!params.resetToken) {
    //     return <Redirect to="/" />;
    //   }

    return (
        <>
            <div className='card'>
                <div className='card-body p-4'>
                    <div className='text-center mb-4'>
                        <h4 className='text-uppercase mt-0 mb-3'>Set New Password</h4>
                        {/* <p className='text-muted mb-0 font-13'>Enter your email address and we'll send you an email with instructions to reset your password.  </p> */}
                    </div>

                    <form action='#' onSubmit={formik.handleSubmit}>
                        <div className='form-group mb-3'>
                            <label htmlFor='password'>Password</label>
                            <input
                                className={`form-control ${getInputClasses('password')}`}
                                type='password'
                                id='password'
                                placeholder='Enter your password'
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.password}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group mb-3'>
                            <label htmlFor='password'>Confirm Password</label>
                            <input
                                className={`form-control ${getInputClasses('confirmPassword')}`}
                                type='password'
                                id='password'
                                placeholder='Enter your password'
                                {...formik.getFieldProps('confirmPassword')}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group mb-0 text-center'>
                            <button className='btn btn-primary btn-block' type='submit'> Set Password </button>
                        </div>
                    </form>

                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <p className='text-muted'>Back to <NavLink to='/auth/login' className='text-dark ml-1'><b>Log in</b></NavLink></p>
                </div>
            </div>
        </>
    )
}

export default SetPassword;
