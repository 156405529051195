import { combineReducers } from "redux";
import * as auth from "../app/modules/auth/redux";
import * as ui from "../app/modules/ui/redux/reducer";
import * as dashboardReducer from "../app/modules/dashboard/redux/reducer";
import * as userManagement from "../app/modules/user-management/redux/reducer";
import * as poseManagement from "../app/modules/pose/redux/reducer";
import * as contentManagement from "../app/modules/cms/redux/reducer";
import * as asanaManagement from "../app/modules/asana/redux/reducer";
import * as courseManagement from "../app/modules/course/redux/reducer";
import * as bannerManagement from "../app/modules/banner/redux/reducer";

export const rootReducer = combineReducers({
  ui: ui.reducer,
  auth: auth.reducer,
  dashboard: dashboardReducer.reducer,
  userManagement: userManagement.reducer,
  poseManagement: poseManagement.reducer,
  contentManagement: contentManagement.reducer,
  asanaManagement: asanaManagement.reducer,
  courseManagement: courseManagement.reducer,
  bannerManagement: bannerManagement.reducer,
});
