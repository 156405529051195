import React from "react";
import AsyncSelect from "react-select/async";

let timer = null;

export const CustomAsyncSelect = ({
  label,
  name,
  formik,
  isLoading = false,
  isSearchable = false,
  isMulti = false,
  isClearable = false,
  defaultValue = [],
  value = [],
  propmiseOptions,
  options = [],
}) => {
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };
  const onChange = (data) => {
    if (isMulti) {
      let values = data.map((item) => item.value);
      return formik.setFieldValue(name, values);
    }
    formik.setFieldValue(name, data);
    // formik.setFieldValue(name, data);
  };

  const createPromiseOptions = (value) => {
    return new Promise((resolve) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if (propmiseOptions) {
          let data = await propmiseOptions(value);
          resolve(data);
        }
        resolve(
          options.filter((option) => {
            return option?.label?.toLowerCase().includes(value);
          })
        );
      }, 500);
    });
  };

  const otherProps = value.length ? { value } : {};
  return (
    <div className="form-group">
      <label style={{ marginTop: "2px" }}>{label}</label>
      <AsyncSelect
        className={getInputClasses(name)}
        classNamePrefix="react-select"
        defaultValue={defaultValue}
        // isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={isMulti}
        isLoading={isLoading}
        isSearchable={isSearchable}
        name={name}
        loadOptions={createPromiseOptions}
        defaultOptions={options}
        onChange={onChange}
        {...otherProps}
      />
      {formik.touched[name] && formik.errors[name] ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      ) : null}
    </div>
  );
};
