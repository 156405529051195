import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AsanaManagementActions } from "../app/modules/asana/redux/action";
import {
  deleteAsanaAsync,
  getAllAsanaAsync,
} from "../app/modules/asana/redux/api";

export const useAsanaHook = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    asanaList,
    refreshAsanaList,
    selectedAsana,
    limit,
    offset,
    totalCount,
  } = useSelector((state) => state.asanaManagement, shallowEqual);

  const currentStartCount = offset * limit - (limit - 1);
  const currentEndCount =
    limit * offset < totalCount ? limit * offset : totalCount;

  useEffect(() => {
    if (refreshAsanaList) {
      dispatch(getAllAsanaAsync());
    }
  }, [refreshAsanaList]);

  useEffect(() => {
    setSelectedAsana(null);
  }, []);

  const setSelectedAsana = (asana) => {
    dispatch(AsanaManagementActions.setSelectedAsana({ selectedAsana: asana }));
  };

  const deleteAsana = () => {
    return dispatch(deleteAsanaAsync(selectedAsana.id));
  };

  const onLimitChange = (value) => {
    dispatch(
      AsanaManagementActions.setAsanaPagination({
        offset: 1,
        limit: parseInt(value),
      })
    );
  };

  const onPageChange = (page) => {
    dispatch(
      AsanaManagementActions.setAsanaPagination({ offset: page, limit })
    );
  };

  const onSearch = async (text) => {
    if (text.length > 1) {
      dispatch(AsanaManagementActions.setAsanaSearch({ searchText: text }));
    }
    if (!text) {
      dispatch(AsanaManagementActions.setAsanaSearch({ searchText: "" }));
    }
  };
  const onFilter = async (name, value) => {
    dispatch(AsanaManagementActions.setAsanaFilter({ name, value }));
  };
  return {
    isLoading,
    asanaList,
    refreshAsanaList,
    selectedAsana,
    limit,
    offset,
    totalCount,
    currentEndCount,
    currentStartCount,
    setSelectedAsana,
    onLimitChange,
    onPageChange,
    deleteAsana,
    onSearch,
    onFilter,
  };
};
