import { AuthActions } from "./authAction";
import { toast } from "react-toastify";
import firebase from "firebase/app";
import { post } from "../../../../api";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config";

export const loginAsync = (user) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
      if (resp && resp.user && resp.user.Aa) {
        // dispatch(AuthActions.setAuthToken(resp.user.Aa));
        const { data } = await post(`${apiService.authUrl}/login`, undefined, {
          headers: { idtoken: resp.user.Aa },
        });
        if (!data.isError) {
          dispatch(AuthActions.login({ user: data.data }));
          dispatch(AuthActions.setAuthToken(data.data.accessToken));
          dispatch(UIActions.setUIKey("loadingButton", false));
          return toast.success(data.message);
        }
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error("Invalid Email/Password");
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const logoutAsync = () => {
  return async (dispatch) => {
    try {
      const { data } = await post();
      if (data.responseCode === 200) {
        dispatch(AuthActions.logout(data.responseData));
      }
    } catch (error) {}
  };
};

export const forgotPasswordAsync = (user) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await firebase.auth().sendPasswordResetEmail(user.email);
      if (!resp) {
        toast.success("Email has been sent to your mail id.");
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const setNewPasswordAsync = ({ resetToken, password }) => {
  return async (dispatch) => {
    try {
      const { data } = await post();
      if (data.responseCode === 200) {
        dispatch(AuthActions.setNewPassword(data.responseData));
      }
    } catch (error) {}
  };
};

export const verifyAuthTokenAsync = () => {
  return async (dispatch) => {
    try {
      const { data } = await post();
      if (data.responseCode === 200) {
        dispatch(AuthActions.verifyAuthToken(data.responseData));
      }
    } catch (error) {}
  };
};
