import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useParams, Redirect } from "react-router-dom";
import { useFormik } from "formik";
// import { ProgressBar } from "react-bootstrap";
import {
  options,
  CreateCourseSchema,
  initialValues,
  createCoursePayload,
  getCourseFiles,
  formatSelectedCourseData,
  practiceOptions,
  studioOptions,
  awareOptions,
  tagsOptions,
} from "../config";
import { CommonCard } from "../../../../components/card/common-card";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { TextInput } from "../../../../components/inputs/text-input";
import { PATHS } from "../../../../components/aside-menu/links";
import { ButtonLoading } from "../../../../components/button";
import { CustomSelect } from "../../../../components/inputs/select";
import {
  COURSE_TYPES,
  COURSE_TYPE_OPTIONS,
  LANGUAGES,
} from "../../../../utils/constants";
import { useContentLanguage } from "../../../../hooks/content-language-hook";
import { LanguageButton } from "../../../../components/button/language-buttons";
// import { useLanguageData } from "../../../../hooks/language-data-hook";
import { Textarea } from "../../../../components/inputs/textarea";
import { Dropzone } from "../../../../components/dropzone/dropzone";
import { VideoTable } from "../components/video-table";
import { VideoModal } from "../components/video-modal";
import { AsanaModal } from "../components/asana-modal";
import { AsanaTable } from "../components/asana-table";
import { PoseModal } from "../components/pose-modal";
import { PoseTable } from "../components/pose-table";
import { CustomCreatableSelect } from "../../../../components/inputs/creatable-select";
import { useCourseHook } from "../../../../hooks/course-data-hook";
import {
  createCourseAsync,
  getCourseDetailsAsync,
  updateCourseAsync,
} from "../redux/api";
import "./course.css";
import { useAsanaHook } from "../../../../hooks/asana-data-hook";
import { usePoseHook } from "../../../../hooks/pose-data-hook";
import { searchAsanaAsync } from "../../asana/redux/api";
import { searchPoseAsync } from "../../pose/redux/api";

export const CreateCourse = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const isEditMode = params.courseId;

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  const { selectedCourse, isLoading, redirect, apiData } = useCourseHook();
  const asanaData = useAsanaHook();
  const poseData = usePoseHook();

  const { language, otherLanguage, toggleLanguage } = useContentLanguage();
  // const { data, updateData } = useLanguageData({
  //   ...initialValues,
  //   language: language,
  // });
  // const errosData = useLanguageData({});
  // const touchedData = useLanguageData({});

  const [videoModal, setVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [asanaModal, setAsanaModal] = useState(false);
  const [poseModal, setPoseModal] = useState(false);
  const [selectedAsanaPose, setSelectedAsanaPose] = useState(null);

  let defaultData = useMemo(
    () =>
      isEditMode && selectedCourse
        ? formatSelectedCourseData(selectedCourse)
        : JSON.parse(JSON.stringify(initialValues)),
    [selectedCourse]
  );

  useEffect(() => {
    if (isEditMode) {
      dispatch(getCourseDetailsAsync(isEditMode));
    }
  }, [isEditMode]);

  const formik = useFormik({
    initialValues: defaultData,
    validationSchema: CreateCourseSchema,
    enableReinitialize: true,
    // initialErrors: errosData,
    // initialTouched: touchedData,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      const payload = createCoursePayload(values, apiData);
      const filesDetails = getCourseFiles(values);
      if (selectedCourse) {
        let data = {
          ...payload,
          id: isEditMode,
        };
        return dispatch(updateCourseAsync(data, filesDetails));
      }
      dispatch(
        createCourseAsync(payload, filesDetails, () =>
          resetForm({ values: { ...initialValues } })
        )
      );
    },
  });

  const onLanguageChange = (lang) => {
    // updateData({ ...formik.values, language: lang });
    // errosData.updateData({ ...formik.errors });
    // touchedData.updateData({ ...formik.touched });
    toggleLanguage(lang);
  };

  const onDeleteVideo = (index) => {
    let allVideos = formik.values[language].videos;
    formik.setFieldValue(
      `${language}.videos`,
      allVideos.filter((_, i) => i !== index)
    );
  };

  const onEditVideo = (item, index) => {
    setSelectedVideo(item);
    setVideoModal(true);
  };

  const onDeleteAsana = (index) => {
    let allAsanas = formik.values[language].asanas;
    formik.setFieldValue(
      `${language}.asanas`,
      allAsanas.filter((_, i) => i !== index)
    );
  };

  const onDeletePose = (index) => {
    let allPoses = formik.values[language].poses;
    formik.setFieldValue(
      `${language}.poses`,
      allPoses.filter((_, i) => i !== index)
    );
  };

  const asanaSearchPromiseOptions = useCallback(async (value) => {
    let data = await searchAsanaAsync(value);
    if (data?.length) {
      return data.map((asana) => ({
        label: asana.details.en.name + " - " + asana.details.hi.name,
        value: asana.id,
        details: asana.details,
      }));
    }
    return [];
  }, []);

  const poseSearchPromiseOptions = useCallback(async (value) => {
    let data = await searchPoseAsync(value);
    if (data?.length) {
      return data.map((pose) => ({
        label: pose.poseName,
        value: pose.id,
        config: { poseName: pose.poseName },
      }));
    }
    return [];
  }, []);

  const onEditClickAsana = (item) => {
    setSelectedAsanaPose(item);
    setAsanaModal(true);
  };
  const onEditClickPose = (item) => {
    setSelectedAsanaPose(item);
    setPoseModal(true);
  };

  if (redirect) {
    return <Redirect to={PATHS.COURSE_MANAGEMENT} />;
  }
  console.log(formik.values);
  return (
    <CommonLayout>
      <CommonCard
        className="card w-75 mx-auto"
        header={
          <>
            <Link to={PATHS.COURSE_MANAGEMENT}>
              <span
                className="mdi mdi-arrow-left mr-2"
                style={{ fontSize: "x-large" }}
              ></span>
            </Link>{" "}
            <h4 className="header-title m-0">Create Course</h4>
          </>
        }
        headerClassName="card-header card-title"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <CustomSelect
                label="Course Type"
                name="courseType"
                formik={formik}
                options={COURSE_TYPE_OPTIONS}
                value={[
                  COURSE_TYPE_OPTIONS.find(
                    (option) => option.value === formik.values.courseType
                  ),
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <CustomCreatableSelect
                label="Tags"
                name="tags"
                formik={formik}
                options={tagsOptions}
                isSearchable
                isMulti
                value={formik.values.tags.map((tag) => ({
                  value: tag,
                  label: tag,
                }))}
              />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Categories"
                name="categories"
                formik={formik}
                options={
                  formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value
                    ? practiceOptions
                    : formik.values.courseType ===
                      COURSE_TYPES.YOGA_STUDIO.value
                    ? studioOptions
                    : formik.values.courseType === COURSE_TYPES.AWARE.value
                    ? awareOptions
                    : []
                }
                isSearchable
                isMulti
                value={formik.values.categories.map((category) => ({
                  label: category,
                  value: category,
                }))}
              />
            </div>
          </div>
          <hr className="my-3" />
          <div className="row">
            <div className="col-md-11">
              <LanguageButton
                language={language}
                onLanguageChange={onLanguageChange}
                errorBorder={
                  formik.touched[otherLanguage] && formik.errors[otherLanguage]
                }
              />
            </div>
          </div>
          <div className="chat-day-title my-2">
            <span className="title text-capitalize">{language}</span>
          </div>
          {language === LANGUAGES.ENGLISH && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.ENGLISH}.title`}
                    label="Title"
                    placeholder="Enter title"
                    id={`${LANGUAGES.ENGLISH}.title`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.ENGLISH}.duration`}
                    label="Duration"
                    placeholder="Enter duration"
                    id={`${LANGUAGES.ENGLISH}.duration`}
                    formik={formik}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Textarea
                    name={`${LANGUAGES.ENGLISH}.aboutCourse`}
                    label="About course"
                    placeholder="Enter about course"
                    id={`${LANGUAGES.ENGLISH}.aboutCourse`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <Dropzone
                    label="Thumbnail"
                    text="Drag 'n' drop image here, or click to select image"
                    info="(Only 1 image file will be accepted)"
                    allow="image/png, image/jpeg, image/jpg, image/gif"
                    type="image"
                    hideDropZoneOnPreview
                    preview
                    name={`${LANGUAGES.ENGLISH}.thumbnail`}
                    formik={formik}
                    language={language}
                  />
                </div>
              </div>
              {formik.values.courseType === COURSE_TYPES.AWARE.value && (
                <div className="row">
                  <div className="col-md-6">
                    <Dropzone
                      label="GIF"
                      text="Drag 'n' drop image here, or click to select image"
                      info="(Only 1 image file will be accepted)"
                      allow="image/png, image/jpeg, image/jpg, image/gif"
                      type="gif"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.ENGLISH}.gif`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                  <div className="col-md-6">
                    <Dropzone
                      label="Audio"
                      text="Drag 'n' drop audio here, or click to select audio"
                      info="(Only 1 audio file will be accepted)"
                      allow="audio/*"
                      type="audio"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.ENGLISH}.audio`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
              )}
              {(formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value ||
                formik.values.courseType ===
                  COURSE_TYPES.YOGA_STUDIO.value) && (
                <div className="row">
                  <div className="col-md-12">
                    <VideoTable
                      onBtnClick={() => {
                        formik.values[LANGUAGES.ENGLISH]?.videos?.length <= 3 &&
                          setVideoModal(true);
                        setSelectedVideo(null);
                      }}
                      rows={formik.values[LANGUAGES.ENGLISH].videos}
                      onDeleteVideo={onDeleteVideo}
                      onEditVideo={onEditVideo}
                    />
                  </div>
                </div>
              )}
              {(formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value ||
                formik.values.courseType ===
                  COURSE_TYPES.YOGA_STUDIO.value) && (
                <div className="row">
                  <div className="col-md-12">
                    <AsanaTable
                      onBtnClick={() => {
                        setSelectedAsanaPose(null);
                        setAsanaModal(true);
                      }}
                      rows={formik.values[LANGUAGES.ENGLISH].asanas}
                      onDeleteAsana={onDeleteAsana}
                      onEditClickAsana={onEditClickAsana}
                    />
                  </div>
                </div>
              )}
              {(formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value ||
                formik.values.courseType ===
                  COURSE_TYPES.YOGA_STUDIO.value) && (
                <div className="row">
                  <div className="col-md-12">
                    <PoseTable
                      onBtnClick={() => {
                        setSelectedAsanaPose(null);
                        setPoseModal(true);
                      }}
                      rows={formik.values[LANGUAGES.ENGLISH].poses}
                      onDeletePose={onDeletePose}
                      onEditClickPose={onEditClickPose}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {language === LANGUAGES.HINDI && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.HINDI}.title`}
                    label="Title"
                    placeholder="Enter title"
                    id={`${LANGUAGES.HINDI}.title`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    name={`${LANGUAGES.HINDI}.duration`}
                    label="Duration"
                    placeholder="Enter duration"
                    id={`${LANGUAGES.HINDI}.duration`}
                    formik={formik}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Textarea
                    name={`${LANGUAGES.HINDI}.aboutCourse`}
                    label="About course"
                    placeholder="Enter about course"
                    id={`${LANGUAGES.HINDI}.aboutCourse`}
                    formik={formik}
                  />
                </div>
                <div className="col-md-6">
                  <Dropzone
                    label="Thumbnail"
                    text="Drag 'n' drop image here, or click to select image"
                    info="(Only 1 image file will be accepted)"
                    allow="image/png, image/jpeg, image/jpg"
                    type="image"
                    hideDropZoneOnPreview
                    preview
                    name={`${LANGUAGES.HINDI}.thumbnail`}
                    formik={formik}
                    language={language}
                  />
                </div>
              </div>
              {formik.values.courseType === COURSE_TYPES.AWARE.value && (
                <div className="row">
                  <div className="col-md-6">
                    <Dropzone
                      label="GIF"
                      text="Drag 'n' drop image here, or click to select image"
                      info="(Only 1 image file will be accepted)"
                      allow="image/png, image/jpeg, image/jpg, image/gif"
                      type="gif"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.HINDI}.gif`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                  <div className="col-md-6">
                    <Dropzone
                      label="Audio"
                      text="Drag 'n' drop audio here, or click to select audio"
                      info="(Only 1 audio file will be accepted)"
                      allow="audio/*"
                      type="audio"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.HINDI}.audio`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
              )}
              {(formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value ||
                formik.values.courseType ===
                  COURSE_TYPES.YOGA_STUDIO.value) && (
                <div className="row">
                  <div className="col-md-12">
                    <VideoTable
                      onBtnClick={() => {
                        formik.values[LANGUAGES.HINDI]?.videos?.length <= 3 &&
                          setVideoModal(true);
                        setSelectedVideo(null);
                      }}
                      rows={formik.values[LANGUAGES.HINDI].videos}
                      onDeleteVideo={onDeleteVideo}
                      onEditVideo={onEditVideo}
                    />
                  </div>
                </div>
              )}
              {(formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value ||
                formik.values.courseType ===
                  COURSE_TYPES.YOGA_STUDIO.value) && (
                <div className="row">
                  <div className="col-md-12">
                    <AsanaTable
                      onBtnClick={() => {
                        setSelectedAsanaPose(null);
                        setAsanaModal(true);
                      }}
                      rows={formik.values[LANGUAGES.HINDI].asanas}
                      onDeleteAsana={onDeleteAsana}
                      onEditClickAsana={onEditClickAsana}
                    />
                  </div>
                </div>
              )}
              {(formik.values.courseType === COURSE_TYPES.YOGA_PRACTICE.value ||
                formik.values.courseType ===
                  COURSE_TYPES.YOGA_STUDIO.value) && (
                <div className="row">
                  <div className="col-md-12">
                    <PoseTable
                      onBtnClick={() => {
                        setSelectedAsanaPose(null);
                        setPoseModal(true);
                      }}
                      rows={formik.values[LANGUAGES.HINDI].poses}
                      onDeletePose={onDeletePose}
                      onEditClickPose={onEditClickPose}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {/* <div className="d-flex justify-content-between"> */}
          {/* <ProgressBar now={40} style={{width: "300px"}} /> */}
          <div className="form-group text-right">
            <Link
              to={PATHS.COURSE_MANAGEMENT}
              className="btn btn-light waves-effect waves-light mr-3"
            >
              Cancel
            </Link>
            <ButtonLoading
              className="btn-primary waves-effect waves-light w-15"
              type="submit"
              title={"Submit"}
              isLoading={loadingButton}
            />
          </div>
          {/* </div> */}
        </form>
      </CommonCard>
      <VideoModal
        show={videoModal}
        setShow={setVideoModal}
        formik={formik}
        language={language}
        name={`${language}.videos`}
        selectedVideo={selectedVideo}
        setSelectedVideo={setSelectedVideo}
      />
      <AsanaModal
        show={asanaModal}
        setShow={setAsanaModal}
        formik={formik}
        name={`${language}.asanas`}
        asanaList={asanaData.asanaList}
        asanaSearchPromiseOptions={asanaSearchPromiseOptions}
        selectedAsanaPose={selectedAsanaPose}
        setSelectedAsanaPose={setSelectedAsanaPose}
      />
      <PoseModal
        show={poseModal}
        setShow={setPoseModal}
        formik={formik}
        name={`${language}.poses`}
        poseList={poseData.poseList}
        poseSearchPromiseOptions={poseSearchPromiseOptions}
        selectedAsanaPose={selectedAsanaPose}
        setSelectedAsanaPose={setSelectedAsanaPose}
      />
    </CommonLayout>
  );
};
