import React from "react";

export const CommonLayout = ({ children }) => {
  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">{children}</div>
      </div>
    </div>
  );
};
