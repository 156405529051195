import { ContentManagementActions } from "./action";
import { get, post } from "../../../../api";
import { toast } from "react-toastify";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config";

export const getContentDataAsync = (type) => {
  return async (dispatch) => {
    try {
      // dispatch(PoseManagementActions.setPoseListLoading(true));
      let url = `${apiService.contentUrl}?type=${type}`;
      const { data } = await get(url);
      if (data && !data.isError) {
        return dispatch(
          ContentManagementActions.getContentData({
            type,
            data: data?.data,
          })
        );
        // return dispatch(PoseManagementActions.setPoseListLoading(false));
      }
      // dispatch(PoseManagementActions.setPoseListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      // dispatch(PoseManagementActions.setPoseListLoading(false));
      return toast.error(error.message);
    }
  };
};

export const updateContentAsync = (data) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await post(`${apiService.contentUrl}`, data);
      if (!resp?.data?.isError) {
        // dispatch(PoseManagementActions.createPose());
        dispatch(UIActions.setUIKey("loadingButton", false));
        if (resp?.data?.message) {
          return toast.success(resp?.data?.message);
        }
        return;
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};
