import React, { useState } from 'react';
import { Fragment } from 'react';

export const ImageCard = ({src = "", width = "32", height = "32", alt, loading = "lazy", className = "", Fallback}) => {
    const [showDefault, setShowDefault] = useState(false)
    const onError = () => {
        setShowDefault(true);
    }
    return (
        <Fragment>
            {!showDefault && (
                <img
                src={src}
                height={height}
                width={width}
                className={className}
                loading={loading}
                onError={onError}
              />
            )}
            {
                showDefault && Fallback
            }
        </Fragment>
    )
}