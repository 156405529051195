/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useDropzone } from "react-dropzone";
import { get_base_64 } from "../../utils/functions";
import "./dropzone.css";

export const Dropzone = ({
  label,
  text,
  allow,
  multiple = false,
  info,
  type,
  preview = false,
  hideDropZoneOnPreview = false,
  formik,
  name,
  language,
}) => {
  console.log(label);
  const [imageUrl, setImageUrl] = useState([]);
  const [gifUrl, setGifUrl] = useState([]);
  const [audioUrl, setAudioUrl] = useState([]);
  const [videos, setVideo] = useState([]);
  let acceptedFiles = get(formik.values, name);

  let { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    accept: allow || "",
    onDrop: async (files) => {
      if (preview && type === "image") {
        const img = await Promise.all(files.map((file) => get_base_64(file)));
        console.log(img);
        setImageUrl(img);
      }
      if (preview && type === "gif") {
        const gif = await Promise.all(files.map((file) => get_base_64(file)));
        console.log(gif);
        setGifUrl(gif);
      }
      if (preview && type === "video") {
        setVideo(files);
      }
      if (preview && type === "audio") {
        setAudioUrl(files);
      }
      formik.setFieldValue(name, files);
      console.log(formik);
    },
  });

  useEffect(async () => {
    let files = acceptedFiles;
    if (files && files.length) {
      if (preview && type === "image") {
        setImageUrl(
          await Promise.all(
            files.map((file) => {
              if (typeof file !== "string") {
                return get_base_64(file);
              }
              return file;
            })
          )
        );
      }
      if (preview && type === "gif") {
        setGifUrl(
          await Promise.all(
            files.map((file) => {
              if (typeof file !== "string") {
                return get_base_64(file);
              }
              return file;
            })
          )
        );
      }
      if (preview && type === "video") {
        setVideo(files);
      }
      if (preview && type === "audio") {
        setAudioUrl(files);
      }
    } else {
      setImageUrl([]);
      setAudioUrl([]);
      setVideo([]);
    }
  }, [acceptedFiles]);

  const onRemoveFile = (index) => {
    let newFiles = acceptedFiles.filter((file, i) => i !== index);
    if (type === "image") {
      setImageUrl((prevImages) => prevImages.filter((_, i) => i !== index));
    }
    if (type === "gif") {
      setGifUrl((prevImages) => prevImages.filter((_, i) => i !== index));
    }
    if (type === "video") {
      setVideo((prevVideos) => prevVideos.filter((_, i) => i !== index));
    }
    if (type === "audio") {
      setAudioUrl((prevAudio) => prevAudio.filter((_, i) => i !== index));
    }

    formik.setFieldValue(name, newFiles);
  };
  console.log(acceptedFiles, label, hideDropZoneOnPreview);
  return (
    <div className="form-group">
      <label style={{ marginTop: "2px" }}>{label}</label>
      {(acceptedFiles.length === 0 || !hideDropZoneOnPreview) && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>{text}</p>
          {info && <em>{info}</em>}
        </div>
      )}
      {preview &&
        type === "image" &&
        acceptedFiles.length > 0 &&
        imageUrl.length > 0 &&
        imageUrl.map((url, i) => (
          <div style={{ position: "relative" }} key={i}>
            <span className="dropzone-delete-file">
              <i
                className="dripicons-cross"
                onClick={() => onRemoveFile(i)}
              ></i>
            </span>
            <img
              className="dropzone-image-preview"
              src={url}
              alt={acceptedFiles[i].name}
            />
          </div>
        ))}
      {preview &&
        type === "gif" &&
        acceptedFiles.length > 0 &&
        gifUrl.length > 0 &&
        gifUrl.map((url, i) => (
          <div style={{ position: "relative" }} key={i}>
            <span className="dropzone-delete-file">
              <i
                className="dripicons-cross"
                onClick={() => onRemoveFile(i)}
              ></i>
            </span>
            <img
              className="dropzone-image-preview"
              src={url}
              alt={acceptedFiles[i].name}
            />
          </div>
        ))}
      {preview &&
        type === "video" &&
        acceptedFiles.length > 0 &&
        videos.length > 0 &&
        videos.map((video, i) => (
          <div style={{ position: "relative" }} key={i}>
            <span className="dropzone-delete-file">
              <i
                className="dripicons-cross"
                onClick={() => onRemoveFile(i)}
              ></i>
            </span>
            <video controls className="w-100">
              <source
                src={
                  typeof video !== "string" ? URL.createObjectURL(video) : video
                }
              />
            </video>
          </div>
        ))}
      {preview &&
        type === "audio" &&
        acceptedFiles.length > 0 &&
        audioUrl.length > 0 &&
        audioUrl.map((audio, i) => (
          <div style={{ position: "relative" }} key={i}>
            <span className="dropzone-delete-file audio">
              <i
                className="dripicons-cross"
                onClick={() => onRemoveFile(i)}
              ></i>
            </span>
            <audio controls className="w-100">
              <source
                src={
                  typeof audio !== "string" ? URL.createObjectURL(audio) : audio
                }
              />
            </audio>
          </div>
        ))}
    </div>
  );
};
