import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './login';
import ForgotPassword from './forgot-password';
import SetPassword from './set-password';

export default function AuthPage() {
    return (
        <div className="authentication-bg">
            <div className='account-pages mt-5 mb-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-5'>
                            <div className='text-center'>
                                {/* <a href='index.html' className='logo'>
                                    <img src='assets/images/logo-light.png' alt='' height='22' className='logo-light mx-auto' />
                                    <img src='assets/images/logo-dark.png' alt='' height='22' className='logo-dark mx-auto' />
                                </a> */}
                                <p className='text-muted mt-2 mb-4'>Admin Dashboard</p>
                            </div>
                            <Switch>
                                <Route path='/auth/login' component={Login} />
                                <Route path='/auth/forgot-password' component={ForgotPassword} />
                                <Route path='/auth/set-password/:resetToken' component={SetPassword} />
                                <Redirect from='/auth' exact={true} to='/auth/login' />
                                <Redirect to='/auth/login' />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
