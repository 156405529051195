import { PoseManagementMap } from "./action";

const initialState = {
  isLoading: false,
  poseList: {},
  refreshPoseList: true,
  selectedPose: null,
  limit: 10,
  offset: 1,
  totalCount: 0,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PoseManagementMap.POSE_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case PoseManagementMap.GET_ALL_POSE: {
      return {
        ...state,
        poseList: action.payload.pose,
        totalCount: action.payload.totalCount,
        refreshPoseList: false,
      };
    }
    case PoseManagementMap.CREATE_POSE: {
      return {
        ...state,
        refreshPoseList: true,
        redirect: true,
      };
    }
    // case PoseManagementMap.UPDATE_USER: {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     refreshUserList: true,
    //     selectedUser: {
    //       ...state.selectedUser,
    //       ...action.payload,
    //     },
    //     reDirect: true,
    //   };
    // }
    case PoseManagementMap.DELETE_POSE: {
      return {
        ...state,
        refreshPoseList: true,
        selectedPose: null,
      };
    }
    case PoseManagementMap.SET_SELECTED_POSE: {
      return {
        ...state,
        selectedPose: action.payload.selectedPose,
      };
    }
    case PoseManagementMap.POSE_PAGE_CHANGE: {
      return {
        ...state,
        offset: action.payload.offset || state.offset,
        limit: action.payload.limit || state.limit,
        refreshPoseList: true,
      };
    }
    case PoseManagementMap.POSE_SEARCH_CHANGE: {
      return {
        ...state,
        searchText: action.payload.searchText,
        offset: 1,
        refreshPoseList: true
      };
    }
    default:
      return { ...state };
  }
};
