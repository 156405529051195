import _ from "lodash";
import * as Yup from "yup";

export const CreatePoseSchema = Yup.object().shape({
  poseName: Yup.string().required("Pose name is required"),
});

export const poseItems = {
  lWristHipDST: { min: "", max: "" },
  leftHandAngle: { min: "", max: "" },
  leftLegAngle: { min: "", max: "" },
  leftWristShoulderHip: { min: "", max: "" },
  lrAnkleDST: { min: "", max: "" },
  lrWristDST: { min: "", max: "" },
  rWristHipDST: { min: "", max: "" },
  rightHandAngle: { min: "", max: "" },
  rightLegAngle: { min: "", max: "" },
  rightWristShoulderHip: { min: "", max: "" },
};

const posePYStringTemplate =
  "{'Lhand_angle' : Lhand_angle in range(#leftHandAngleMin#, #leftHandAngleMax#),\n\t\t\t'Rhand_angle' : Rhand_angle in range(#rightHandAngleMin#, #rightHandAngleMax#),\n\t\t\t'Lleg_angle' : Lleg_angle in range(#leftLegAngleMin#, #leftLegAngleMax#),\n\t\t\t'Rleg_angle' : Rleg_angle in range(#rightLegAngleMin#, #rightLegAngleMax#),\n\t\t\t'l_wrist_shoulder_hip' : l_wrist_shoulder_hip in range(#leftWristShoulderHipMin#, #leftWristShoulderHipMax#),\n\t\t\t'r_wrist_shoulder_hip' : r_wrist_shoulder_hip in range(#rightWristShoulderHipMin#, #rightWristShoulderHipMax#),\n\t\t\t'l_r_wrist_dst' : #lrWristDSTMin# <= l_r_wrist_dst <= #lrWristDSTMax#,\n\t\t\t'l_r_ankle_dst' : #lrAnkleDSTMin# <= l_r_ankle_dst <= #lrAnkleDSTMax#,\n\t\t\t'l_wrist_hip_dst' : #lWristHipDSTMin# <= l_wrist_hip_dst <= #lWristHipDSTMax#,\n\t\t\t'r_wrist_hip_dst' : #rWristHipDSTMin# <= r_wrist_hip_dst <= #rWristHipDSTMax#,\n\t\t\t'wrist_nose_above' : False,\n\t\t\t'wrist_hip_above' : True}";

export const getInitialValues = (data) => {
  let poseJSON = {
    str_json: posePYStringTemplate,
    parsed_json: data?.poseJSON?.parsed_json || poseItems,
  };
  return {
    poseName: data?.poseName || "",
    deviation: data?.deviation || "",
    poseJSON,
    accuracyMeter: data?.accuracyMeter,
  };
};

export const poseItemsMinMax = [
  "lWristHipDST",
  "leftHandAngle",
  "leftLegAngle",
  "leftWristShoulderHip",
  "lrAnkleDST",
  "lrWristDST",
  "rWristHipDST",
  "rightHandAngle",
  "rightLegAngle",
  "rightWristShoulderHip",
];

export const mapPoseItems = (poseJSON) => {
  let resultantPoseString = posePYStringTemplate;
  _.mapKeys(poseJSON, (value, key) => {
    if (poseItemsMinMax.includes(key)) {
      resultantPoseString = resultantPoseString.replace(
        `#${key}Min#`,
        value.min
      );
      resultantPoseString = resultantPoseString.replace(
        `#${key}Max#`,
        value.max
      );
    }
  });
  return resultantPoseString;
};
