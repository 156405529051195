import { AuthActions } from "../app/modules/auth/redux/authAction";
import { toast } from "react-toastify";
import { get } from "../api";
import { apiService } from "../api/config";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      if (authToken && !config.url.includes("/auth/token")) {
        config.headers.accesstoken = authToken;
      }

      return config;
    },
    (err) => Promise.resolve(err)
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (err) => {
      if (err.response && err.response.status === 401) {
        const {
          auth: {
            user: { refreshToken },
          },
        } = store.getState();
        if (refreshToken && !err.response.config.url.includes("/auth/token")) {
          let { data } = await axios({
            url: `${apiService.serverUrl}/app/auth/token`,
            method: "GET",
            headers: {
              refreshtoken: refreshToken,
            },
          });
          if (!data?.isError) {
            store.dispatch(AuthActions.login({ user: data.data }));
            return store.dispatch(
              AuthActions.setAuthToken(data.data.accessToken)
            );
          }
        }
        store.dispatch(AuthActions.logout());
        return toast.error("Unauthorized. Please login again.");
      }
      return Promise.resolve(err.response);
    }
  );
}
