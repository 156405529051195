export const UserManagementMap = {
    GET_ALL_USER: 'GET_ALL_USER',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER',
    SET_SELECTED_USER: 'SET_SELECTED_USER',
    USER_PAGE_CHANGE: 'USER_PAGE_CHANGE',
    USER_LIST_LOADING: 'USER_LIST_LOADING',
};

export const UserManagementActions = {
    getAllUser: (data) => ({ type: UserManagementMap.GET_ALL_USER, payload: data, }),

    createUser: (data) => ({ type: UserManagementMap.CREATE_USER, payload: data, }),

    updateUser: (data) => ({ type: UserManagementMap.UPDATE_USER, payload: data, }),

    deleteUser: (data) => ({ type: UserManagementMap.DELETE_USER, payload: data, }),

    setSelectedUser: (data) => ({ type: UserManagementMap.SET_SELECTED_USER, payload: data }),

    setUserPagination: (data) => ({type: UserManagementMap.USER_PAGE_CHANGE, payload: data}),

    setUserListLoading: (data) => ({type: UserManagementMap.USER_LIST_LOADING, payload: data}),
};
