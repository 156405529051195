import { PoseManagementActions } from "./action";
import { get, post, update, remove, upload } from "../../../../api";
import { toast } from "react-toastify";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config"; 

export const getAllPoseAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(PoseManagementActions.setPoseListLoading(true));
      const { limit, offset, searchText } = getState().poseManagement;
      let url = `${apiService.poseUrl}/list?limit=${limit}&offset=${offset}`;
      if (searchText) {
        url += `&searchText=${searchText}`;
      }
      const { data } = await get(url);
      if (data && !data.isError) {
        dispatch(
          PoseManagementActions.getAllPose({
            pose: data?.data?.poses || [],
            totalCount: data?.data?.pagination?.totalCount || 0,
          })
        );
        return dispatch(PoseManagementActions.setPoseListLoading(false));
      }
      dispatch(PoseManagementActions.setPoseListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(PoseManagementActions.setPoseListLoading(false));
      return toast.error(error.message);
    }
  };
};

export const getPoseDetailsAsync = (poseId) => {
  return async (dispatch) => {
    try {
      dispatch(PoseManagementActions.setPoseListLoading(true));
      const { data } = await get(`${apiService.poseUrl}?poseId=${poseId}`);
      if (data && !data.isError) {
        dispatch(
          PoseManagementActions.setSelectedPose({
            selectedPose: data?.data || null,
          })
        );
        return dispatch(PoseManagementActions.setPoseListLoading(false));
      }
      dispatch(PoseManagementActions.setPoseListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(PoseManagementActions.setPoseListLoading(false));
      return toast.error(error.message);
    }
  };
};

export const createPoseAsync = (data) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await post(`${apiService.poseUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(PoseManagementActions.createPose());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(resp?.data?.message);
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const updatePoseAsync = (data) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await update(`${apiService.poseUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(resp?.data?.message);
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};
export const deletePoseAsync = (id) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await remove(`${apiService.poseUrl}?poseId=${id}`);
      if (!resp?.data?.isError) {
        dispatch(PoseManagementActions.deletePose());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(resp?.data?.message);
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const searchPoseAsync = async (searchText) => {
  try {
    let url = `${apiService.poseUrl}/list?limit=50&offset=1`;
    if (searchText) {
      url += `&searchText=${searchText}`;
    }
    const { data } = await get(url);
    if (data && !data.isError) {
      return data?.data?.poses || [];
    }
    return [];
  } catch (error) {
    return [];
  }
};
