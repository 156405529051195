import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { AsideMenu } from "../components/aside-menu";
import SplashScreen from "../components/splashscreen";
import Dashboard from "./modules/dashboard/dashboard";
import UserManagement from "./modules/user-management/user-management";
import CreateUser from "./modules/user-management/pages/create-user";
import CategoryManagement from "./modules/category/category-management";
// import Footer from '../components/footer';
import ProfileSettings from "./modules/profile-settings/profile-settings";
import { CreateCategory } from "./modules/category/pages/create-category";
import { PATHS } from "../components/aside-menu/links";
import { CreateCourse } from "./modules/course/pages/create-course";
import CourseManagement from "./modules/course/course-management";
import AsanaManagement from "./modules/asana/asana-management";
import { CreateAsana } from "./modules/asana/page/create-asana";
import BannerManagement from "./modules/banner/banner-management";
import { CreateBanner } from "./modules/banner/page/create-banner";
import PoseManagement from "./modules/pose/pose-management";
import { CreatePose } from "./modules/pose/page/create-pose";
import ContentManagement from "./modules/cms/content-management";
import { CreateMultiCourse } from "./modules/course/pages/create-multi-course";

const BaseRoutes = [
  // Admin
  { path: PATHS.DASHBOARD, component: Dashboard, exact: true },
  { path: PATHS.PROFILE_SETINGS, component: ProfileSettings, exact: true },
  // User management
  { path: PATHS.USER_MANAGEMENT, component: UserManagement, exact: true },
  {
    path: [
      PATHS.USER_MANAGEMENT + PATHS.CREATE_USER,
      PATHS.USER_MANAGEMENT + PATHS.EDIT_USER,
    ],
    component: CreateUser,
  },
  // Category Management
  {
    path: PATHS.CATEGORY_MANAGEMENT,
    component: CategoryManagement,
    exact: true,
  },
  {
    path: PATHS.CATEGORY_MANAGEMENT + PATHS.CREATE_CATEGORY,
    component: CreateCategory,
  },
  // Course Management
  { path: PATHS.COURSE_MANAGEMENT, component: CourseManagement, exact: true },
  {
    path: [
      PATHS.COURSE_MANAGEMENT + PATHS.CREATE_COURSE,
      PATHS.COURSE_MANAGEMENT + PATHS.EDIT_COURSE,
    ],
    component: CreateCourse,
  },
  {
    path: [
      PATHS.COURSE_MANAGEMENT + PATHS.CREATE_MULTI_COURSE,
      PATHS.COURSE_MANAGEMENT + PATHS.EDIT_MULTI_COURSE,
    ],
    component: CreateMultiCourse,
  },
  // Banner Management
  { path: PATHS.BANNER_MANAGEMENT, component: BannerManagement, exact: true },
  {
    path: [
      PATHS.BANNER_MANAGEMENT + PATHS.CREATE_BANNER,
      PATHS.BANNER_MANAGEMENT + PATHS.EDIT_BANNER,
    ],
    component: CreateBanner,
  },
  // Pose Management
  { path: PATHS.POSE_MANAGEMENT, component: PoseManagement, exact: true },
  {
    path: [
      PATHS.POSE_MANAGEMENT + PATHS.CREATE_POSE,
      PATHS.POSE_MANAGEMENT + PATHS.EDIT_POSE,
    ],
    component: CreatePose,
  },
  // Content Management
  { path: PATHS.CONTENT_MANAGEMENT, component: ContentManagement, exact: true },
  // Asana Management
  { path: PATHS.ASANA_MANAGEMENT, component: AsanaManagement, exact: true },
  {
    path: [
      PATHS.ASANA_MANAGEMENT + PATHS.CREATE_ASANA,
      PATHS.ASANA_MANAGEMENT + PATHS.EDIT_ASANA,
    ],
    component: CreateAsana,
  },
];

const BasePageRoutes = () => {
  return (
    <React.Suspense fallback={<SplashScreen />}>
      <AsideMenu />
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        {BaseRoutes.map((page) => (
          <Route {...page} key={page.path} />
        ))}
        <Redirect to="error/error-v1" />
      </Switch>
      {/* <Footer /> */}
    </React.Suspense>
  );
};

export default BasePageRoutes;
