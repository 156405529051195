import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordAsync } from '../redux/authApi';
import { NavLink } from 'react-router-dom';
import { ButtonLoading } from '../../../../components/button/loading-button';

const ForgotPassword = () => {

    const initialValues = {
        email: '',
    };

    const ForgotPasswordSchema = Yup.object().shape({
        email: Yup.string().trim()
            .email("Enter valid email")
            .required("Email Id is required"),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }
        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }
        return "";
    };

    const dispatch = useDispatch();

    const loadingButton = useSelector(state => state.ui.loadingButton);

    const formik = useFormik({
        initialValues,
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(forgotPasswordAsync(values));
            resetForm()
        },
    });

    return (
        <>
            <div className='card'>
                <div className='card-body p-4'>
                    <div className='text-center mb-4'>
                        <h4 className='text-uppercase mt-0 mb-3'>Reset Password</h4>
                        <p className='text-muted mb-0 font-13'>Enter your email address and we'll send you an email with instructions to reset your password.  </p>
                    </div>

                    <form action='#' onSubmit={formik.handleSubmit}>
                        <div className='form-group mb-3'>
                            <label htmlFor='emailaddress'>Email address</label>
                            <input
                                className={`form-control ${getInputClasses('email')}`}
                                type='email'
                                id='emailaddress'
                                placeholder='Enter your email'
                                name='email'
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.email}</div>
                                </div>
                            ) : null}
                        </div>
                        <div className='form-group mb-0 text-center'>
                            <ButtonLoading type="submit" title="Reset Password" className="btn-primary btn-block" isLoading={loadingButton} />
                        </div>
                    </form>

                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-12 text-center'>
                    <p className='text-muted'>Back to <NavLink to='/auth/login' className='text-dark ml-1'><b>Log in</b></NavLink></p>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;
