export const apiService = {
  serverUrl: process.env.REACT_APP_SERVER_URL,
  assetBaseUrl: process.env.REACT_APP_UPLOAD_BASE_URL,
  baseUrl: "admin",
  poseUrl: "pose",
  contentUrl: "static",
  asanaUrl: "asana",
  courseUrl: "course",
  multiCourseUrl: "course/multi-day",
  addDayUrl: "add-day",
  updateDayUrl: "update-day",
  removeDayUrl: "remove-day",
  bannerUrl: "banner",
  userUrl: "user",
  authUrl: "auth",
};
