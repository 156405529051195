import { DashboardMap } from "./action";

const initialState = {
  userCount: {
      totalUsers: 0,
      activeCount: 0,
      inActiveCount: 0
  },
  loading: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DashboardMap.DASHBOARD_USER_COUNT: {
        return {
            ...state,
            userCount: {
                ...state.userCount,
                ...action.payload
            },
            loading: false
        }
    }
    case DashboardMap.DASHBOARD_LOADING: {
      return {
          ...state,
          loading: action.payload
      }
  }
    default:
      return { ...state };
  }
};
