import React, { useState } from "react";
import "./search.css";

let timer = null;

export const SearchInput = ({ onSearchInput }) => {
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    let { value } = e.target; 
    clearTimeout(timer);
    timer = setTimeout(() => onSearchInput(value), 500);
    setValue(value);
  };
  const onClear = () => {
    onSearchInput("");
    setValue("");
  };
  return (
    <form className="app-search">
      <div className="app-search-box">
        <div className="input-group">
          <div className="input-group-prepend">
            <button className="btn search-i" type="button">
              <i className="fe-search search-i"></i>
            </button>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={value}
            onChange={handleChange}
          />
          <div className="input-group-append">
            <button className="btn" type="button">
              {value && <i className="dripicons-cross" onClick={onClear} />}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
