export const AuthMap = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
    VERIFY_AUTH_TOKEN: 'VERIFY_AUTH_TOKEN',
    AUTH_ISLOADING: 'AUTH_ISLOADING',
    SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
    UPDATE_ADMIN_PROFILE: 'UPDATE_ADMIN_PROFILE',
}

export const AuthActions = {
    login: (data) => ({ type: AuthMap.LOGIN, payload: data }),

    logout: () => ({ type: AuthMap.LOGOUT }),

    setNewPassword: (data) => ({ type: AuthMap.SET_NEW_PASSWORD, payload: data }),

    verifyAuthToken: (data) => ({ type: AuthMap.VERIFY_AUTH_TOKEN, payload: data }),

    authIsLoading: (loading) => ({ type: AuthMap.AUTH_ISLOADING, payload: loading }),

    setAuthToken: (token) => ({ type: AuthMap.SET_AUTH_TOKEN, payload: token }),

    updateAdminProfile: (data) => ({type: AuthMap.UPDATE_ADMIN_PROFILE, payload: data}),
} 