import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  createAsanaAsync,
  getAsanaDetailsAsync,
  updateAsanaAsync,
} from "../redux/api";
import {
  createAsanaPayload,
  CreateAsanaSchema,
  difficultyOptions,
  formatSelectedAsanaData,
  getAsanaFiles,
  initialValues,
  positionOptions,
} from "../config";
import { CommonCard } from "../../../../components/card/common-card";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { TextInput } from "../../../../components/inputs/text-input";
import { PATHS } from "../../../../components/aside-menu/links";
import { ButtonLoading } from "../../../../components/button";
import { useContentLanguage } from "../../../../hooks/content-language-hook";
import { LanguageButton } from "../../../../components/button/language-buttons";
// import { useLanguageData } from "../../../../hooks/language-data-hook";
import { Textarea } from "../../../../components/inputs/textarea";
import { Dropzone } from "../../../../components/dropzone/dropzone";
import { CustomSelect } from "../../../../components/inputs/select";
import { LANGUAGES } from "../../../../utils/constants";
import "./asana.css";
import { searchPoseAsync } from "../../pose/redux/api";
import { usePoseHook } from "../../../../hooks/pose-data-hook";
import { PoseTable } from "../components/pose-table";
import { PoseModal } from "../components/pose-modal";

export const CreateAsana = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const isEditMode = params.asanaId;

  const { isLoading, selectedAsana, redirect, apiData } = useSelector(
    (state) => state.asanaManagement,
    shallowEqual
  );

  const [poseModal, setPoseModal] = useState(false);
  const [selectedAsanaPose, setSelectedAsanaPose] = useState(null);

  const poseData = usePoseHook();

  const { language, otherLanguage, toggleLanguage } = useContentLanguage();

  let defaultData = useMemo(() => {
    return isEditMode && selectedAsana
      ? formatSelectedAsanaData(JSON.parse(JSON.stringify(selectedAsana)))
      : initialValues;
  }, [selectedAsana]);

  // const { data, updateData } = useLanguageData({
  //   ...defaultData,
  //   language: language,
  // });

  // const errosData = useLanguageData({});
  // const touchedData = useLanguageData({});

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  useEffect(() => {
    if (isEditMode) {
      dispatch(getAsanaDetailsAsync(isEditMode));
    }
  }, [isEditMode]);

  const formik = useFormik({
    initialValues: { ...defaultData },
    validationSchema: CreateAsanaSchema,
    enableReinitialize: true,
    // initialErrors: errosData,
    // initialTouched: touchedData,
    onSubmit: (values) => {
      let payload = createAsanaPayload(values, apiData);
      let filesData = getAsanaFiles(values);
      // return console.log(payload);
      if (selectedAsana) {
        let data = {
          ...payload,
          id: isEditMode,
        };
        return dispatch(updateAsanaAsync(data, filesData));
      }
      dispatch(createAsanaAsync(payload, filesData));
    },
  });

  const onLanguageChange = (lang) => {
    // updateData({ ...formik.values, language: lang });
    // errosData.updateData({ ...formik.errors });
    // touchedData.updateData({ ...formik.touched });
    toggleLanguage(lang);
  };

  const poseSearchPromiseOptions = useCallback(async (value) => {
    let data = await searchPoseAsync(value);
    if (data?.length) {
      return data.map((pose) => ({
        label: pose.poseName,
        value: pose.id,
        config: { poseName: pose.poseName },
      }));
    }
    return [];
  }, []);

  const onDeletePose = (index) => {
    let allPoses = formik.values[language].pose;
    formik.setFieldValue(
      `${language}.pose`,
      allPoses.filter((_, i) => i !== index)
    );
  };

  const onEditClickPose = (item) => {
    setSelectedAsanaPose(item);
    setPoseModal(true);
  };

  if (redirect) {
    return <Redirect to={PATHS.ASANA_MANAGEMENT} />;
  }
  return (
    <CommonLayout>
      <CommonCard
        className="card w-75 mx-auto"
        header={
          <>
            <Link to={PATHS.ASANA_MANAGEMENT}>
              <span
                className="mdi mdi-arrow-left mr-2"
                style={{ fontSize: "x-large" }}
              ></span>
            </Link>{" "}
            <h4 className="header-title m-0">Create Asana</h4>
          </>
        }
        headerClassName="card-header card-title"
      >
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            {/* <div className="row">
            <div className="col-md-6">
              <TextInput
                name={`nameEnglish`}
                label="Name (English)"
                placeholder="Enter name (english)"
                id={`nameEnglish`}
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                name={`nameHindi`}
                label="Name (Hindi)"
                placeholder="Enter name (hindi)"
                id={`nameHindi`}
                formik={formik}
              />
            </div>
          </div> */}
            <div className="row">
              <div className="col-md-6">
                <CustomSelect
                  name={`difficulty`}
                  label="Difficulty"
                  formik={formik}
                  options={difficultyOptions}
                  value={[
                    difficultyOptions.find(
                      (option) => option.value === formik.values.difficulty
                    ),
                  ]}
                  defaultValue={{
                    label: difficultyOptions[0].label,
                    value: difficultyOptions[0].value,
                  }}
                />
              </div>
              <div className="col-md-6">
                <CustomSelect
                  label="Position"
                  name="position"
                  formik={formik}
                  options={positionOptions}
                  isSearchable
                  value={[
                    positionOptions.find(
                      (option) => option.value === formik.values.position
                    ),
                  ]}
                  defaultValue={{
                    label: positionOptions[0].label,
                    value: positionOptions[0].value,
                  }}
                />
              </div>
            </div>
            <hr className="my-3" />
            <div className="row">
              <div className="col-md-11">
                <LanguageButton
                  language={language}
                  onLanguageChange={onLanguageChange}
                  errorBorder={
                    formik.touched[otherLanguage] &&
                    formik.errors[otherLanguage]
                  }
                />
              </div>
            </div>
            <div className="chat-day-title my-2">
              <span className="title text-capitalize">{language}</span>
            </div>
            {language === LANGUAGES.ENGLISH && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      name={`${LANGUAGES.ENGLISH}.name`}
                      label={`Name`}
                      placeholder={`Enter name`}
                      id={`${LANGUAGES.ENGLISH}.name`}
                      formik={formik}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Textarea
                      name={`${LANGUAGES.ENGLISH}.contradictions`}
                      label="Contradictions"
                      placeholder="Enter contradictions"
                      id={`${LANGUAGES.ENGLISH}.contradictions`}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-6">
                    <Textarea
                      name={`${LANGUAGES.ENGLISH}.benefits`}
                      label="Benefits"
                      placeholder="Enter benefits"
                      id={`${LANGUAGES.ENGLISH}.benefits`}
                      formik={formik}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Dropzone
                      label="Thumbnail"
                      text="Drag 'n' drop image here, or click to select image"
                      info="(Only 1 image file will be accepted)"
                      allow="image/png, image/jpeg, image/jpg"
                      type="image"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.ENGLISH}.thumbnail`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Dropzone
                      label="Video"
                      text="Drag 'n' drop video here, or click to select video"
                      info="(Only 1 video file will be accepted)"
                      type="video"
                      allow="video/*"
                      preview
                      hideDropZoneOnPreview
                      name={`${LANGUAGES.ENGLISH}.video`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <PoseTable
                      onBtnClick={() => {
                        setSelectedAsanaPose(null);
                        setPoseModal(true);
                      }}
                      rows={formik.values[LANGUAGES.ENGLISH].pose}
                      onDeletePose={onDeletePose}
                      onEditClickPose={onEditClickPose}
                    />
                  </div>
                </div>
              </>
            )}
            {language === LANGUAGES.HINDI && (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      name={`${LANGUAGES.HINDI}.name`}
                      label={`Name`}
                      placeholder={`Enter name`}
                      id={`${LANGUAGES.HINDI}.name`}
                      formik={formik}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Textarea
                      name={`${LANGUAGES.HINDI}.contradictions`}
                      label="Contradictions"
                      placeholder="Enter contradictions"
                      id={`${LANGUAGES.HINDI}.contradictions`}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-6">
                    <Textarea
                      name={`${LANGUAGES.HINDI}.benefits`}
                      label="Benefits"
                      placeholder="Enter benefits"
                      id={`${LANGUAGES.HINDI}.benefits`}
                      formik={formik}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Dropzone
                      label="Thumbnail"
                      text="Drag 'n' drop image here, or click to select image"
                      info="(Only 1 image file will be accepted)"
                      allow="image/png, image/jpeg, image/jpg"
                      type="image"
                      hideDropZoneOnPreview
                      preview
                      name={`${LANGUAGES.HINDI}.thumbnail`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Dropzone
                      label="Video"
                      text="Drag 'n' drop video here, or click to select video"
                      info="(Only 1 video file will be accepted)"
                      type="video"
                      allow="video/*"
                      preview
                      hideDropZoneOnPreview
                      name={`${LANGUAGES.HINDI}.video`}
                      formik={formik}
                      language={language}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <PoseTable
                      onBtnClick={() => {
                        setSelectedAsanaPose(null);
                        setPoseModal(true);
                      }}
                      rows={formik.values[LANGUAGES.HINDI].pose}
                      onDeletePose={onDeletePose}
                      onEditClickPose={onEditClickPose}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-group text-right">
              <Link
                to={PATHS.ASANA_MANAGEMENT}
                className="btn btn-light waves-effect waves-light mr-3"
              >
                Cancel
              </Link>
              <ButtonLoading
                className="btn-primary waves-effect waves-light w-15"
                type="submit"
                title={"Submit"}
                isLoading={loadingButton}
              />
            </div>
          </form>
        )}
      </CommonCard>
      <PoseModal
        show={poseModal}
        setShow={setPoseModal}
        formik={formik}
        name={`${language}.pose`}
        poseList={poseData.poseList}
        poseSearchPromiseOptions={poseSearchPromiseOptions}
        selectedAsanaPose={selectedAsanaPose}
        setSelectedAsanaPose={setSelectedAsanaPose}
      />
    </CommonLayout>
  );
};
