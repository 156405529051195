import { CourseManagementMap } from "./actions";

const initialState = {
  isLoading: false,
  courseList: {},
  refreshCourseList: true,
  selectedCourse: null,
  limit: 10,
  offset: 1,
  totalCount: 0,
  redirect: false,
  searchText: "",
  apiData: null,
  filters: {
    category: "",
    type: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CourseManagementMap.COURSE_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case CourseManagementMap.GET_ALL_COURSE: {
      return {
        ...state,
        courseList: action.payload.course,
        totalCount: action.payload.totalCount,
        refreshCourseList: false,
        redirect: false,
      };
    }
    case CourseManagementMap.CREATE_COURSE: {
      return {
        ...state,
        refreshCourseList: true,
        redirect: true,
      };
    }
    case CourseManagementMap.DELETE_COURSE: {
      return {
        ...state,
        refreshCourseList: true,
        selectedCourse: null,
      };
    }
    case CourseManagementMap.SET_SELECTED_COURSE: {
      return {
        ...state,
        selectedCourse: action.payload.selectedCourse,
        apiData: action.payload.apiData || null,
      };
    }
    case CourseManagementMap.COURSE_PAGE_CHANGE: {
      return {
        ...state,
        offset: action.payload.offset || state.offset,
        limit: action.payload.limit || state.limit,
        refreshCourseList: true,
      };
    }
    case CourseManagementMap.COURSE_SEARCH_CHANGE: {
      return {
        ...state,
        searchText: action.payload.searchText,
        offset: 1,
        refreshCourseList: true,
      };
    }
    case CourseManagementMap.COURSE_FILTER_CHANGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value,
        },
        offset: 1,
        refreshCourseList: true,
      };
    }
    case CourseManagementMap.COURSE_UPDATE_API_DATA: {
      return {
        ...state,
        apiData: {
          ...state.apiData,
          [action.payload.name]: action.payload.value
        },
      };
    }
    default:
      return { ...state };
  }
};
