import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AuthActions } from "../../app/modules/auth/redux/authAction";
import { UIActions } from "../../app/modules/ui/redux/action";
import { links, PAGE_TITLE } from "./links";
// import "./aside.scss"

export const AsideMenu = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { pageTitle } = useSelector((state) => state.ui, shallowEqual);

  const { user } = useSelector((state) => state.auth, shallowEqual);
  const location = useLocation();
  useEffect(() => {
    links.forEach((link) => {
      if (location.pathname.indexOf(link.route) !== -1) {
        dispatch(UIActions.setUIKey("pageTitle", link.name));
      }
    });
  }, [location.pathname]);

  const onPageChange = (page, url) => {
    if (page !== pageTitle) {
      dispatch(UIActions.setUIKey("pageTitle", page));
      history.push(url);
    }
  };

  const onLogout = () => {
    dispatch(AuthActions.logout());
  };

  return (
    <div id="wrapper">
      <div className="navbar-custom nav-light">
        <ul className="list-unstyled topnav-menu float-right mb-0">
          <li className="dropdown notification-list">
            <a
              className="nav-link dropdown-toggle nav-user mr-0 waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src={"/assets/images/account-circle-outline.png"}
                alt="user-image"
                className="rounded-circle"
              />
              <span className="pro-user-name ml-1">
                {user.name}
                {/* <i className="mdi mdi-chevron-down"></i> */}
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
              <div className="dropdown-header noti-title">
                <h6 className="text-overflow m-0">Welcome</h6>
              </div>

              {/* <Link
                to="/profile-settings"
                href="#"
                className="dropdown-item notify-item"
              >
                <i className="fe-settings"></i>
                <span>Profile Settings</span>
              </Link> */}

              <a
                href="#"
                className="dropdown-item notify-item"
                onClick={onLogout}
              >
                <i className="fe-log-out"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>

        <div className="logo-box">
          <a href="#" className="logo logo-dark text-center">
            <span className="logo-lg">
              <img src="/assets/images/zoga-logo.jpeg" alt="" height="16" />
            </span>
            <span className="logo-sm">
              <img src="/assets/images/zoga-logo.jpeg" alt="" height="24" />
            </span>
          </a>
          <a href="#" className="logo logo-light text-center">
            <span className="logo-lg">
              <img src="/assets/images/zoga-logo.jpeg" alt="" height="16" />
            </span>
            <span className="logo-sm">
              <img src="/assets/images/zoga-logo.jpeg" alt="" height="24" />
            </span>
          </a>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left mb-0">
          <li>
            <button className="button-menu-mobile disable-btn waves-effect">
              <i className="fe-menu"></i>
            </button>
          </li>

          <li>
            <h4 className="page-title-main">{pageTitle}</h4>
          </li>
        </ul>
      </div>

      <div className="left-side-menu">
        <div className="slimscroll-menu">
          <div id="sidebar-menu mt-3">
            <ul className="metismenu" id="side-menu">
              {links.map((link) => {
                if (!link.hideAsideMenu) {
                  if (
                    link.name === PAGE_TITLE.USER_MANAGEMENT &&
                    user.role !== "system_admin"
                  ) {
                    return null;
                  }
                  return (
                    <li
                      key={link.name}
                      style={{
                        margin: "1rem 0.8rem",
                        paddingBottom: "1rem",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      <a
                        className={`cursor-pointer${
                          pageTitle === link.name ? " active" : ""
                        }`}
                        onClick={() => onPageChange(link.name, link.route)}
                      >
                        <i className={`mdi ${link.icon}`}></i>
                        <span> {link.name} </span>
                      </a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};
