import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { PATHS } from "../../../components/aside-menu/links";
import { IconButton } from "../../../components/button/common-button";
import { CommonCard } from "../../../components/card/common-card";
import { CommonLayout } from "../../../components/layout/common-layout";
import { ButtonLoading } from "../../../components/button";
import BasicPagination from "../../../components/pagination";
import { usePoseHook } from "../../../hooks/pose-data-hook";
import { SearchInput } from "../../../components/search/search";

const columns = [
  {
    id: "id",
    label: "ID",
    className: "text-center align-middle",
  },
  {
    id: "poseName",
    label: "Pose Name",
    className: "text-center align-middle",
  },
];

const PoseManagement = () => {
  const [showDelete, setShowDelete] = useState(false);
  const history = useHistory();
  const onAdd = () => {
    history.push(PATHS.POSE_MANAGEMENT + PATHS.CREATE_POSE);
  };

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  const {
    isLoading,
    poseList,
    currentStartCount,
    currentEndCount,
    limit,
    offset,
    totalCount,
    setSelectedPose,
    deletePose,
    onLimitChange,
    onPageChange,
    onSearch,
  } = usePoseHook();

  const onDeleteToggle = (pose, value) => {
    setSelectedPose(pose);
    setShowDelete(value);
  };

  const onDeletePose = async () => {
    await deletePose();
    setShowDelete(false);
  };
  const onEdit = (pose) => {
    pose.id && history.push(`${PATHS.POSE_MANAGEMENT}/${pose.id}`);
  };

  return (
    <CommonLayout>
      <CommonCard
        header={
          <>
            <SearchInput onSearchInput={onSearch} /> 
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light w-10"
              onClick={onAdd}
            >
              Add Pose
            </button>
          </>
        }
        headerClassName="justify-content-end mr-3"
      >
        <table id="datatable" className="table dt-responsive nowrap">
          <thead>
            <tr>
              <th className="text-center align-middle">ID</th>
              <th className="text-center align-middle">Pose Name</th>
              <th className="text-center align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : poseList?.length > 0 ? (
              poseList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">{item.id}</td>
                    <td className="text-center align-middle">
                      {item.poseName}
                    </td>
                    <td className="text-center align-middle">
                      <IconButton
                        color="warning"
                        icon={<i className="fe-edit-1"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onEdit(item)}
                      />
                      <IconButton
                        color="danger"
                        icon={<i className="fe-trash-2"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onDeleteToggle(item, true)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            Showing {currentStartCount} to {currentEndCount} of {totalCount}{" "}
            records
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mr-3 mb-2">
              Show{" "}
              <select
                value={limit}
                onChange={(e) => onLimitChange(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>{" "}
              entries
            </div>
            <BasicPagination
              totalRecords={totalCount}
              limit={limit}
              batch={offset}
              onBatchChange={onPageChange}
            />
          </div>
        </div>
      </CommonCard>
      {/** Delete pose confirmation modal */}

      <Modal show={showDelete} onHide={() => onDeleteToggle(null, false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete pose</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure to delete pose?</h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light waves-effect"
            onClick={() => onDeleteToggle(null, false)}
          >
            Keep
          </button>
          <ButtonLoading
            className={`btn-danger waves-effect waves-light w-25`}
            onClick={onDeletePose}
            title="Yes, Delete"
            isLoading={loadingButton}
          />
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
};

export default PoseManagement;
