import React, { useState } from "react";
import Profile from "./pages/profile";
import ChangePassword from "./pages/change-password";

const ProfileSettings = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <div className="content-page"> 
        <div className="content">
          <div className="container-fluid">
            <div className="card-box w-75 mx-auto">
                <div className="card-header bg-transparent">
                  <ul className="nav nav-tabs">
                    <li className="nav-item" onClick={() => setTabIndex(0)}>
                      <a
                        className={`nav-link ${tabIndex === 0 ? "active" : ""}`}
                      >
                        Profile Setting
                      </a>
                    </li>
                    <li className="nav-item" onClick={() => setTabIndex(1)}>
                      <a
                        className={`nav-link ${tabIndex === 1 ? "active" : ""}`}
                      >
                        Change Password
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  {tabIndex === 0 ? <Profile /> : null}
                  {tabIndex === 1 ? <ChangePassword /> : null}
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileSettings;
