import React from "react";
import { CONTENT_LANGUAGES } from "../../utils/constants";

export const LanguageButton = ({
  language,
  onLanguageChange,
  errorBorder = false,
}) => {
  const onClick = (value) => () => onLanguageChange(value);
  return (
    <div className="form-group">
      <label style={{ marginTop: "2px" }}>Language</label>
      <div>
        {CONTENT_LANGUAGES.map((lang) => (
          <button
            key={lang}
            className={`btn waves-effect waves-light mr-2 w-25 text-capitalize ${
              language === lang ? "btn-secondary" : "btn-light"
            } ${
              errorBorder && language !== lang ? "border border-danger" : ""
            }`}
            type="button"
            onClick={onClick(lang)}
          >
            {lang}
          </button>
        ))}
      </div>
    </div>
  );
};
