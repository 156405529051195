import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ErrorPage1 from './pages/error-page1';
import ErrorPage2 from './pages/error-page2';

const ErrorsPage = () => {
    return (
        <Switch>
            <Redirect from='/error' exact={true} to='/error/error-v1' />
            <Route path='/error/error-v1' component={ErrorPage1} />
            <Route path='/error/error-v2' component={ErrorPage2} />
        </Switch>
    );
}

export default ErrorsPage