import { AsanaManagementActions } from "./action";
import {
  get,
  post,
  update,
  remove,
  getUploadSignedUrl,
  uploadSigned,
  getDownloadSignedUrl,
} from "../../../../api";
import { toast } from "react-toastify";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config";
import { setIndex } from "../../../../utils/functions";

export const getAllAsanaAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(AsanaManagementActions.setAsanaListLoading(true));
      const {
        limit,
        offset,
        searchText,
        filters: { difficulty, type },
      } = getState().asanaManagement;
      let url = `${apiService.asanaUrl}/list?limit=${limit}&offset=${offset}`;
      if (searchText) {
        url += `&searchText=${searchText}`;
      }
      if (difficulty !== "") {
        url += `&difficulty=${difficulty}`;
      }
      if (type) {
        url += `&type=${type}`;
      }
      const { data } = await get(url);
      if (data && !data.isError) {
        dispatch(
          AsanaManagementActions.getAllAsana({
            asana: data?.data?.asanaDocs || [],
            totalCount: data?.data?.total || 0,
          })
        );
        return dispatch(AsanaManagementActions.setAsanaListLoading(false));
      }
      dispatch(AsanaManagementActions.setAsanaListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(AsanaManagementActions.setAsanaListLoading(false));
      return toast.error(error.message);
    }
  };
};

export const getAsanaDetailsAsync = (asanaId) => {
  return async (dispatch) => {
    try {
      dispatch(AsanaManagementActions.setAsanaListLoading(true));
      const { data } = await get(`${apiService.asanaUrl}?id=${asanaId}`);
      if (data && !data.isError) {
        let apiData = JSON.parse(JSON.stringify(data.data));
        // await getAsanaDownloadUrl(data.data);
        dispatch(
          AsanaManagementActions.setSelectedAsana({
            selectedAsana: data?.data || null,
            apiData,
          })
        );
        return dispatch(AsanaManagementActions.setAsanaListLoading(false));
      }
      dispatch(AsanaManagementActions.setAsanaListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(AsanaManagementActions.setAsanaListLoading(false));
      return toast.error(error.message);
    }
  };
};

const getAsanaDownloadUrl = async (asanaData) => {
  let payload = [];
  let en = asanaData.details.en,
    hi = asanaData.details.hi;
  if (en.thumbnailUrl) {
    payload.push({
      path: en.thumbnailUrl,
      refId: "thumbnailUrl",
      language: "en",
    });
  }
  if (en.videoUrl) {
    payload.push({
      path: en.videoUrl,
      refId: "videoUrl",
      language: "en",
    });
  }
  if (hi.thumbnailUrl) {
    payload.push({
      path: hi.thumbnailUrl,
      refId: "thumbnailUrl",
      language: "hi",
    });
  }
  if (hi.videoUrl) {
    payload.push({
      path: hi.videoUrl,
      refId: "videoUrl",
      language: "hi",
    });
  }
  if (payload.length) {
    let { data } = await getDownloadSignedUrl({ filePaths: payload });
    if (!data?.isError) {
      data.data.map(
        (item) =>
          (asanaData.details[item.language][item.refId] = item.downloadUrl)
      );
    }
  }
};

const getAsanaUploadUrl = (files) => {
  let englishFilePromise;
  if (files.english) {
    englishFilePromise = getUploadSignedUrl(files.english);
  }
  let hindiFilePromise;
  if (files.hindi) {
    hindiFilePromise = getUploadSignedUrl(files.hindi);
  }
  return Promise.all([englishFilePromise, hindiFilePromise]);
};

const updateAsanaDetailsUploadUrlData = async (
  data,
  files,
  [englishUrlsResp, hindiUrlsResp]
) => {
  let englishData = englishUrlsResp?.data?.data || [];
  let hindiData = hindiUrlsResp?.data?.data || [];
  let enPromise, hiPromise;
  if (englishData.length) {
    enPromise = Promise.all(
      englishData.map((enData, i) => { 
        setIndex(data.details.en, enData.uploadedFilePath.refId, `${enData.uploadedFilePath.path}`);
        return uploadSigned(enData.signedUrl, files.english[i]);
      })
    );
  }
  if (hindiData.length) {
    hiPromise = Promise.all(
      hindiData.map((hiData, i) => {
        setIndex(data.details.hi, hiData.uploadedFilePath.refId, `${hiData.uploadedFilePath.path}`);
        return uploadSigned(hiData.signedUrl, files.hindi[i]);
      })
    );
  }
  await Promise.all([enPromise, hiPromise]);
};

export const createAsanaAsync = (data, { filesData, files }) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getAsanaUploadUrl(filesData);
      await updateAsanaDetailsUploadUrlData(data, files, filesUrlData);
      let resp = await post(`${apiService.asanaUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(AsanaManagementActions.createAsana());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(resp?.data?.message || "Asana create successfull");
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const updateAsanaAsync = (data, { filesData, files }) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getAsanaUploadUrl(filesData);
      await updateAsanaDetailsUploadUrlData(data, files, filesUrlData);
      let resp = await update(`${apiService.asanaUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(resp?.data?.message || "Asana update successfull");
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};
export const deleteAsanaAsync = (id) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await remove(apiService.asanaUrl, { id });
      if (!resp?.data?.isError) {
        dispatch(AsanaManagementActions.deleteAsana());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(resp?.data?.message || "Delete asana successfull");
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const searchAsanaAsync = async (searchText) => {
  try {
    let url = `${apiService.asanaUrl}/list?limit=50&offset=1`;
    if (searchText) {
      url += `&searchText=${searchText}`;
    }
    const { data } = await get(url);
    if (data && !data.isError) {
      return data?.data?.asanaDocs || [];
    }
    return [];
  } catch (error) {
    return [];
  }
};
