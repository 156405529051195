import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import { rootReducer } from './rootReducer';

let enhancers = [];
const middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  let logger = createLogger({
    collapsed: true,
  });
  middleware.push(logger);
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer,
  {},
  composedEnhancers
);

export const persistor = persistStore(store);

export default store;
