import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { PATHS } from "../../../components/aside-menu/links";
import { IconButton } from "../../../components/button/common-button";
import { CommonCard } from "../../../components/card/common-card";
import { CommonLayout } from "../../../components/layout/common-layout";
import BasicPagination from "../../../components/pagination";
import { useAsanaHook } from "../../../hooks/asana-data-hook";
import { shallowEqual, useSelector } from "react-redux";
import { ButtonLoading } from "../../../components/button";
import { SearchInput } from "../../../components/search/search";
import { difficultyOptions, positionOptions } from "./config";

const AsanaManagement = () => {
  const history = useHistory();
  const [showDelete, setShowDelete] = useState(false);

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  const {
    setSelectedAsana,
    isLoading,
    asanaList,
    limit,
    offset,
    totalCount,
    currentEndCount,
    currentStartCount,
    onLimitChange,
    onPageChange,
    deleteAsana,
    onSearch,
    onFilter,
  } = useAsanaHook();

  const onAdd = () => {
    history.push(PATHS.ASANA_MANAGEMENT + PATHS.CREATE_ASANA);
  };

  const onDeleteToggle = (asana, value) => {
    setSelectedAsana(asana);
    setShowDelete(value);
  };

  const onDeleteAsana = async () => {
    await deleteAsana();
    setShowDelete(false);
  };

  const onEdit = (asana) => {
    asana.id && history.push(`${PATHS.ASANA_MANAGEMENT}/${asana.id}`);
  };

  return (
    <CommonLayout>
      <CommonCard
        header={
          <>
            <Select
              options={[...positionOptions]}
              onChange={(e) => {
                if (!e) {
                  return onFilter("type", "");
                }
                onFilter("type", e.value);
              }}
              placeholder={"Type"}
              className="mr-3 w-10"
              isClearable
            />
            <Select
              options={[...difficultyOptions]}
              onChange={(e) => {
                if (!e) {
                  return onFilter("difficulty", "");
                }
                onFilter("difficulty", e.value);
              }}
              placeholder={"Difficulty"}
              className="mr-3 w-10"
              isClearable
            />
            <SearchInput onSearchInput={onSearch} />
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light w-10"
              onClick={onAdd}
            >
              Add Asana
            </button>
          </>
        }
        headerClassName="justify-content-end mr-3"
      >
        <table id="datatable" className="table dt-responsive nowrap">
          <thead>
            <tr>
              <th className="text-center align-middle">Name EN</th>
              <th className="text-center align-middle">Name HI</th>
              <th className="text-center align-middle">Difficulty</th>
              <th className="text-center align-middle">Category</th>
              <th className="text-center align-middle">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : asanaList?.length > 0 ? (
              asanaList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {item?.details?.en?.name}
                    </td>
                    <td className="text-center align-middle">
                      {item?.details?.hi?.name}
                    </td>
                    <td className="text-center align-middle">
                      {item?.difficulty}
                    </td>
                    <td className="text-center align-middle">{item?.type}</td>
                    <td className="text-center align-middle">
                      <IconButton
                        color="warning"
                        icon={<i className="fe-edit-1"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onEdit(item)}
                      />
                      <IconButton
                        color="danger"
                        icon={<i className="fe-trash-2"></i>}
                        className="btn-icon py-0 px-1 ml-2"
                        onClick={() => onDeleteToggle(item, true)}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            Showing {currentStartCount} to {currentEndCount} of {totalCount}{" "}
            records
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mr-3 mb-2">
              Show{" "}
              <select
                value={limit}
                onChange={(e) => onLimitChange(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>{" "}
              entries
            </div>
            <BasicPagination
              totalRecords={totalCount}
              limit={limit}
              batch={offset}
              onBatchChange={onPageChange}
            />
          </div>
        </div>
      </CommonCard>

      {/** Delete asana confirmation modal */}
      <Modal show={showDelete} onHide={() => onDeleteToggle(null, false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete asana</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure to delete asana?</h4>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light waves-effect"
            onClick={() => onDeleteToggle(null, false)}
          >
            Keep
          </button>
          <ButtonLoading
            className={`btn-danger waves-effect waves-light w-25`}
            onClick={onDeleteAsana}
            title="Yes, Delete"
            isLoading={loadingButton}
          />
        </Modal.Footer>
      </Modal>
    </CommonLayout>
  );
};

export default AsanaManagement;
