import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { PoseManagementActions } from "../app/modules/pose/redux/action";
import {
  deletePoseAsync,
  getAllPoseAsync,
} from "../app/modules/pose/redux/api";

export const usePoseHook = () => {
  const dispatch = useDispatch();

  const {
    isLoading,
    poseList,
    refreshPoseList,
    selectedPose,
    limit,
    offset,
    totalCount,
  } = useSelector((state) => state.poseManagement, shallowEqual);

  const currentStartCount = offset * limit - (limit - 1);
  const currentEndCount =
    limit * offset < totalCount ? limit * offset : totalCount;

  useEffect(() => {
    if (refreshPoseList) {
      dispatch(getAllPoseAsync());
    }
  }, [refreshPoseList]);

  useEffect(() => {
    dispatch(PoseManagementActions.setSelectedPose({ selectedPose: null }));
  }, []);

  const setSelectedPose = (pose) => {
    dispatch(PoseManagementActions.setSelectedPose({ selectedPose: pose }));
  };

  const deletePose = () => {
    return dispatch(deletePoseAsync(selectedPose.id));
  };

  const onLimitChange = (value) => {
    dispatch(
      PoseManagementActions.setPosePagination({
        offset: 1,
        limit: parseInt(value),
      })
    );
  };

  const onPageChange = (page) => {
    dispatch(PoseManagementActions.setPosePagination({ offset: page, limit }));
  };

  const onSearch = async (text) => {
    if (text.length > 1) {
      dispatch(PoseManagementActions.setPoseSearch({ searchText: text }));
    }
    if (!text) {
      dispatch(PoseManagementActions.setPoseSearch({ searchText: "" }));
    }
  };

  return {
    isLoading,
    poseList,
    refreshPoseList,
    selectedPose,
    limit,
    offset,
    totalCount,
    currentStartCount,
    currentEndCount,
    setSelectedPose,
    deletePose,
    onLimitChange,
    onPageChange,
    onSearch,
  };
};
