import { BannerManagementMap } from "./action";

const initialState = {
  isLoading: false,
  bannerList: {},
  refreshBannerList: true,
  selectedBanner: null,
  limit: 10,
  offset: 1,
  totalCount: 0,
  redirect: false,
  searchText: "",
  apiData: null,
  filters: {
    section: "",
    type: "",
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BannerManagementMap.BANNER_LIST_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case BannerManagementMap.GET_ALL_BANNER: {
      return {
        ...state,
        bannerList: action.payload.banner,
        totalCount: action.payload.totalCount,
        refreshBannerList: false,
        redirect: false,
      };
    }
    case BannerManagementMap.CREATE_BANNER: {
      return {
        ...state,
        refreshBannerList: true,
        redirect: true,
      };
    }
    case BannerManagementMap.DELETE_BANNER: {
      return {
        ...state,
        refreshBannerList: true,
        selectedBanner: null,
      };
    }
    case BannerManagementMap.SET_SELECTED_BANNER: {
      return {
        ...state,
        selectedBanner: action.payload.selectedBanner,
        apiData: action.payload.apiData,
      };
    }
    case BannerManagementMap.BANNER_PAGE_CHANGE: {
      return {
        ...state,
        offset: action.payload.offset || state.offset,
        limit: action.payload.limit || state.limit,
        refreshBannerList: true,
      };
    }
    case BannerManagementMap.BANNER_SEARCH_CHANGE: {
      return {
        ...state,
        searchText: action.payload.searchText,
        offset: 1,
        refreshBannerList: true,
      };
    }
    case BannerManagementMap.BANNER_FILTER_CHANGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.value,
        },
        offset: 1,
        refreshBannerList: true,
      };
    }
    default:
      return { ...state };
  }
};
