import React from "react";
import BasicPagination from "../pagination";

const defaultData = {
  columns: [],
  rows: [],
  actions: { active: false, className: "", lists: [] },
  pagination: {
    active: false,
    offset: 0,
    limit: 1,
    totalRecords: 0,
    onLimitChange: () => null,
    onPageChange: () => null,
  },
  tableId: "dataTable",
};

export const CommonTable = ({ data = defaultData, isLoading }) => {
  return (
    <>
      <table id={data.tableId} className="table dt-responsive nowrap">
        <thead>
          <tr>
            {data?.columns?.length > 0 &&
              data?.columns?.map((column, idx) => (
                <th className="text-center align-middle" key={idx}>
                  {column.label}
                </th>
              ))}
            {data?.actions?.active && (
              <th className="text-center align-middle">
                {data.actions.label || "Action"}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={8} className="text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
          ) : data?.rows?.length > 0 ? (
            data?.rows?.map((item, key) => {
              return (
                <tr key={key}>
                  {Object.keys(item).map((_, index) => {
                    return (
                      <React.Fragment key={index}>
                        {data?.columns?.[index]?.custom ? (
                          <>{data?.columns?.[index]?.custom}</>
                        ) : (
                          item?.[data?.columns?.[index]?.id] && (
                            <td className={data?.columns?.[index]?.className}>
                              {item?.[data?.columns?.[index]?.id]}
                            </td>
                          )
                        )}
                      </React.Fragment>
                    );
                  })}
                  {data.actions.active && (
                    <td className={data.actions.className || ""}>
                      {data.actions.lists.map((item) => item)}
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8} className="text-center">
                No Record Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data.pagination.active && (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            Showing{" "}
            {data.pagination.offset * data.pagination.limit -
              (data.pagination.limit - 1)}{" "}
            to{" "}
            {data.pagination.limit * data.pagination.offset <
            data.pagination.totalRecords
              ? data.pagination.limit * data.pagination.offset
              : data.pagination.totalRecords}{" "}
            of {data.pagination.totalRecords} records
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mr-3 mb-2">
              Show{" "}
              <select
                value={data.pagination.limit}
                onChange={data.pagination.onLimitChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
              </select>{" "}
              entries
            </div>
            <BasicPagination
              totalRecords={data.pagination.totalRecords}
              limit={data.pagination.limit}
              batch={data.pagination.offset}
              onBatchChange={data.pagination.onPageChange}
            />
          </div>
        </div>
      )}
    </>
  );
};
