import { CourseManagementActions } from "./actions";
import {
  get,
  post,
  update,
  remove,
  getUploadSignedUrl,
  uploadSigned,
  getDownloadSignedUrl,
} from "../../../../api";
import { toast } from "react-toastify";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config";
import { setIndex } from "../../../../utils/functions";

export const getAllCourseAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(CourseManagementActions.setCourseListLoading(true));
      const {
        limit,
        offset,
        searchText,
        filters: { category, type },
      } = getState().courseManagement;
      let payload = {
        limit,
        offset,
      };
      if (searchText) {
        payload.searchText = searchText;
      }
      if (category) {
        payload.category = category;
      }
      if (type) {
        payload.type = type;
      }
      const { data } = await post(`${apiService.courseUrl}/list`, payload);
      if (data && !data.isError) {
        dispatch(
          CourseManagementActions.getAllCourse({
            course: data?.data?.courseDocs || [],
            totalCount: data?.data?.total || 0,
          })
        );
        return dispatch(CourseManagementActions.setCourseListLoading(false));
      }
      dispatch(CourseManagementActions.setCourseListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(CourseManagementActions.setCourseListLoading(false));
      return toast.error(error.message);
    }
  };
};

const getCourseUploadUrl = (files) => {
  let englishFilePromise;
  if (files.english) {
    englishFilePromise = getUploadSignedUrl(files.english);
  }
  let hindiFilePromise;
  if (files.hindi) {
    hindiFilePromise = getUploadSignedUrl(files.hindi);
  }
  return Promise.all([englishFilePromise, hindiFilePromise]);
};

const updateCourseDetailsUploadUrlData = async (
  data,
  files,
  [englishUrlsResp, hindiUrlsResp],
  key
) => {
  let englishData = englishUrlsResp?.data?.data || [];
  let hindiData = hindiUrlsResp?.data?.data || [];
  let enPromise, hiPromise;
  if (englishData.length) {
    enPromise = Promise.all(
      englishData.map((enData, i) => {
        setIndex(
          data[key].en,
          enData.uploadedFilePath.refId,
          enData.uploadedFilePath.path
        );
        // let refArr = enData.uploadedFilePath.refId.split(".");
        // if (refArr.length === 2) {
        //   data.details.en[refArr[0]][
        //     refArr[1]
        //   ] = `${enData.uploadedFilePath.path}`;
        // } else {
        //   data.details.en[
        //     enData.uploadedFilePath.refId
        //   ] = `${enData.uploadedFilePath.path}`;
        // }
        return uploadSigned(enData.signedUrl, files.english[i]);
      })
    );
  }
  if (hindiData.length) {
    hiPromise = Promise.all(
      hindiData.map((hiData, i) => {
        setIndex(
          data[key].hi,
          hiData.uploadedFilePath.refId,
          hiData.uploadedFilePath.path
        );
        // let refArr = hiData.uploadedFilePath.refId.split(".");
        // if (refArr.length === 2) {
        //   data.details.hi[refArr[0]][
        //     refArr[1]
        //   ] = `${hiData.uploadedFilePath.path}`;
        // } else {
        //   data.details.hi[
        //     hiData.uploadedFilePath.refId
        //   ] = `${hiData.uploadedFilePath.path}`;
        // }
        return uploadSigned(hiData.signedUrl, files.hindi[i]);
      })
    );
  }
  await Promise.all([enPromise, hiPromise]);
};

export const createCourseAsync = (data, { filesData, files }, cb) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getCourseUploadUrl(filesData);
      await updateCourseDetailsUploadUrlData(
        data,
        files,
        filesUrlData,
        "details"
      );
      let resp = await post(`${apiService.courseUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(CourseManagementActions.createCourse());
        dispatch(UIActions.setUIKey("loadingButton", false));
        if (typeof cb === "function") cb();
        return toast.success(
          resp?.data?.message || "Course create successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const getCourseDetailsAsync = (courseId, day, isMulti, initData) => {
  return async (dispatch) => {
    try {
      dispatch(CourseManagementActions.setCourseListLoading(true));
      const url = isMulti
        ? `${apiService.courseUrl}?id=${courseId}&day=${day}`
        : `${apiService.courseUrl}?id=${courseId}`;
      const { data } = await get(url);
      if (data && !data.isError) {
        let apiData = JSON.parse(JSON.stringify(data.data));
        // await getCourseDownloadUrl(data.data);
        dispatch(
          CourseManagementActions.setSelectedCourse({
            selectedCourse: data?.data || null,
            apiData,
          })
        );
        return dispatch(CourseManagementActions.setCourseListLoading(false));
      }
      dispatch(CourseManagementActions.setCourseListLoading(false));
      if (isMulti && day && initData) {
        let apiData = JSON.parse(
          JSON.stringify({ ...initData, isNewDay: true })
        );
        // await getCourseDownloadUrl(data.data);
        dispatch(
          CourseManagementActions.setSelectedCourse({
            selectedCourse: initData || null,
            apiData,
          })
        );
      } else {
        return toast.error("Error while fetching data.Please try again later.");
      }
    } catch (error) {
      console.log(error);
      dispatch(CourseManagementActions.setCourseListLoading(false));
      return toast.error(error.message);
    }
  };
};

const getCourseDownloadUrl = async (courseData) => {
  let payload = [];
  let en = courseData.details.en,
    hi = courseData.details.hi;
  if (en.thumbnailUrl) {
    payload.push({
      path: en.thumbnailUrl,
      refId: "thumbnailUrl",
      language: "en",
    });
  }
  if (en.videoUrls) {
    for (let key in en.videoUrls) {
      let video = en.videoUrls[key];
      if (video) {
        payload.push({
          path: video,
          refId: `videoUrls.${key}`,
          language: "en",
        });
      }
    }
  }
  if (en.audioUrl?.["320"]) {
    payload.push({
      path: en.audioUrl?.["320"],
      refId: "audioUrl.320",
      language: "en",
    });
  }
  if (hi.thumbnailUrl) {
    payload.push({
      path: hi.thumbnailUrl,
      refId: "thumbnailUrl",
      language: "hi",
    });
  }
  if (hi.videoUrls) {
    for (let key in hi.videoUrls) {
      let video = hi.videoUrls[key];
      if (video) {
        payload.push({
          path: video,
          refId: `videoUrls.${key}`,
          language: "hi",
        });
      }
    }
  }
  if (hi.audioUrl?.["320"]) {
    payload.push({
      path: hi.audioUrl?.["320"],
      refId: "audioUrl.320",
      language: "hi",
    });
  }
  if (payload.length) {
    let { data } = await getDownloadSignedUrl({ filePaths: payload });
    if (!data?.isError) {
      data.data.map((item) => {
        let refArr = item.refId.split(".");
        if (refArr.length === 2) {
          courseData.details[item.language][refArr[0]][refArr[1]] =
            item.downloadUrl;
        } else {
          courseData.details[item.language][refArr[0]] = item.downloadUrl;
        }
      });
    }
  }
};

export const updateCourseAsync = (data, { filesData, files }, cb) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getCourseUploadUrl(filesData);
      await updateCourseDetailsUploadUrlData(
        data,
        files,
        filesUrlData,
        "details"
      );
      let resp = await update(`${apiService.courseUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(UIActions.setUIKey("loadingButton", false));
        if (typeof cb === "function") cb();
        return toast.success(
          resp?.data?.message || "Course update successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const deleteCourseAsync = (id) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await remove(apiService.courseUrl, { id });
      if (!resp?.data?.isError) {
        dispatch(CourseManagementActions.deleteCourse());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(
          resp?.data?.message || "Delete course successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

/**
 * Multi day course async actions
 */

export const createMultiCourseAsync = (
  data,
  { filesData, files },
  daysData,
  daysFilesDetails,
  cb
) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getCourseUploadUrl(filesData);
      await updateCourseDetailsUploadUrlData(
        data,
        files,
        filesUrlData,
        "details"
      );
      let resp = await post(`${apiService.multiCourseUrl}`, data);
      if (!resp?.data?.isError && resp?.data?.data) {
        await dispatch(
          createDaysAsync(daysData, daysFilesDetails, resp?.data?.data, cb)
        );
        dispatch(CourseManagementActions.createCourse());
        return dispatch(UIActions.setUIKey("loadingButton", false));
        // toast.success(resp?.data?.message || "Course create successfull");
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while creating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const updateMultiCourseAsync = (data, { filesData, files }, cb) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getCourseUploadUrl(filesData);
      await updateCourseDetailsUploadUrlData(
        data,
        files,
        filesUrlData,
        "details"
      );
      let resp = await update(`${apiService.multiCourseUrl}`, data);
      if (!resp?.data?.isError && resp?.data?.data) {
        dispatch(UIActions.setUIKey("loadingButton", false));
        if (typeof cb === "function") cb();
        return toast.success(
          resp?.data?.message || "Course update successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const createDaysAsync =
  (data, { filesData, files }, courseId, cb) =>
  async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getCourseUploadUrl(filesData);
      await updateCourseDetailsUploadUrlData(
        data,
        files,
        filesUrlData,
        "daysDetails"
      );
      let resp = await post(
        `${apiService.multiCourseUrl}/${apiService.addDayUrl}`,
        { ...data, courseId }
      );
      if (!resp?.data?.isError) {
        if (typeof cb === "function") {
          cb();
        }
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(
          resp?.data?.message || "Course create successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while creating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      // return toast.error(error.message);
      return;
    }
  };

export const updateDaysAsync =
  (data, { filesData, files }, courseId, cb) =>
  async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getCourseUploadUrl(filesData);
      await updateCourseDetailsUploadUrlData(
        data,
        files,
        filesUrlData,
        "daysDetails"
      );
      let resp = await update(
        `${apiService.multiCourseUrl}/${apiService.updateDayUrl}`,
        { ...data, courseId }
      );
      if (!resp?.data?.isError) {
        if (typeof cb === "function") {
          cb();
        }
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(
          resp?.data?.message || "Course update successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      // return toast.error(error.message);
      return;
    }
  };

export const deleteDaysAsync = (data, cb) => async (dispatch) => {
  try {
    dispatch(UIActions.setUIKey("loadingButton", true));
    let resp = await remove(
      `${apiService.multiCourseUrl}/${apiService.removeDayUrl}`,
      data
    );
    if (!resp?.data?.isError) {
      if (typeof cb === "function") {
        cb();
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.success(resp?.data?.message || "Course delete successfull");
    }
    dispatch(UIActions.setUIKey("loadingButton", false));
    return toast.error(
      resp?.data?.message || "Error while deleting. Please try again later."
    );
  } catch (error) {
    console.log(error);
    dispatch(UIActions.setUIKey("loadingButton", false));
    // return toast.error(error.message);
    return;
  }
};

export const searchCourseAsync = async (searchText) => {
  try {
    let url = `${apiService.courseUrl}/list`;
    const { data } = await post(url, {
      limit: 50,
      offset: 1,
      searchText,
    });
    if (data && !data.isError) {
      return data?.data?.courseDocs || [];
    }
    return [];
  } catch (error) {
    return [];
  }
};
