import React from "react";

export const ButtonLoading = ({ title, type="button", isLoading, onClick = null, className }) => {
  return (
    <button className={`btn ${className}`} type={type} onClick={onClick} disabled={isLoading}>
      {" "}
      {isLoading ? (
        <div
          className="spinner-border spinner-border-sm text-custom"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        title
      )}{" "}
    </button>
  );
};
