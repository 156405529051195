export const PoseManagementMap = {
  GET_ALL_POSE: "GET_ALL_POSE",
  CREATE_POSE: "CREATE_POSE",
  UPDATE_POSE: "UPDATE_POSE",
  DELETE_POSE: "DELETE_POSE",
  SET_SELECTED_POSE: "SET_SELECTED_POSE",
  POSE_PAGE_CHANGE: "POSE_PAGE_CHANGE",
  POSE_SEARCH_CHANGE: "POSE_SEARCH_CHANGE",
  POSE_LIST_LOADING: "POSE_LIST_LOADING",
};

export const PoseManagementActions = {
  getAllPose: (data) => ({
    type: PoseManagementMap.GET_ALL_POSE,
    payload: data,
  }),

  createPose: (data) => ({
    type: PoseManagementMap.CREATE_POSE,
    payload: data,
  }),

  updatePose: (data) => ({
    type: PoseManagementMap.UPDATE_POSE,
    payload: data,
  }),

  deletePose: (data) => ({
    type: PoseManagementMap.DELETE_POSE,
    payload: data,
  }),

  setSelectedPose: (data) => ({
    type: PoseManagementMap.SET_SELECTED_POSE,
    payload: data,
  }),

  setPosePagination: (data) => ({
    type: PoseManagementMap.POSE_PAGE_CHANGE,
    payload: data,
  }),
  setPoseSearch: (data) => ({
    type: PoseManagementMap.POSE_SEARCH_CHANGE,
    payload: data,
  }),

  setPoseListLoading: (data) => ({
    type: PoseManagementMap.POSE_LIST_LOADING,
    payload: data,
  }),
};
