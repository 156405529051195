import { UIMap } from "./action";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  storage,
  key: "v706-demo1-ui",
  whitelist: ["pageTitle"],
};

const initialState = {
  pageTitle: "",
  loader: false,
  loadingButton: false,
};

export const reducer = persistReducer(
  persistConfig,
  (state = initialState, action) => {
    switch (action.type) {
      case UIMap.SET_UI_KEY: {
        return {
          ...state,
          [action.key]: action.payload,
        };
      }
      default:
        return { ...state };
    }
  }
);
