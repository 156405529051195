import { DashboardActions } from "./action";
import { get } from "../../../../api";

export const getDashboardUserCountAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(DashboardActions.dashboardLoading(true));
      const { data } = await get(`user/count`);
      if (!data.isError) {
        return dispatch(
          DashboardActions.getDashboardUserCount(data.data)
        );
      }
      dispatch(DashboardActions.dashboardLoading(false));
    } catch (error) {
      dispatch(DashboardActions.dashboardLoading(false));
    }
  };
};
