import * as Yup from "yup";
import { apiService } from "../../../../api/config";
import { generateOriginalUrl, getFileName } from "../../../../utils/functions";
import { LANGUAGES } from "../../../../utils/constants";

export const options = [
  { value: "chocolate", label: "chocolate" },
  { value: "strawberry", label: "strawberry" },
  { value: "vanilla", label: "vanilla" },
];
export const difficultyOptions = [
  { value: 0, label: "Easy" },
  { value: 1, label: "Medium" },
  { value: 2, label: "Hard" },
];
export const positionOptions = [
  { value: "all", label: "All" },
  { value: "standing", label: "Standing" },
  { value: "sitting", label: "Sitting" },
  { value: "prone", label: "Prone" },
  { value: "supine", label: "Supine" },
  { value: "balancing", label: "Balancing" },
  { value: "twisting", label: "Twisting" },
  { value: "forward_bend", label: "Forward Bend" },
  { value: "back_bend", label: "Back Bend" },
  { value: "inversion", label: "Inversion" },
];

export const qualityOptions = [
  { value: "480p", label: "480p" },
  { value: "720p", label: "720p" },
  { value: "1080p", label: "1080p" },
];
export const asanaOptions = [
  { value: "Asana 1", label: "Asana 1" },
  { value: "Asana 2", label: "Asana 2" },
  { value: "Asana 3", label: "Asana 3" },
];

export const CreateAsanaSchema = Yup.object().shape({
  // nameEnglish: Yup.string().required("English Name is required"),
  // nameHindi: Yup.string().required("Hindi Name is required"),
  position: Yup.string().required("Position is required"),
  [LANGUAGES.ENGLISH]: Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
  }),
  [LANGUAGES.HINDI]: Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
  }),
});
export const initialValues = {
  difficulty: 0,
  position: "standing",
  [LANGUAGES.ENGLISH]: {
    name: "",
    contradictions: "",
    benefits: "",
    thumbnail: [],
    video: [],
    pose: [],
  },
  [LANGUAGES.HINDI]: {
    name: "",
    contradictions: "",
    benefits: "",
    thumbnail: [],
    video: [],
    pose: [],
  },
};

export const createAsanaPayload = (data, selectedAsana) => ({
  difficulty: data.difficulty,
  type: data.position,
  details: {
    en: {
      name: data.english.name,
      contradictions: data.english.contradictions,
      benefits: data.english.benefits,
      thumbnailUrl:
        typeof data.english.thumbnail[0] === "string"
          ? generateOriginalUrl(selectedAsana.details.en.thumbnailUrl)
          : "",
      videoUrls: {
        480: "",
        720: "",
        1080: "",
        "default":
          typeof data.english.video[0] === "string"
            ? generateOriginalUrl(selectedAsana.details.en.videoUrls.default)
            : "",
      },
      pose: data.english.pose.map((pose) => ({
        id: pose.id,
        startTime: pose.startTime,
        endTime: pose.endTime
      })),
    },
    hi: {
      name: data.hindi.name,
      contradictions: data.hindi.contradictions,
      benefits: data.hindi.benefits,
      thumbnailUrl:
        typeof data.hindi.thumbnail[0] === "string"
          ? generateOriginalUrl(selectedAsana.details.hi.thumbnailUrl)
          : "",
      videoUrls: {
        480: "",
        720: "",
        1080: "",
        "default":
          typeof data.hindi.video[0] === "string"
            ? generateOriginalUrl(selectedAsana.details.hi.videoUrls?.default)
            : "",
      },
      pose: data.hindi.pose.map((pose) => ({
        id: pose.id,
        startTime: pose.startTime,
        endTime: pose.endTime
      })),
    },
  },
});

export const getAsanaFiles = ({ english, hindi }) => {
  let files = {
    english: { fileDetails: [] },
    hindi: { fileDetails: [] },
  };
  let filesObj = { english: [], hindi: [] };
  if (english.thumbnail.length && typeof english.thumbnail[0] !== "string") {
    let file = english.thumbnail[0];
    filesObj.english.push(file);
    files.english.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "asana",
      refId: "thumbnailUrl",
      language: "en",
    });
  }
  if (english.video.length && typeof english.video[0] !== "string") {
    let file = english.video[0];
    filesObj.english.push(file);
    files.english.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "asana",
      refId: "videoUrls.default",
      language: "en",
    });
  }
  if (hindi.thumbnail.length && typeof hindi.thumbnail[0] !== "string") {
    let file = hindi.thumbnail[0];
    filesObj.hindi.push(file);
    files.hindi.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "asana",
      refId: "thumbnailUrl",
      language: "hi",
    });
  }
  if (hindi.video.length && typeof hindi.video[0] !== "string") {
    let file = hindi.video[0];
    filesObj.hindi.push(file);
    files.hindi.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "asana",
      refId: "videoUrls.default",
      language: "hi",
    });
  }
  let filesData = {};
  if (files.english.fileDetails.length) {
    filesData.english = files.english;
  }
  if (files.hindi.fileDetails.length) {
    filesData.hindi = files.hindi;
  }
  return { filesData, files: filesObj };
};

export const formatSelectedAsanaData = (data) => ({
  difficulty: data.difficulty,
  position: data.type,
  [LANGUAGES.ENGLISH]: {
    name: data.details.en.name,
    contradictions: data.details.en.contradictions,
    benefits: data.details.en.benefits,
    thumbnail: data.details.en.thumbnailUrl
      ? [data.details.en.thumbnailUrl]
      : [],
    video: data.details.en.videoUrls?.default ? [data.details.en.videoUrls?.default] : [],
    pose: data.details.en.pose,
  },
  [LANGUAGES.HINDI]: {
    name: data.details.hi.name,
    contradictions: data.details.hi.contradictions,
    benefits: data.details.hi.benefits,
    thumbnail: data.details.hi.thumbnailUrl
      ? [data.details.hi.thumbnailUrl]
      : [],
    video: data.details.hi.videoUrls?.default ? [data.details.hi.videoUrls?.default] : [],
    pose: data.details.hi.pose,
  },
});
