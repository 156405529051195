import * as Yup from "yup";
import { COURSE_TYPES, LANGUAGES } from "../../../../utils/constants";
import { generateOriginalUrl, getFileName } from "../../../../utils/functions";

export const options = [
  { value: "chocolate", label: "chocolate" },
  { value: "strawberry", label: "strawberry" },
  { value: "vanilla", label: "vanilla" },
];
export const tagsOptions = [
  {
    label: "anuradha",
    value: "anuradha",
  },
  {
    label: "abs",
    value: "abs",
  },
  {
    label: "chandranamaskar",
    value: "chandranamaskar",
  },
  {
    label: "asthama",
    value: "asthama",
  },
  {
    label: "backbend",
    value: "backbend",
  },
  {
    label: "balance",
    value: "balance",
  },
  {
    label: "english",
    value: "english",
  },
  {
    label: "male",
    value: "male",
  },
  {
    label: "beginner",
    value: "beginner",
  },
  {
    label: "15min",
    value: "15min",
  },
  {
    label: "studio",
    value: "studio",
  },
  {
    label: "breath",
    value: "breath",
  },
  {
    label: "kaustubh",
    value: "kaustubh",
  },
  {
    label: "arms",
    value: "arms",
  },
  {
    label: "pranayam",
    value: "pranayam",
  },
  {
    label: "covid",
    value: "covid",
  },
  {
    label: "forwardbend",
    value: "forwardbend",
  },
  {
    label: "basics",
    value: "basics",
  },
  {
    label: "hindi",
    value: "hindi",
  },
  {
    label: "female",
    value: "female",
  },
  {
    label: "bodyparts",
    value: "bodyparts",
  },
  {
    label: "30min",
    value: "30min",
  },
  {
    label: "practice",
    value: "practice",
  },
  {
    label: "chanting",
    value: "chanting",
  },
  {
    label: "kshitija",
    value: "kshitija",
  },
  {
    label: "back",
    value: "back",
  },
  {
    label: "suryanamaskar",
    value: "suryanamaskar",
  },
  {
    label: "diabetes",
    value: "diabetes",
  },
  {
    label: "seated",
    value: "seated",
  },
  {
    label: "flexibility",
    value: "flexibility",
  },
  {
    label: "unisex",
    value: "unisex",
  },
  {
    label: "painrelief",
    value: "painrelief",
  },
  {
    label: "60min",
    value: "60min",
  },
  {
    label: "listening",
    value: "listening",
  },
  {
    label: "neha",
    value: "neha",
  },
  {
    label: "core",
    value: "core",
  },
  {
    label: "yognidra",
    value: "yognidra",
  },
  {
    label: "digestion",
    value: "digestion",
  },
  {
    label: "standing",
    value: "standing",
  },
  {
    label: "relax",
    value: "relax",
  },
  {
    label: "relaxation",
    value: "relaxation",
  },
  {
    label: "movement",
    value: "movement",
  },
  {
    label: "om prakash",
    value: "om prakash",
  },
  {
    label: "fullbody",
    value: "fullbody",
  },
  {
    label: "yonimudra",
    value: "yonimudra",
  },
  {
    label: "headache",
    value: "headache",
  },
  {
    label: "supine",
    value: "supine",
  },
  {
    label: "relief",
    value: "relief",
  },
  {
    label: "weightloss",
    value: "weightloss",
  },
  {
    label: "visualization",
    value: "visualization",
  },
  {
    label: "rohan",
    value: "rohan",
  },
  {
    label: "hamstrings",
    value: "hamstrings",
  },
  {
    label: "insomnia",
    value: "insomnia",
  },
  {
    label: "twisting",
    value: "twisting",
  },
  {
    label: "strength",
    value: "strength",
  },
  {
    label: "emotions",
    value: "emotions",
  },
  {
    label: "stuthi",
    value: "stuthi",
  },
  {
    label: "head",
    value: "head",
  },
  {
    label: "menstruation",
    value: "menstruation",
  },
  {
    label: "sleep",
    value: "sleep",
  },
  {
    label: "sugandha",
    value: "sugandha",
  },
  {
    label: "hip",
    value: "hip",
  },
  {
    label: "migrain",
    value: "migrain",
  },
  {
    label: "breathawareness",
    value: "breathawareness",
  },
  {
    label: "sujeet",
    value: "sujeet",
  },
  {
    label: "knees",
    value: "knees",
  },
  {
    label: "pain",
    value: "pain",
  },
  {
    label: "chakraawareness",
    value: "chakraawareness",
  },
  {
    label: "vinay",
    value: "vinay",
  },
  {
    label: "legs",
    value: "legs",
  },
  {
    label: "posture",
    value: "posture",
  },
  {
    label: "focus",
    value: "focus",
  },
  {
    label: "bruna",
    value: "bruna",
  },
  {
    label: "lowerback",
    value: "lowerback",
  },
  {
    label: "spondiliosis",
    value: "spondiliosis",
  },
  {
    label: "positivity",
    value: "positivity",
  },
  {
    label: "shruti",
    value: "shruti",
  },
  {
    label: "lungs",
    value: "lungs",
  },
  {
    label: "stiffness",
    value: "stiffness",
  },
  {
    label: "nimish",
    value: "nimish",
  },
  {
    label: "neck",
    value: "neck",
  },
  {
    label: "stress",
    value: "stress",
  },
  {
    label: "susana",
    value: "susana",
  },
  {
    label: "prostate",
    value: "prostate",
  },
  {
    label: "thyroid",
    value: "thyroid",
  },
  {
    label: "dhruvi",
    value: "dhruvi",
  },
  {
    label: "shoulder",
    value: "shoulder",
  },
  {
    label: "weakness",
    value: "weakness",
  },
  {
    label: "neeta",
    value: "neeta",
  },
  {
    label: "sidebody",
    value: "sidebody",
  },
  {
    label: "weight",
    value: "weight",
  },
  {
    label: "spine",
    value: "spine",
  },
  {
    label: "anger",
    value: "anger",
  },
  {
    label: "sromach",
    value: "sromach",
  },
  {
    label: "awareness",
    value: "awareness",
  },
  {
    label: "thigh",
    value: "thigh",
  },
  {
    label: "concentration",
    value: "concentration",
  },
  {
    label: "upperback",
    value: "upperback",
  },
  {
    label: "depression",
    value: "depression",
  },
  {
    label: "quadriseps",
    value: "quadriseps",
  },
  {
    label: "imbalance",
    value: "imbalance",
  },
  {
    label: "waist",
    value: "waist",
  },
];

export const awareOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Sleep", label: "Sleep" },
  { value: "Relaxation", label: "Relaxation" },
  { value: "Emotion", label: "Emotion" },
  { value: "Focus", label: "Focus" },
];
export const practiceOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Weight loss", label: "Weight loss" },
  { value: "Short practice", label: "Short practice" },
  { value: "Relaxation", label: "Relaxation" },
];
export const studioOptions = [
  { value: "Beginner", label: "Beginner" },
  { value: "Weight loss", label: "Weight loss" },
  { value: "Short practice", label: "Short practice" },
  { value: "Body parts", label: "Body parts" },
  { value: "Relaxation", label: "Relaxation" },
  { value: "Pain relief", label: "Pain relief" },
];

export const filterCategoryOption = [
  { value: "Beginner", label: "Beginner" },
  { value: "Sleep", label: "Sleep" },
  { value: "Relaxation", label: "Relaxation" },
  { value: "Emotion", label: "Emotion" },
  { value: "Focus", label: "Focus" },
  { value: "Weight loss", label: "Weight loss" },
  { value: "Short practice", label: "Short practice" },
  { value: "Body parts", label: "Body parts" },
  { value: "Pain relief", label: "Pain relief" },
];

export const qualityOptions = [
  { value: "480", label: "480p" },
  { value: "720", label: "720p" },
  { value: "1080", label: "1080p" },
];
export const asanaOptions = [
  { value: "Asana 1", label: "Asana 1" },
  { value: "Asana 2", label: "Asana 2" },
  { value: "Asana 3", label: "Asana 3" },
];

export const CreateCourseSchema = Yup.object().shape({
  courseType: Yup.string().required("Please select course type"),
  // courseDuration: Yup.string().required("Please enter course duration"),
  [LANGUAGES.ENGLISH]: Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    aboutCourse: Yup.string().trim().required("About course is required"),
  }),
  [LANGUAGES.HINDI]: Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    aboutCourse: Yup.string().trim().required("About course is required"),
  }),
});

export const initialValues = {
  courseType: "",
  language: "",
  tags: [],
  categories: [],
  [LANGUAGES.ENGLISH]: {
    title: "",
    duration: "",
    aboutCourse: "",
    thumbnail: [],
    gif: [],
    audio: [],
    videos: [],
    asanas: [],
    poses: [],
  },
  [LANGUAGES.HINDI]: {
    title: "",
    duration: "",
    aboutCourse: "",
    thumbnail: [],
    gif: [],
    audio: [],
    videos: [],
    asanas: [],
    poses: [],
  },
};

const checkVideoUrlExist = (videos, key, selectedCourse, lang) => {
  let video = videos.find((video) => video.quality === key);
  if (video && video?.file.length && typeof video?.file?.[0] === "string") {
    let url = video.file[0];
    return generateOriginalUrl(url);
  }
  return "";
};

export const createCoursePayload = (data, selectedCourse) => {
  const coursePayload = {
    type: data.courseType,
    tags: data.tags,
    categories: data.categories,
    details: {
      en: {
        title: data.english.title,
        duration: data.english.duration,
        description: data.english.aboutCourse,
        thumbnailUrl:
          typeof data.english.thumbnail[0] === "string"
            ? generateOriginalUrl(selectedCourse.details.en.thumbnailUrl)
            : "",
        gifUrl:
          typeof data.english.gif[0] === "string"
            ? generateOriginalUrl(selectedCourse.details.en.gifUrl)
            : "",
        videoUrls: {
          1080: checkVideoUrlExist(
            data.english.videos,
            "1080",
            selectedCourse,
            "en"
          ),
          720: checkVideoUrlExist(
            data.english.videos,
            "720",
            selectedCourse,
            "en"
          ),
          480: checkVideoUrlExist(
            data.english.videos,
            "480",
            selectedCourse,
            "en"
          ),
        },
        asanas: data.english.asanas.map((data) => ({
          id: data.id,
          startTime: data.startTime,
          endTime: data.endTime,
        })),
        pose: data.english.poses.map((data) => ({
          id: data.id,
          startTime: data.startTime,
          endTime: data.endTime,
        })),
      },
      hi: {
        title: data.hindi.title,
        duration: data.hindi.duration,
        description: data.hindi.aboutCourse,
        thumbnailUrl:
          typeof data.hindi.thumbnail[0] === "string"
            ? generateOriginalUrl(selectedCourse.details.hi.thumbnailUrl)
            : "",
        gifUrl:
          typeof data.hindi.gif[0] === "string"
            ? generateOriginalUrl(selectedCourse.details.hi.gifUrl)
            : "",
        videoUrls: {
          1080: checkVideoUrlExist(
            data.hindi.videos,
            "1080",
            selectedCourse,
            "hi"
          ),
          720: checkVideoUrlExist(
            data.hindi.videos,
            "720",
            selectedCourse,
            "hi"
          ),
          480: checkVideoUrlExist(
            data.hindi.videos,
            "480",
            selectedCourse,
            "hi"
          ),
        },
        asanas: data.hindi.asanas.map((data) => ({
          id: data.id,
          startTime: data.startTime,
          endTime: data.endTime,
        })),
        pose: data.hindi.poses.map((data) => ({
          id: data.id,
          startTime: data.startTime,
          endTime: data.endTime,
        })),
      },
    },
  };

  if (data.courseType === COURSE_TYPES.AWARE.value) {
    coursePayload.details.en["audioUrl"] = {
      320:
        typeof data.english.audio[0] === "string"
          ? generateOriginalUrl(selectedCourse.details.en.audioUrl["320"])
          : "",
    };
    coursePayload.details.hi["audioUrl"] = {
      320:
        typeof data.hindi.audio?.[0] === "string"
          ? generateOriginalUrl(selectedCourse.details.hi?.audioUrl?.["320"])
          : "",
    };
  }
  return coursePayload;
};

export const getCourseFiles = ({ english, hindi }) => {
  let files = {
    english: { fileDetails: [] },
    hindi: { fileDetails: [] },
  };
  let filesObj = { english: [], hindi: [] };
  if (english?.thumbnail?.length && typeof english.thumbnail[0] !== "string") {
    let file = english.thumbnail[0];
    filesObj.english.push(file);
    files.english.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "course",
      refId: "thumbnailUrl",
      language: "en",
    });
  }

  if (english?.gif?.length && typeof english.gif[0] !== "string") {
    let file = english.gif[0];
    filesObj.english.push(file);
    files.english.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "course",
      refId: "gifUrl",
      language: "en",
    });
  }
  if (english?.videos?.length) {
    english.videos.forEach((video) => {
      if (video.file?.length && typeof video.file[0] !== "string") {
        let file = video.file[0];
        filesObj.english.push(file);
        files.english.fileDetails.push({
          name: getFileName(file.name),
          contentType: file.type,
          type: "course",
          refId: `videoUrls.${video.quality}`,
          language: "en",
        });
      }
    });
  }
  if (english?.audio?.length && typeof english.audio[0] !== "string") {
    let file = english.audio[0];
    filesObj.english.push(file);
    files.english.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "course",
      refId: "audioUrl.320",
      language: "en",
    });
  }
  if (hindi?.thumbnail?.length && typeof hindi.thumbnail[0] !== "string") {
    let file = hindi.thumbnail[0];
    filesObj.hindi.push(file);
    files.hindi.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "course",
      refId: "thumbnailUrl",
      language: "hi",
    });
  }
  if (hindi?.gif?.length && typeof hindi.gif[0] !== "string") {
    let file = hindi.gif[0];
    filesObj.hindi.push(file);
    files.hindi.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "course",
      refId: "gifUrl",
      language: "hi",
    });
  }

  if (hindi?.videos?.length) {
    hindi.videos.forEach((video) => {
      if (video.file?.length && typeof video.file[0] !== "string") {
        let file = video.file[0];
        filesObj.hindi.push(file);
        files.hindi.fileDetails.push({
          name: getFileName(file.name),
          contentType: file.type,
          type: "course",
          refId: `videoUrls.${video.quality}`,
          language: "hi",
        });
      }
    });
  }
  if (hindi?.audio?.length && typeof hindi.audio[0] !== "string") {
    let file = hindi.audio[0];
    filesObj.hindi.push(file);
    files.hindi.fileDetails.push({
      name: getFileName(file.name),
      contentType: file.type,
      type: "course",
      refId: "audioUrl.320",
      language: "en",
    });
  }
  let filesData = {};
  if (files.english.fileDetails.length) {
    filesData.english = files.english;
  }
  if (files.hindi.fileDetails.length) {
    filesData.hindi = files.hindi;
  }
  return { filesData, files: filesObj };
};

export const formatSelectedCourseData = (data) => ({
  courseType: data.type,
  tags: data.tags,
  categories: data.categories,
  [LANGUAGES.ENGLISH]: {
    title: data.details.en.title,
    duration: data.details.en.duration,
    aboutCourse: data.details.en.description,
    thumbnail: data.details.en.thumbnailUrl
      ? [data.details.en.thumbnailUrl]
      : [],
    gif: data.details.en.gifUrl ? [data.details.en.gifUrl] : [],
    videos: Object.keys(data.details.en.videoUrls).reduce((acc, key) => {
      if (data.details.en.videoUrls[key]) {
        acc.push({
          quality: key,
          file: [data.details.en.videoUrls[key]],
        });
        return acc;
      }
      return acc;
    }, []),
    audio: data.details.en.audioUrl?.["320"]
      ? [data.details.en.audioUrl?.["320"]]
      : [],
    asanas: [...data.details.en.asanas],
    poses: [...data.details.en.pose],
  },
  [LANGUAGES.HINDI]: {
    title: data.details.hi.title,
    duration: data.details.hi.duration,
    aboutCourse: data.details.hi.description,
    thumbnail: data.details.hi.thumbnailUrl
      ? [data.details.hi.thumbnailUrl]
      : [],
    gif: data.details.hi.gifUrl ? [data.details.hi.gifUrl] : [],
    videos: Object.keys(data.details.hi.videoUrls).reduce((acc, key) => {
      if (data.details.hi.videoUrls[key]) {
        acc.push({
          quality: key,
          file: [data.details.hi.videoUrls[key]],
        });
        return acc;
      }
      return acc;
    }, []),
    audio: data.details.hi.audioUrl?.["320"]
      ? [data.details.hi.audioUrl?.["320"]]
      : [],
    asanas: [...data.details.hi.asanas],
    poses: [...data.details.hi.pose],
  },
});

/**
 * Multi course configs
 */

export const CreateMultiCourseSchema = Yup.object().shape({
  courseType: Yup.string().required("Please select course type"),
  courseDuration: Yup.number()
    .required("Please enter course duration")
    .min(2)
    .max(31)
    .moreThan(1, "Course duration must be atleast 2 days")
    .lessThan(32, "Course duration must not be greater than 31 days"),
  [LANGUAGES.ENGLISH]: Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    aboutCourse: Yup.string().trim().required("About course is required"),
  }),
  [LANGUAGES.HINDI]: Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    aboutCourse: Yup.string().trim().required("About course is required"),
  }),
});

export const initialMultiCourseValues = {
  courseType: "",
  language: "",
  tags: [],
  categories: [],
  courseDuration: "2",
  [LANGUAGES.ENGLISH]: {
    title: "",
    duration: "",
    aboutCourse: "",
    thumbnail: [],
    audio: [],
  },
  [LANGUAGES.HINDI]: {
    title: "",
    duration: "",
    aboutCourse: "",
    thumbnail: [],
    audio: [],
  },
};

export const createMultiCoursePayload = (data, selectedCourse, isEdit) => {
  const coursePayload = {
    type: data.courseType,
    tags: data.tags,
    categories: data.categories,
    courseDuration: parseInt(data.courseDuration),
    details: {
      en: {
        title: data.english.title,
        duration: data.english.duration,
        description: data.english.aboutCourse,
        thumbnailUrl:
          typeof data.english.thumbnail[0] === "string"
            ? generateOriginalUrl(selectedCourse.details.en.thumbnailUrl)
            : "",
        days: {},
      },
      hi: {
        title: data.hindi.title,
        duration: data.hindi.duration,
        description: data.hindi.aboutCourse,
        thumbnailUrl:
          typeof data.hindi.thumbnail[0] === "string"
            ? generateOriginalUrl(selectedCourse.details.hi.thumbnailUrl)
            : "",
        days: {},
      },
    },
  };

  if (data.courseType === COURSE_TYPES.AWARE.value) {
    coursePayload.details.en["audioUrl"] = {
      320:
        typeof data.english.audio[0] === "string"
          ? generateOriginalUrl(selectedCourse.details.en.audioUrl["320"])
          : "",
    };
    coursePayload.details.hi["audioUrl"] = {
      320:
        typeof data.hindi.audio?.[0] === "string"
          ? generateOriginalUrl(selectedCourse.details.hi?.audioUrl?.["320"])
          : "",
    };
  }
  if (isEdit) {
    delete coursePayload.details.en.days;
    delete coursePayload.details.hi.days;
  }
  return coursePayload;
};

export const formatSelectedMultiCourseData = (data) => ({
  courseType: data.type,
  tags: data.tags,
  categories: data.categories,
  courseDuration: data.courseDuration,
  [LANGUAGES.ENGLISH]: {
    title: data.details.en.title,
    duration: data.details.en.duration,
    aboutCourse: data.details.en.description,
    thumbnail: data.details.en.thumbnailUrl
      ? [data.details.en.thumbnailUrl]
      : [],
    audio: data.details.en.audioUrl?.["320"]
      ? [data.details.en.audioUrl?.["320"]]
      : [],
  },
  [LANGUAGES.HINDI]: {
    title: data.details.hi.title,
    duration: data.details.hi.duration,
    aboutCourse: data.details.hi.description,
    thumbnail: data.details.hi.thumbnailUrl
      ? [data.details.hi.thumbnailUrl]
      : [],
    audio: data.details.hi.audioUrl?.["320"]
      ? [data.details.hi.audioUrl?.["320"]]
      : [],
  },
});

export const dayFormikInitData = {
  day: "2",
  [LANGUAGES.ENGLISH]: { videos: [], asanas: [], poses: [] },
  [LANGUAGES.HINDI]: { videos: [], asanas: [], poses: [] },
};

export const createDaysPayload = (data, selectedCourse) => ({
  day: parseInt(data.day),
  daysDetails: {
    en: {
      videoUrls: {
        1080: checkVideoUrlExist(
          data.english.videos,
          "1080",
          selectedCourse,
          "en"
        ),
        720: checkVideoUrlExist(
          data.english.videos,
          "720",
          selectedCourse,
          "en"
        ),
        480: checkVideoUrlExist(
          data.english.videos,
          "480",
          selectedCourse,
          "en"
        ),
      },
      asanas: data.english.asanas.map((data) => ({
        id: data.id,
        startTime: data.startTime,
        endTime: data.endTime,
      })),
      pose: data.english.poses.map((data) => ({
        id: data.id,
        startTime: data.startTime,
        endTime: data.endTime,
      })),
    },
    hi: {
      videoUrls: {
        1080: checkVideoUrlExist(
          data.hindi.videos,
          "1080",
          selectedCourse,
          "hi"
        ),
        720: checkVideoUrlExist(data.hindi.videos, "720", selectedCourse, "hi"),
        480: checkVideoUrlExist(data.hindi.videos, "480", selectedCourse, "hi"),
      },
      asanas: data.hindi.asanas.map((data) => ({
        id: data.id,
        startTime: data.startTime,
        endTime: data.endTime,
      })),
      pose: data.hindi.poses.map((data) => ({
        id: data.id,
        startTime: data.startTime,
        endTime: data.endTime,
      })),
    },
  },
});

export const formatSelectedDayData = (data) => ({
  // courseDuration: data.courseDuration,
  day: data.day,
  [LANGUAGES.ENGLISH]: {
    videos: Object.keys(data.details.en.videoUrls).reduce((acc, key) => {
      if (data.details.en.videoUrls[key]) {
        acc.push({
          quality: key,
          file: [data.details.en.videoUrls[key]],
        });
        return acc;
      }
      return acc;
    }, []),
    asanas: [...data.details.en.asanas],
    poses: [...data.details.en.pose],
  },
  [LANGUAGES.HINDI]: {
    videos: Object.keys(data.details.hi.videoUrls).reduce((acc, key) => {
      if (data.details.hi.videoUrls[key]) {
        acc.push({
          quality: key,
          file: [data.details.hi.videoUrls[key]],
        });
        return acc;
      }
      return acc;
    }, []),
    asanas: [...data.details.hi.asanas],
    poses: [...data.details.hi.pose],
  },
});

export const AsanaValidationSchema = Yup.object().shape({
  id: Yup.string().required("Name is required"),
  startTime: Yup.string().required("Start time is required"),
  endTime: Yup.string().required("End time is required"),
});
