import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { ButtonLoading } from "../../../../components/button";
import { changePasswordAsync } from "../redux/api";

const passwordRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,32}$/;

const ChangePassword = () => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const PasswordSchema = () =>
    Yup.object().shape({
      oldPassword: Yup.string()
        .trim()
        .required("Enter valid current password."),
      newPassword: Yup.string()
        .trim()
        // .matches(
        //   passwordRegex,
        //   "Password should contain min 6 and max 32 characters with atleast one Alphanumeric and special character."
        // )
        .required("Please enter password."),
      confirmPassword: Yup.string()
        .trim()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "New password and confirm password didn't match"
        )
        .required("Please enter confirm password."),
    });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const dispatch = useDispatch();

  const loadingButton = useSelector(
    (state) => state.ui.loadingButton,
    shallowEqual
  );

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values) => {
      // dispatch(changePasswordAsync(values.newPassword));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row">
        <label
          htmlFor="hori-pass1"
          className="col-sm-4 col-form-label text-right"
        >
          Old Password*
        </label>
        <div className="col-sm-4">
          <input
            name="oldPassword"
            id="hori-pass1"
            type="password"
            placeholder="Old Password"
            className={`form-control ${getInputClasses("password")}`}
            {...formik.getFieldProps("oldPassword")}
          />
          {formik.touched.oldPassword && formik.errors.oldPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.oldPassword}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="hori-pass2"
          className="col-sm-4 col-form-label text-right"
        >
          New Password*
        </label>
        <div className="col-sm-4">
          <input
            name="newPassword"
            data-parsley-equalto="#hori-pass1"
            id="hori-pass2"
            type="password"
            placeholder="New Password"
            className={`form-control ${getInputClasses("password")}`}
            {...formik.getFieldProps("newPassword")}
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.newPassword}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="hori-pass2"
          className="col-sm-4 col-form-label text-right"
        >
          Confirm Password*
        </label>
        <div className="col-sm-4">
          <input
            name="confirmPassword"
            data-parsley-equalto="#hori-pass1"
            id="hori-pass2"
            type="password"
            placeholder="Confirm Password"
            className={`form-control ${getInputClasses("password")}`}
            {...formik.getFieldProps("confirmPassword")}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirmPassword}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="form-group text-right">
        <Link
          to="/dashboard"
          className="btn btn-light waves-effect waves-light mr-3"
        >
          Cancel
        </Link>
        <ButtonLoading
          className="btn-primary waves-effect waves-light w-15"
          type="submit"
          title="Update"
          isLoading={loadingButton}
        />
      </div>
    </form>
  );
};

export default ChangePassword;
