import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import get from "lodash/get";
import { ButtonLoading } from "../../../../components/button";
import { CustomSelect } from "../../../../components/inputs/select";
import { qualityOptions } from "../config";
import { Dropzone } from "../../../../components/dropzone/dropzone";
import { useFormik } from "formik";

export const VideoModal = ({
  show,
  setShow,
  formik,
  language,
  name,
  selectedVideo,
  setSelectedVideo,
}) => {
  //   const options = useMemo(() => {
  //     let videos = get(formik.values, name);
  //     if (videos.length) {
  //       return qualityOptions.filter((opt) =>
  //         Boolean(!videos.find((video) => video.quality === opt.value))
  //       );
  //     }
  //     return qualityOptions;
  //   });

  let defautlData = useMemo(() => {
    return selectedVideo
      ? {
          quality: selectedVideo.quality,
          file: [selectedVideo.file[0]],
        }
      : {
          quality: qualityOptions[0].value,
          file: [],
        };
  }, [selectedVideo]);
  const videoFormik = useFormik({
    initialValues: { ...defautlData },
    enableReinitialize: true,
  });

  const onSave = async () => {
    if (videoFormik.values.file.length) {
      let videos = [...get(formik.values, name)];
      if (selectedVideo) {
        videos = videos.map((video) => {
          if (video.quality === selectedVideo.quality) {
            return {
              ...videoFormik.values,
              fileName: videoFormik.values.file[0]?.name || "",
            };
          }
          return video;
        });
      } else {
        videos.push({
          ...videoFormik.values,
          fileName: videoFormik.values.file[0].name,
        });
      }
      formik.setFieldValue(name, [...videos]);
    }
    videoFormik.resetForm();
    setSelectedVideo(null);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setSelectedVideo(null);
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomSelect
          label="Video Quality"
          name={`quality`}
          formik={videoFormik}
          options={qualityOptions}
          // defaultValue={qualityOptions[0]}
          value={[
            {
              label: videoFormik.values.quality + "p",
              value: videoFormik.values.quality,
            },
          ]}
        />
        <Dropzone
          label="Video"
          text="Drag 'n' drop video here, or click to select video"
          info="(Only 1 video file will be accepted)"
          type="video"
          allow="video/*"
          preview
          hideDropZoneOnPreview
          name="file"
          formik={videoFormik}
          language={language}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light waves-effect"
          onClick={() => setShow(false)}
        >
          Cancel
        </button>
        <ButtonLoading
          className={`btn-primary waves-effect waves-light w-25`}
          onClick={onSave}
          title="Save"
          isLoading={false}
        />
      </Modal.Footer>
    </Modal>
  );
};
