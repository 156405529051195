import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Tab, Tabs, Button } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../components/aside-menu/links";
import { ButtonLoading } from "../../../components/button";
import { CommonCard } from "../../../components/card/common-card";
import Editor from "../../../components/editor/editor";
import { CommonLayout } from "../../../components/layout/common-layout";
import { TABS, TABS_TYPES } from "./config";
import { getContentDataAsync, updateContentAsync } from "./redux/api";

const ContentManagement = () => {
  const [key, setKey] = useState(TABS_TYPES.PRIVACY_POLICY);
  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);
  const { privacy_policy, terms_and_conditions } = useSelector(
    (state) => state.contentManagement,
    shallowEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContentDataAsync(TABS_TYPES.PRIVACY_POLICY));
    dispatch(getContentDataAsync(TABS_TYPES.TERMS_AND_CONDITIONS));
  }, []);
  const formik = useFormik({
    initialValues: {
      privacy_policy,
      terms_and_conditions,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(updateContentAsync({ type: key, content: values[key] }));
    },
  });
  const onDataChange = (type, data) => {
    formik.setFieldValue(type, data);
  };
  return (
    <CommonLayout>
      <CommonCard>
        <Tabs
          id="content-tabs"
          className="mb-3"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {TABS.map((tab) => (
            <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
              <div className="d-flex">
                <ButtonLoading
                  className="btn-primary waves-effect waves-light w-15 mb-3 mr-0 ml-auto"
                  type="button"
                  title={"Save"}
                  isLoading={loadingButton}
                  onClick={formik.handleSubmit}
                />
              </div>
              <Editor
                name={tab.key}
                onChange={onDataChange}
                data={formik.values[tab.key]}
              />
            </Tab>
          ))}
        </Tabs>
      </CommonCard>
    </CommonLayout>
  );
};

export default ContentManagement;
