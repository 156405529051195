import { BannerManagementActions } from "./action";
import {
  get,
  post,
  update,
  remove,
  upload,
  getUploadSignedUrl,
  uploadSigned,
  getDownloadSignedUrl,
} from "../../../../api";
import { toast } from "react-toastify";
import { UIActions } from "../../ui/redux/action";
import { apiService } from "../../../../api/config";
import { setIndex } from "../../../../utils/functions";

export const getAllBannerAsync = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(BannerManagementActions.setBannerListLoading(true));
      const {
        limit,
        offset,
        searchText,
        filters: { section, type },
      } = getState().bannerManagement;
      let url = `${apiService.bannerUrl}/list?limit=${limit}&offset=${offset}`;
      if (searchText) {
        url += `&searchText=${searchText}`;
      }
      if (section !== "") {
        url += `&section=${section}`;
      }
      if (type) {
        url += `&type=${type}`;
      }
      const { data } = await get(url);
      if (data && !data.isError) {
        dispatch(
          BannerManagementActions.getAllBanner({
            banner: data?.data?.bannerDocs || [],
            totalCount: data?.data?.total || 0,
          })
        );
        return dispatch(BannerManagementActions.setBannerListLoading(false));
      }
      dispatch(BannerManagementActions.setBannerListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(BannerManagementActions.setBannerListLoading(false));
      return toast.error(error.message);
    }
  };
};

const getBannerUploadUrl = (files) => {
  let englishFilePromise;
  if (files.english) {
    englishFilePromise = getUploadSignedUrl(files.english);
  }
  let hindiFilePromise;
  if (files.hindi) {
    hindiFilePromise = getUploadSignedUrl(files.hindi);
  }
  return Promise.all([englishFilePromise, hindiFilePromise]);
};

export const createBannerAsync = (data, { filesData, files }) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getBannerUploadUrl(filesData);
      await updateBannerDetailsUploadUrlData(data, files, filesUrlData);
      let resp = await post(`${apiService.bannerUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(BannerManagementActions.createBanner());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(
          resp?.data?.message || "Banner create successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      console.log(error);
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

const updateBannerDetailsUploadUrlData = async (
  data,
  files,
  [englishUrlsResp, hindiUrlsResp]
) => {
  let englishData = englishUrlsResp?.data?.data || [];
  let hindiData = hindiUrlsResp?.data?.data || [];
  let enPromise, hiPromise;
  if (englishData.length) {
    enPromise = Promise.all(
      englishData.map((enData, i) => {
        // data.details.en[
        //   enData.uploadedFilePath.refId
        // ] = `${enData.uploadedFilePath.path}`;
        setIndex(
          data.details.en,
          enData.uploadedFilePath.refId,
          `${enData.uploadedFilePath.path}`
        );
        return uploadSigned(enData.signedUrl, files.english[i]);
      })
    );
  }
  if (hindiData.length) {
    hiPromise = Promise.all(
      hindiData.map((hiData, i) => {
        // data.details.hi[
        //   hiData.uploadedFilePath.refId
        // ] = `${hiData.uploadedFilePath.path}`;
        setIndex(
          data.details.hi,
          hiData.uploadedFilePath.refId,
          `${hiData.uploadedFilePath.path}`
        );
        return uploadSigned(hiData.signedUrl, files.hindi[i]);
      })
    );
  }
  await Promise.all([enPromise, hiPromise]);
};

export const updateBannerAsync = (data, { filesData, files }) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let filesUrlData = await getBannerUploadUrl(filesData);
      await updateBannerDetailsUploadUrlData(data, files, filesUrlData);
      let resp = await update(`${apiService.bannerUrl}`, data);
      if (!resp?.data?.isError) {
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(
          resp?.data?.message || "Banner update successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const deleteBannerAsync = (id) => {
  return async (dispatch) => {
    try {
      dispatch(UIActions.setUIKey("loadingButton", true));
      let resp = await remove(apiService.bannerUrl, { id });
      if (!resp?.data?.isError) {
        dispatch(BannerManagementActions.deleteBanner());
        dispatch(UIActions.setUIKey("loadingButton", false));
        return toast.success(
          resp?.data?.message || "Delete banner successfull"
        );
      }
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(
        resp?.data?.message || "Error while updating.Please try again later."
      );
    } catch (error) {
      dispatch(UIActions.setUIKey("loadingButton", false));
      return toast.error(error.message);
    }
  };
};

export const getBannerDetailsAsync = (bannerId) => {
  return async (dispatch) => {
    try {
      dispatch(BannerManagementActions.setBannerListLoading(true));
      const { data } = await get(`${apiService.bannerUrl}?id=${bannerId}`);
      if (data && !data.isError) {
        let apiData = JSON.parse(JSON.stringify(data.data));
        // await getBannerDownloadUrl(data.data);
        dispatch(
          BannerManagementActions.setSelectedBanner({
            selectedBanner: data?.data || null,
            apiData,
          })
        );
        return dispatch(BannerManagementActions.setBannerListLoading(false));
      }
      dispatch(BannerManagementActions.setBannerListLoading(false));
      return toast.error("Error while fetching data.Please try again later.");
    } catch (error) {
      dispatch(BannerManagementActions.setBannerListLoading(false));
      return toast.error(error.message);
    }
  };
};

const getBannerDownloadUrl = async (bannerData) => {
  let payload = [];
  let en = bannerData.details.en,
    hi = bannerData.details.hi;
  if (en.thumbnailUrl) {
    payload.push({
      path: en.thumbnailUrl,
      refId: "thumbnailUrl",
      language: "en",
    });
  }
  if (hi.thumbnailUrl) {
    payload.push({
      path: hi.thumbnailUrl,
      refId: "thumbnailUrl",
      language: "hi",
    });
  }
  if (payload.length) {
    let { data } = await getDownloadSignedUrl({ filePaths: payload });
    if (!data?.isError) {
      data.data.map(
        (item) =>
          (bannerData.details[item.language][item.refId] = item.downloadUrl)
      );
    }
  }
};
