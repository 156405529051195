import React from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "../../../components/aside-menu/links";
import { CommonCard } from "../../../components/card/common-card";
import { CommonLayout } from "../../../components/layout/common-layout";
import { CommonTable } from "../../../components/table/common-table";

const columns = [
  {
    id: "title",
    label: "Title",
    className: "text-center align-middle",
  },
  {
    id: "displayOrder",
    label: "Display Order",
    className: "text-center align-middle",
  },
  {
    id: "date",
    label: "Date",
    className: "text-center align-middle",
  },
  {
    id: "status",
    label: "Status",
    className: "text-center align-middle",
  },
];

const actions = {
  active: true,
  className: "text-center align-middle",
  lists: [],
};

const CategoryManagement = () => {
  const history = useHistory();
  const onAddCategory = () => {
    history.push(PATHS.CATEGORY_MANAGEMENT + PATHS.CREATE_CATEGORY);
  };
  return (
    <CommonLayout>
      <CommonCard
        header={
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light w-10"
            onClick={onAddCategory}
          >
            Add Category
          </button>
        }
        headerClassName="justify-content-end mr-3"
      >
        <CommonTable
          data={{
            columns: columns,
            actions: actions,
            rows: [],
            pagination: {
              active: true,
              offset: 0,
              limit: 1,
              totalRecords: 0,
              onLimitChange: () => null,
              onPageChange: () => null,
            },
          }}
        />
      </CommonCard>
    </CommonLayout>
  );
};

export default CategoryManagement;
