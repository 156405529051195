import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import {
  CreatePoseSchema,
  getInitialValues,
  mapPoseItems,
  poseItemsMinMax,
} from "../config";
import { CommonCard } from "../../../../components/card/common-card";
import { CommonLayout } from "../../../../components/layout/common-layout";
import { TextInput } from "../../../../components/inputs/text-input";
import { PATHS } from "../../../../components/aside-menu/links";
import { ButtonLoading } from "../../../../components/button";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  createPoseAsync,
  getPoseDetailsAsync,
  updatePoseAsync,
} from "../redux/api";
import { Accordion } from "react-bootstrap";
import { Redirect } from "react-router-dom";

export const CreatePose = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const isEditMode = params.poseId;

  const { isLoading, selectedPose, redirect } = useSelector(
    (state) => state.poseManagement,
    shallowEqual
  );

  const { loadingButton } = useSelector((state) => state.ui, shallowEqual);

  useEffect(() => {
    if (isEditMode) {
      dispatch(getPoseDetailsAsync(isEditMode));
    }
  }, [isEditMode]);

  const formik = useFormik({
    initialValues: getInitialValues(selectedPose),
    validationSchema: CreatePoseSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      // Generate string json for python lib eval
      values.poseJSON.str_json = mapPoseItems(values.poseJSON.parsed_json);
      if (selectedPose) {
        let data = {
          ...values,
          id: isEditMode,
        };
        return dispatch(updatePoseAsync(data));
      }
      dispatch(createPoseAsync(values));
    },
  });

  if (redirect) {
    return <Redirect to={PATHS.POSE_MANAGEMENT} />;
  }

  return (
    <CommonLayout>
      <CommonCard
        className="card w-75 mx-auto mt-2"
        header={
          <>
            <Link to={PATHS.POSE_MANAGEMENT}>
              <span
                className="mdi mdi-arrow-left mr-2"
                style={{ fontSize: "x-large" }}
              ></span>
            </Link>{" "}
            {isEditMode ? (
              <h4 className="header-title m-0">Edit Pose</h4>
            ) : (
              <h4 className="header-title m-0">Create Pose</h4>
            )}
          </>
        }
        headerClassName="card-header card-title"
      >
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <TextInput
                  name="poseName"
                  label="Pose Name"
                  placeholder="Enter pose name"
                  id="poseName"
                  formik={formik}
                />
              </div>
              <div className="col-md-6">
                <TextInput
                  name="deviation"
                  label="Deviation"
                  placeholder="Enter deviation"
                  id="deviation"
                  formik={formik}
                />
              </div>
               <Accordion defaultActiveKey="AccuracyMeter">
                <Accordion.Item key="AccuracyMeter1" eventKey="AccuracyMeter">
                  <Accordion.Header>Accuracy Meter</Accordion.Header>
                  <Accordion.Body>
                    <div className="row">
                      <div className="col-md-3">
                        <TextInput
                          name={`accuracyMeter.low`}
                          label="Minimum"
                          placeholder="Enter minimum"
                          id="min"
                          formik={formik}
                        />
                      </div>
                      <div className="col-md-3">
                        <TextInput
                          name={`accuracyMeter.medium`}
                          label="Medium"
                          placeholder="Enter medium"
                          id="max"
                          formik={formik}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="row">
              {poseItemsMinMax.map(
                (key) =>
                  formik.values.poseJSON.parsed_json[key] && (
                    <Accordion defaultActiveKey={key}>
                      <Accordion.Item key={key} eventKey={key}>
                        <Accordion.Header>{key}</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-md-3">
                              <TextInput
                                name={`poseJSON.parsed_json.${key}.min`}
                                label="Min"
                                placeholder="Enter min"
                                id="min"
                                formik={formik}
                              />
                            </div>
                            <div className="col-md-3">
                              <TextInput
                                name={`poseJSON.parsed_json.${key}.max`}
                                label="Max"
                                placeholder="Enter max"
                                id="max"
                                formik={formik}
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )
              )}
            </div>
            <div className="form-group text-right">
              <Link
                to={PATHS.POSE_MANAGEMENT}
                className="btn btn-light waves-effect waves-light mr-3"
              >
                Cancel
              </Link>
              <ButtonLoading
                className="btn-primary waves-effect waves-light w-15"
                type="submit"
                title={"Submit"}
                isLoading={loadingButton}
              />
            </div>
          </form>
        )}
      </CommonCard>
    </CommonLayout>
  );
};
